// React
// Redux
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";
// Modules
import axios from "axios";

const accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

export const setPath = async (coordsUser: any, coordsDest: [number, number] | null, map: any) => {
	if (!coordsDest || coordsDest === null) {
		let layer = map.getLayer("route");

		if (layer) {
			map.removeLayer(layer.id);
			map.removeSource("route");
		}

		return;
	}
	if (coordsUser) {
		try {
			let req = await axios.get(
				`https://api.mapbox.com/directions/v5/mapbox/walking/${coordsUser[0]},${coordsUser[1]};${coordsDest[0]},${coordsDest[1]}?steps=true&geometries=geojson&access_token=${accessToken}`
			);

			const route = req.data.routes[0].geometry.coordinates;
			const geojson = {
				type: "Feature",
				properties: {},
				geometry: {
					type: "LineString",
					coordinates: route,
				},
			};

			if (map.getSource("route")) {
			} else if (map) {
				const layers = map.getStyle().layers;

				let firstSymbolId;
				for (const layer of layers) {
					if (layer.type === "symbol") {
						firstSymbolId = layer.id;
						break;
					}
				}

				map.addSource("route", {
					type: "geojson",
					data: geojson as any,
				});

				if (!map.getLayer("route")) {
					map.addLayer({
						id: "route",
						type: "line",
						source: "route",
						layout: {
							"line-join": "round",
							"line-cap": "square",
						},
						paint: {
							"line-color": "#1C1917",
							"line-width": 5,
							"line-opacity": 0.75,
							"line-dasharray": [
								"match",
								["get", "property"],
								1,
								["literal", [1, 2]],
								2,
								["literal", [1, 2]],
								3,
								["literal", [1, 2]],
								["literal", [1, 2]],
							],
						},
					});
				}
			} else {
				console.log("Map don't exist.");
			}
		} catch (e) {
			console.log("Failed to retrieve new path.");
		}
	} else {
		console.log("Retrieving user location failed.");
		return;
	}
};
