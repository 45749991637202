// React
import React from "react";
// Redux
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";
// Imports
import { GetTrad } from "src/utils/getTrad";
// CSS
import "./quizUnlock.css";
// assets
import starIconBackground from "../../style/assets/objFound/starIconBackground.png";
import quizStarIconBig from "../../style/assets/quiz/quizStarIconBig.png";
import quizQuestionMark from "../../style/assets/quiz/quizQuestionMark.png";
import quizGoButton from "../../style/assets/quiz/quizGoArrow.png";

export const QuizUnlock = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);

	return (
		<div className='quizUnlock'>
			<div className='quizUnlock-content'>
				<div className='quizUnlock-congrat-container'>
					<div className='quizUnlock-congrat-text1'>
						<GetTrad value='congrats' lang={language} />
					</div>
					<div className='quizUnlock-congrat-text2'>
						<GetTrad value='youUnlocked' lang={language} />
					</div>
				</div>
				<div className='quizUnlock-quizImage-container'>
					<div className='quizUnlock-quizImage-starBG-container'>
						<div
							className='quizUnlock-quizImage-starBG'
							style={{ backgroundImage: `url(${starIconBackground})` }}
						></div>
					</div>
					<div className='quizUnlock-quizImage-bigStar-container'>
						<div
							className='quizUnlock-quizImage-bigStar'
							style={{ backgroundImage: `url(${quizStarIconBig})` }}
						></div>
					</div>
					<div
						className='quizUnlock-quizImage-questionMark'
						style={{ backgroundImage: `url(${quizQuestionMark})` }}
					></div>
					<div className='quizUnlock-quizImage-text-container'>
						<div className='quizUnlock-quizImage-text'>
							<GetTrad value='theQuiz' lang={language} />
						</div>
					</div>
				</div>
			</div>
			<div className='quizUnlock-buttons'>
				<div className='quizUnlock-button-Container'>
					<div className='quizUnlock-button-text'>
						<GetTrad value='trelazeExpert' lang={language} />
					</div>
					<div
						className='quizUnlock-button-Go'
						style={{ backgroundImage: `url(${quizGoButton})` }}
						onClick={() => {
							props.pageFcts.setPage("quiz");
						}}
					></div>
				</div>
				<div
					className='quizUnlock-later-button'
					onClick={() => {
						props.pageFcts.setPage(null);
					}}
				>
					Plus tard
				</div>
			</div>
		</div>
	);
};
