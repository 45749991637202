// React
import React, { useEffect, useRef, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../reducers";
// Components
import { GetTrad } from "src/utils/getTrad";
// Style
import "./inventory.css";
// Datas
import { ranks } from "../../datas/ranks";
import { geojson as poisList } from "../../datas/pois";
// Assets
import lockImg from "../../style/assets/inventary/lock.svg";
import movieImg from "../../style/assets/inventary/movie.jpg";
import podcastImg from "../../style/assets/inventary/podcast.png";
import podcastPauseImg from "../../style/assets/inventary/podcastPause.png";
import quizImg from "../../style/assets/inventary/quiz.png";

import inventoryHeaderBackground from "../../style/assets/inventary/1InventoryHeaderBackground.png";
import inventoryStarUnactive from "../../style/assets/icons/inventoryLvlStarUnactive.svg";
import inventoryStaractive from "../../style/assets/icons/inventoryLvlStarActive.svg";

import contentIconEnabled from "../../style/assets/icons/contentIconEnabled.svg";
import contentIconDisabled from "../../style/assets/icons/contentIconDisabled.svg";

import objectIconEnabled from "../../style/assets/icons/objectTrophyEnabled.svg";
import objectIconDisabled from "../../style/assets/icons/objectTrophyDisabled.svg";

import cardImage1 from "../../style/assets/pois/premiereManuAllu/Etape1_PremiereManuAllu.jpg";
import cardImage2 from "../../style/assets/pois/butteEnfer/Etape2_ButteduBuisson_ChevalementPuits1ChampRobert.jpg";
import cardImage3 from "../../style/assets/pois/favereaux/Etape3_CardImage_Favereaux.jpg";
import cardImage4 from "../../style/assets/pois/vieuxfonds/Etape4_Bremandiere_Carrierecielouvert.jpg";
import cardImage5 from "../../style/assets/pois/moulin/Etape5_Moulin_After_Filtre.jpg";
import cardImage6 from "../../style/assets/pois/butteBuisson/Etape6_Buisson_fond_Monthibert.jpg";
import cardImage7 from "../../style/assets/pois/pyramide/Etape7_LaPyramide.jpg";
import cardImage8 from "../../style/assets/pois/placeTellieres/Etape8_LesTellieres.jpg";
import cardImage9 from "../../style/assets/pois/secondeManuAllu/Etape9_LaManu.jpg";
import cardImage10 from "../../style/assets/pois/maraichere/Etape10_LaMaraichere1.jpg";

import itemStep1 from "../../style/assets/inventary/itemLvl1.png";
import itemStep2 from "../../style/assets/inventary/itemLvl2.png";
import itemStep3 from "../../style/assets/inventary/itemLvl3.png";
import itemStep4 from "../../style/assets/inventary/itemLvl4.png";
import itemStep5 from "../../style/assets/inventary/itemLvl5.png";
import itemStep6 from "../../style/assets/inventary/itemLvl6.png";
import itemStep7 from "../../style/assets/inventary/itemLvl7.png";
import itemStep8 from "../../style/assets/inventary/itemLvl8.png";
import itemStep9 from "../../style/assets/inventary/itemLvl9.png";
import itemStep10 from "../../style/assets/inventary/itemLvl10.png";

// Empty item
import itemStep1_empty from "../../style/assets/inventary/itemLvl1_Empty.png";
import itemStep2_empty from "../../style/assets/inventary/itemLvl2_Empty.png";
import itemStep3_empty from "../../style/assets/inventary/itemLvl3_Empty.png";
import itemStep4_empty from "../../style/assets/inventary/itemLvl4_Empty.png";
import itemStep5_empty from "../../style/assets/inventary/itemLvl5_Empty.png";
import itemStep6_empty from "../../style/assets/inventary/itemLvl6_Empty.png";
import itemStep7_empty from "../../style/assets/inventary/itemLvl7_Empty.png";
import itemStep8_empty from "../../style/assets/inventary/itemLvl8_Empty.png";
import itemStep9_empty from "../../style/assets/inventary/itemLvl9_Empty.png";
import itemStep10_empty from "../../style/assets/inventary/itemLvl10_Empty.png";


export const Inventory = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);
	const { treasures } = useSelector((state: IRootState) => state.game);

	const [treasuresState, setTreasuresState] = useState(treasures);
	const _treasuresState = useRef(treasuresState);



	useEffect(() => {
		_treasuresState.current = treasuresState;
	}, [treasuresState]);

	const setShowImage = (value: string) => {
		console.log(_treasuresState.current);
		if (!_treasuresState.current.find((p: any) => p.id === value)) {
			console.log("Trying to discover an inexistant POI!");
		} else {
			let poi = _treasuresState.current.find((p: any) => p.id === value);
			if (poi.showImage) {
				props.setPoi(poisList.features.find((p: any) => p.properties.id === value));
				props.pageFcts.setPage("poi");
			} else {
				let tmp = JSON.parse(JSON.stringify(_treasuresState.current));

				tmp.find((p: any) => p.id === value).showImage = !_treasuresState.current.find(
					(p: any) => p.id === value
				).showImage;
				setTreasuresState(tmp);
			}
		}
	};

	useEffect(() => {
		let tmp = JSON.parse(JSON.stringify(_treasuresState.current));

		for (let treasure of treasures) {
			if (treasure.discovered) {
				tmp.find((p: any) => p.id === treasure.id).showImage = !_treasuresState.current.find(
					(p: any) => p.id === treasure.id
				).showImage;
			}
		}
		setTreasuresState(tmp);
	}, []);

	const [mode, setMode] = useState<string>("treasures");

	const [isPlaying, setIsPlaying] = useState(false);
	const [timeLeft, setTimeLeft] = useState(115);

	const formatTime = (time: number) => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
	  };

	const togglePlayPause = () => {
	  const audioElem = document.getElementById("inventory-audio") as HTMLAudioElement;
	  if (audioElem) {
		if (isPlaying) {
		  audioElem.pause();
		} else {
		  audioElem.play();
		}
		setIsPlaying(!isPlaying);
	  }
	};

	useEffect(() => {
		const audioElem = document.getElementById("inventory-audio") as HTMLAudioElement;
		const interval = setInterval(() => {
		  if (audioElem && !audioElem.paused) {
			const remaningTime = audioElem.duration - audioElem.currentTime;
			setTimeLeft(remaningTime);
		  }
		}, 1000);
	
		return () => clearInterval(interval);
	  }, [isPlaying]);

	  

	return (
		<React.Fragment>
			<div className='inventory'>
				
				<div className='inventory-content'>
					<div className='inventory-header'>
						<div className='inventory-header-text'>
							<GetTrad value={"progress"} lang={language} />
						</div>
					</div>
					<div className='inventory-quit'>
					<div
						className='inventory-quit-cross'
						onClick={() => {
							props.pageFcts.setPage(null);
						}}
					></div>
				</div>
					<div className='inventory-topContainer'>
						<div className='inventory-HeaderBackground'>1</div>
						<div className='inventory-topContainer-level-Container'>
							<div className='inventory-topContainer-level-text1'>
								<GetTrad value={"level"} lang={language} />
							</div>
							<div className='inventory-topContainer-level-icons-container'>
								<div
									className='inventory-topContainer-level-icon1'
									style={{ backgroundImage: `url(${inventoryStaractive})` }}
								>
									{" "}
								</div>

								<div
									className='inventory-topContainer-level-icon2'
									style={{
										backgroundImage:
											treasuresState.filter((p: any) => p.discovered === true).length >= 5
												? `url(${inventoryStaractive})`
												: `url(${inventoryStarUnactive})`,
									}}
								></div>
								<div
									className='inventory-topContainer-level-icon3'
									style={{
										backgroundImage:
											treasuresState.filter((p: any) => p.discovered === true).length >= 10
												? `url(${inventoryStaractive})`
												: `url(${inventoryStarUnactive})`,
									}}
								></div>
							</div>
							<div className='inventory-topContainer-level-text2'>
								{ranks.findIndex((r: any) => {
									return (
										r.min <= treasuresState.filter((p: any) => p.discovered).length &&
										r.max > treasuresState.filter((p: any) => p.discovered).length
									);
								}) +
									1 +
									"/" +
									ranks.length}
							</div>
						</div>
						<div className='inventory-topContainer-blackbar'></div>
						<div className='inventory-topContainer-title-container'>
							<div className='inventory-topContainer-title'>
								<GetTrad
									value={
										ranks.find((r: any) => {
											return (
												r.min <= treasuresState.filter((p: any) => p.discovered).length &&
												r.max > treasuresState.filter((p: any) => p.discovered).length
											);
										})?.id || ""
									}
									lang={language}
								/>
							</div>
							<div className='inventory-topContainer-subtitle'>
								<GetTrad
									value={
										ranks.find((r: any) => {
											return (
												r.min <= treasuresState.filter((p: any) => p.discovered).length &&
												r.max > treasuresState.filter((p: any) => p.discovered).length
											);
										})?.idDesc || ""
									}
									lang={language}
								/>
							</div>
						</div>
					</div>

					<div
						className='inventory-buttons-container'
						onClick={() => {
							setMode(mode === "treasures" ? "content" : "treasures");
						}}
					>
						<div
							className='inventory-button-content-container'
							style={{ backgroundColor: mode === "content" ? "#FFFFFF" : "#D6D3D1" }}
						>
							<div
								className='inventory-button-content-icon'
								style={{
									backgroundImage:
										mode === "content"
											? `url(${contentIconEnabled})`
											: `url(${contentIconDisabled})`,
								}}
							></div>
							<div
								className='inventory-button-content-text'
								style={{ color: mode === "content" ? "black" : "#78716C" }}
							>
								<GetTrad value={"content"} lang={language} />
							</div>
						</div>
						<div
							className='inventory-button-objects-container'
							style={{ backgroundColor: mode === "content" ? "#D6D3D1" : "#FFFFFF" }}
						>
							<div
								className='inventory-button-objects-icon'
								style={{
									backgroundImage:
										mode === "content" ? `url(${objectIconDisabled})` : `url(${objectIconEnabled})`,
								}}
							></div>
							<div
								className='inventory-button-objects-text'
								style={{ color: mode === "content" ? "#78716C" : "black" }}
							>
								<GetTrad value={"objects"} lang={language} />
							</div>
						</div>
					</div>
					{mode === "content" && (
						<React.Fragment>
							<div
								className='inventory-card inventory-film360'
								onClick={() => {
									props.pageFcts.setPage("movie");
								}}
							>
								<div className='inventory-cardContent'>
									<div className='inventory-card-title'>
										<GetTrad value={"film360"} lang={language} />
									</div>
									<div className='inventory-card-content'>
										<div
											className='inventory-card-360'
											style={{ backgroundImage: `url(${movieImg})` }}
										></div>
									</div>
									<div className='inventory-card-descMovieName'>
										<GetTrad value={"movieName"} lang={language} />
									</div>
								</div>
							</div>
							  
							<div
								className='inventory-card inventory-podcast'
							>
								<audio id="inventory-audio" src={"assets/podcastBonus.mp3"}></audio>

							
								<div className='inventory-cardContent'>
									<div className='inventory-card-title'>
										<GetTrad value={"secretPodcast"} lang={language} />
									</div>
									<div className='inventory-card-content'>
										{treasuresState.filter((p: any) => p.discovered === true).length < 5 ? (
											<div
												className='inventory-card-lock'
												style={{ backgroundImage: `url(${lockImg})` }}
											></div>
										) : (
											<div className='inventory-card-content'>
												<div
													className={`inventory-card-360 ${isPlaying ? 'animated-background' : ''}`}
													onClick={togglePlayPause}
													style={{ backgroundImage: isPlaying ? `url(${podcastImg})` : `url(${podcastPauseImg})` }}
												></div>
											</div>
										)}
									</div>
									{treasuresState.filter((p: any) => p.discovered === true).length < 5 ? (
										<div className='inventory-card-descNoFind'>
											<GetTrad value={"find5toDiscover"} lang={language} />
										</div>
									) : (
										<div className='inventory-card-descMovieName'>
											<GetTrad value={"podcastName"} lang={language} />
											<div style={{ textAlign: 'center'}}>
												{formatTime(timeLeft)}
											</div>
										</div>
									)}
								</div>
							</div>
							<div className='inventory-card inventory-quiz'>
								<div className='inventory-cardContent'>
									<div className='inventory-card-title'>
										<GetTrad value={"finalTest"} lang={language} />
									</div>
									<div className='inventory-card-content'>
										{treasuresState.filter((p: any) => p.discovered === true).length < 10 ? (
											<div
												className='inventory-card-lock'
												style={{ backgroundImage: `url(${lockImg})` }}
											></div>
										) : (
											<div
												className='inventory-card-quiz'
												style={{ backgroundImage: `url(${quizImg})` }}
											></div>
										)}
									</div>
									{treasuresState.filter((p: any) => p.discovered === true).length < 10 ? (
										<div className='inventory-card-descNoFind'>
											<GetTrad value={"find10toDiscover"} lang={language} />
										</div>
									) : (
										<div
											className='inventory-card-button'
											onClick={() => {
												props.pageFcts.setPage("quizunlock");
											}}
										>
											<GetTrad value={"play"} lang={language} />
										</div>
									)}
								</div>
							</div>
						</React.Fragment>
					)}
					{mode === "treasures" && (
						<React.Fragment>
							<div className='inventory-collectedObject-container-text'>
								<div className='inventory-CollectedObject-number'>
									{treasuresState.filter((p: any) => p.discovered === true).length}/
									{treasuresState.length}
								</div>
								<div className='inventory-CollectedObject-text'>
								{treasuresState.filter((p: any) => p.discovered === true).length > 1 ? 
									(<GetTrad value={"collectedObjectPlurial"} lang={language} />) 
									: 
									(<GetTrad value={"collectedObject"} lang={language} />)
								}
									
								</div>
							</div>
							<div className='inventory-card-container'>
								<div className='inventory-card-column'>
									<div
										className='inventory-card-poi'
										onClick={() => {
											setShowImage("premiereManuAllu");
										}}
									>
										<div className='inventory-card-container-inside'>
											<div
												className='inventory-card-image'
												style={{
													backgroundColor: "#B4C8D9",
													backgroundImage: treasuresState.find(
														(p: { id: string }) => p.id === "premiereManuAllu"
													)?.showImage
														? treasures.find(
																(p: { id: string }) => p.id === "premiereManuAllu"
														  )?.discovered
															? `url(${itemStep1})`
															: `url(${itemStep1_empty})`
														: `url(${cardImage1})`,
													backgroundSize: treasuresState.find(
														(p: { id: string }) => p.id === "premiereManuAllu"
													)?.showImage
														? `70%`
														: `cover`,
												}}
											/>
											<div className='inventory-card-text'>
												<GetTrad value={"inventoryCardStep1"} lang={language} />
											</div>
										</div>
									</div>
									<div
										className='inventory-card-poi'
										onClick={() => {
											setShowImage("favereaux");
										}}
									>
										<div className='inventory-card-container-inside'>
											<div
												className='inventory-card-image'
												style={{
													backgroundColor: "#3C699D",
													backgroundImage: treasuresState.find(
														(p: { id: string }) => p.id === "favereaux"
													)?.showImage
														? treasures.find((p: { id: string }) => p.id === "favereaux")
																?.discovered
															? `url(${itemStep3})`
															: `url(${itemStep3_empty})`
														: `url(${cardImage3})`,
													backgroundSize: treasuresState.find(
														(p: { id: string }) => p.id === "favereaux"
													)?.showImage
														? `70%`
														: `cover`,
												}}
											/>
											<div className='inventory-card-text'>
												<GetTrad value={"inventoryCardStep3"} lang={language} />
											</div>
										</div>
									</div>
									<div
										className='inventory-card-poi'
										onClick={() => {
											setShowImage("moulin");
										}}
									>
										<div className='inventory-card-container-inside'>
											<div
												className='inventory-card-image'
												style={{
													backgroundColor: "#F2DABD", //"#7B5E4E",
													backgroundImage: treasuresState.find(
														(p: { id: string }) => p.id === "moulin"
													)?.showImage
														? treasures.find((p: { id: string }) => p.id === "moulin")
																?.discovered
															? `url(${itemStep5})`
															: `url(${itemStep5_empty})`
														: `url(${cardImage5})`,
													backgroundSize: treasuresState.find(
														(p: { id: string }) => p.id === "moulin"
													)?.showImage
														? `70%`
														: `cover`,
												}}
											/>
											<div className='inventory-card-text'>
												<GetTrad value={"inventoryCardStep5"} lang={language} />
											</div>
										</div>
									</div>
									<div
										className='inventory-card-poi'
										onClick={() => {
											setShowImage("pyramide");
										}}
									>
										<div className='inventory-card-container-inside'>
											<div
												className='inventory-card-image'
												style={{
													backgroundColor: "#7B5E4E",
													backgroundImage: treasuresState.find(
														(p: { id: string }) => p.id === "pyramide"
													)?.showImage
														? treasures.find((p: { id: string }) => p.id === "pyramide")
																?.discovered
															? `url(${itemStep7})`
															: `url(${itemStep7_empty})`
														: `url(${cardImage7})`,
													backgroundSize: treasuresState.find(
														(p: { id: string }) => p.id === "pyramide"
													)?.showImage
														? `70%`
														: `cover`,
												}}
											/>
											<div className='inventory-card-text'>
												<GetTrad value={"inventoryCardStep7"} lang={language} />
											</div>
										</div>
									</div>
									<div
										className='inventory-card-poi'
										onClick={() => {
											setShowImage("secondeManuAllu");
										}}
									>
										<div className='inventory-card-container-inside'>
											<div
												className='inventory-card-image'
												style={{
													backgroundColor: "#E5E6BE",
													backgroundImage: treasuresState.find(
														(p: { id: string }) => p.id === "secondeManuAllu"
													)?.showImage
														? treasures.find(
																(p: { id: string }) => p.id === "secondeManuAllu"
														  )?.discovered
															? `url(${itemStep9})`
															: `url(${itemStep9_empty})`
														: `url(${cardImage9})`,
													backgroundSize: treasuresState.find(
														(p: { id: string }) => p.id === "secondeManuAllu"
													)?.showImage
														? `70%`
														: `cover`,
												}}
											/>
											<div className='inventory-card-text'>
												<GetTrad value={"inventoryCardStep9"} lang={language} />
											</div>
										</div>
									</div>
								</div>
								<div className='inventory-card-column'>
									<div
										className='inventory-card-poi'
										style={{ margin: "40% 0 10% 2%" }}
										onClick={() => {
											setShowImage("butteEnfer");
										}}
									>
										<div className='inventory-card-container-inside'>
											<div
												className='inventory-card-image'
												style={{
													backgroundColor: "#7B5E4E",
													backgroundImage: treasuresState.find(
														(p: { id: string }) => p.id === "butteEnfer"
													)?.showImage
														? treasures.find((p: { id: string }) => p.id === "butteEnfer")
																?.discovered
															? `url(${itemStep2})`
															: `url(${itemStep2_empty})`
														: `url(${cardImage2})`,
													backgroundSize: treasuresState.find(
														(p: { id: string }) => p.id === "butteEnfer"
													)?.showImage
														? `70%`
														: `cover`,
												}}
											/>
											<div className='inventory-card-text'>
												<GetTrad value={"inventoryCardStep2"} lang={language} />
											</div>
										</div>
									</div>
									<div
										className='inventory-card-poi'
										onClick={() => {
											setShowImage("vieuxfonds");
										}}
									>
										<div className='inventory-card-container-inside'>
											<div
												className='inventory-card-image'
												style={{
													backgroundColor: "#E5E6BE",
													backgroundImage: treasuresState.find(
														(p: { id: string }) => p.id === "vieuxfonds"
													)?.showImage
														? treasures.find((p: { id: string }) => p.id === "vieuxfonds")
																?.discovered
															? `url(${itemStep4})`
															: `url(${itemStep4_empty})`
														: `url(${cardImage4})`,
													backgroundSize: treasuresState.find(
														(p: { id: string }) => p.id === "vieuxfonds"
													)?.showImage
														? `70%`
														: `cover`,
												}}
											></div>

											<div className='inventory-card-text'>
												<GetTrad value={"inventoryCardStep4"} lang={language} />
											</div>
										</div>
									</div>
									<div
										className='inventory-card-poi'
										onClick={() => {
											setShowImage("butteBuisson");
										}}
									>
										<div className='inventory-card-container-inside'>
											<div
												className='inventory-card-image'
												style={{
													backgroundColor: "#B4C8D9",
													backgroundImage: treasuresState.find(
														(p: { id: string }) => p.id === "butteBuisson"
													)?.showImage
														? treasures.find((p: { id: string }) => p.id === "butteBuisson")
																?.discovered
															? `url(${itemStep6})`
															: `url(${itemStep6_empty})`
														: `url(${cardImage6})`,
													backgroundSize: treasuresState.find(
														(p: { id: string }) => p.id === "butteBuisson"
													)?.showImage
														? `70%`
														: `cover`,
												}}
											/>
											<div className='inventory-card-text'>
												<GetTrad value={"inventoryCardStep6"} lang={language} />
											</div>
										</div>
									</div>
									<div
										className='inventory-card-poi'
										onClick={() => {
											setShowImage("placeTellieres");
										}}
									>
										<div className='inventory-card-container-inside'>
											<div
												className='inventory-card-image'
												style={{
													backgroundColor: "#3C699D",
													backgroundImage: treasuresState.find(
														(p: { id: string }) => p.id === "placeTellieres"
													)?.showImage
														? treasures.find(
																(p: { id: string }) => p.id === "placeTellieres"
														  )?.discovered
															? `url(${itemStep8})`
															: `url(${itemStep8_empty})`
														: `url(${cardImage8})`,
													backgroundSize: treasuresState.find(
														(p: { id: string }) => p.id === "placeTellieres"
													)?.showImage
														? `70%`
														: `cover`,
												}}
											/>
											<div className='inventory-card-text'>
												<GetTrad value={"inventoryCardStep8"} lang={language} />
											</div>
										</div>
									</div>
									<div
										className='inventory-card-poi'
										onClick={() => {
											setShowImage("maraichere");
										}}
									>
										<div className='inventory-card-container-inside'>
											<div
												className='inventory-card-image'
												style={{
													backgroundColor: "#F2DABD",
													backgroundImage: treasuresState.find(
														(p: { id: string }) => p.id === "maraichere"
													)?.showImage
														? treasures.find((p: { id: string }) => p.id === "maraichere")
																?.discovered
															? `url(${itemStep10})`
															: `url(${itemStep10_empty})`
														: `url(${cardImage10})`,
													backgroundSize: treasuresState.find(
														(p: { id: string }) => p.id === "maraichere"
													)?.showImage
														? `70%`
														: `cover`,
												}}
											/>
											<div className='inventory-card-text'>
												<GetTrad value={"inventoryCardStep10"} lang={language} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</React.Fragment>
					)}
				</div>
				<div className='inventory-quizScreen'></div>
			</div>
		</React.Fragment>
	);
};
