// React
import { configureStore } from "@reduxjs/toolkit";
// App Files
import { reducer as reducer_app, IAppState } from "./reducer_app";
import { reducer as reducer_map, IMapState } from "./reducer_map";
import { reducer as reducer_game, IGameState } from "./reducer_game";

export interface IRootState {
	app: IAppState;
	map: IMapState;
	game: IGameState;
}

const store = configureStore({
	reducer: {
		app: reducer_app,
		map: reducer_map,
		game: reducer_game
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});

export type RootState = ReturnType<typeof store.getState>;

export default store;
