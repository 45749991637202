import imgPoiPremiereManuAllu from "../style/assets/pois/premiereManuAllu/Etape1_PremiereManuAllu.jpg";
import imgPoiButteEnfer from "../style/assets/pois/butteEnfer/Etape2_ButteduBuisson_ChevalementPuits1ChampRobert.jpg";
import imgPoiFavereaux from "../style/assets/pois/favereaux/Etape3_CardImage_Favereaux.jpg";
import imgPoiVieuxfonds from "../style/assets/pois/vieuxfonds/Etape4_Bremandiere_Carrierecielouvert.jpg";
import imgPoiMoulin from "../style/assets/pois/moulin/Etape5_Moulin_After_Filtre.jpg";
import imgPoiButteBuisson from "../style/assets/pois/butteBuisson/Etape6_Buisson_fond_Monthibert.jpg";
import imgPoiPyramide from "../style/assets/pois/pyramide/Etape7_LaPyramide.jpg";
import imgPoiPlaceTellieres from "../style/assets/pois/placeTellieres/Etape8_LesTellieres.jpg";
import imgPoiSecondeManuAllu from "../style/assets/pois/secondeManuAllu/Etape9_LaManu.jpg";
import imgPoiMaraichere from "../style/assets/pois/maraichere/Etape10_LaMaraichere1.jpg";
export const quiz = [
	{
		id: 0,
		question: "primMaterial",
		image: `url(${imgPoiPremiereManuAllu})`,
		explanations: 'primMaterialExplanations',
		answers: [
			{
				id: 0,
				answer: 'primMaterialCalcaire',
				correct: false,
				points: 0
			},
			{
				id: 1,
				answer: 'primMaterialCoal',
				correct: false,
				points: 0
			},
			{
				id: 2,
				answer: 'primMaterialSchiste',
				correct: true,
				points: 1
			},
			{
				id: 3,
				answer: 'primMaterialNickel',
				correct: false,
				points: 0
			}
		]
	},
	{
		id: 1,
		question: "structureToGoDown",
		image: `url(${imgPoiButteEnfer})`,
		explanations: 'structureToGoDownExplanations',
		answers: [
			{
				id: 0,
				answer: 'structureToGoDownCheval',
				correct: false,
				points: 0
			},
			{
				id: 1,
				answer: 'structureToGoDownChevalement',
				correct: true,
				points: 1
			},
			{
				id: 2,
				answer: 'structureToGoDownCheminement',
				correct: false,
				points: 0
			},
			{
				id: 3,
				answer: 'structureToGoDownChameau',
				correct: false,
				points: 0
			}
		]
	},
	{
		id: 2,
		question: "employeeMine",
		image: `url(${imgPoiFavereaux})`,
		explanations: 'employeeMineExplanations',
		answers: [
			{
				id: 0,
				answer: 'employeeMinePerreyeux',
				correct: true,
				points: 1
			},
			{
				id: 1,
				answer: 'employeeMineMineur',
				correct: false,
				points: 0
			},
			{
				id: 2,
				answer: 'employeeMineHouilleur',
				correct: false,
				points: 0
			},
			{
				id: 3,
				answer: 'employeeMineCarrier',
				correct: false,
				points: 0
			}
		]
	},
	{
		id: 3,
		question: "oldHole",
		image: `url(${imgPoiVieuxfonds})`,
		explanations: 'oldHoleExplanations',
		answers: [
			{
				id: 0,
				answer: 'oldHoleRetraite',
				correct: false,
				points: 0
			},
			{
				id: 1,
				answer: 'oldHoleSouterrain',
				correct: false,
				points: 0
			},
			{
				id: 2,
				answer: 'oldHoleGrotte',
				correct: false,
				points: 0
			},
			{
				id: 3,
				answer: 'oldHoleEau',
				correct: true,
				points: 1
			}
		]
	},
	{
		id: 4,
		question: "dangerExploitation",
		image: `url(${imgPoiMoulin})`,
		explanations: 'dangerExploitationExplanations',
		answers: [
			{
				id: 0,
				answer: 'dangerExploitationEau',
				correct: true,
				points: 1
			},
			{
				id: 1,
				answer: 'dangerExploitationFeu',
				correct: false,
				points: 0
			},
			{
				id: 2,
				answer: 'dangerExploitationDisputes',
				correct: false,
				points: 0
			},
			{
				id: 3,
				answer: 'dangerExploitationAir',
				correct: false,
				points: 0
			}
		]
	},
	{
		id: 5,
		question: "deeperSchiste",
		image: `url(${imgPoiButteBuisson})`,
		explanations: 'deeperSchisteExplanations',
		answers: [
			{
				id: 0,
				answer: 'deeperSchiste225',
				correct: false,
				points: 0
			},
			{
				id: 1,
				answer: 'deeperSchiste325',
				correct: false,
				points: 0
			},
			{
				id: 2,
				answer: 'deeperSchiste425',
				correct: false,
				points: 0
			},
			{
				id: 3,
				answer: 'deeperSchiste525',
				correct: true,
				points: 1
			}
		]
	},
	{
		id: 6,
		question: "pyramideTrelaze",
		image: `url(${imgPoiPyramide})`,
		explanations: 'pyramideTrelazeExplanations',
		answers: [
			{
				id: 0,
				answer: 'pyramideTrelazeNapoleon',
				correct: false,
				points: 0
			},
			{
				id: 1,
				answer: 'pyramideTrelazeLoire',
				correct: true,
				points: 1
			},
			{
				id: 2,
				answer: 'pyramideTrelazeCommission',
				correct: false,
				points: 0
			},
			{
				id: 3,
				answer: 'pyramideTrelazeEgypte',
				correct: false,
				points: 0
			}
		]
	},
	{
		id: 7,
		question: "employeeHome",
		image: `url(${imgPoiPlaceTellieres})`,
		explanations: 'employeeHomeExplanations',
		answers: [
			{
				id: 0,
				answer: 'employeeHomeLotissement',
				correct: false,
				points: 0
			},
			{
				id: 1,
				answer: 'employeeHomeGratteCiel',
				correct: false,
				points: 0
			},
			{
				id: 2,
				answer: 'employeeHomeEnsemble',
				correct: false,
				points: 0
			},
			{
				id: 3,
				answer: 'employeeHomeCiteJardin',
				correct: true,
				points: 1
			}
		]
	},
	{
		id: 8,
		question: "manuStruct",
		image: `url(${imgPoiSecondeManuAllu})`,
		explanations: 'manuStructExplanations',
		answers: [
			{
				id: 0,
				answer: 'manuStructParadis',
				correct: false,
				points: 0
			},
			{
				id: 1,
				answer: 'manuStructMineOr',
				correct: true,
				points: 1
			},
			{
				id: 2,
				answer: 'manuStructModerne',
				correct: false,
				points: 0
			},
			{
				id: 3,
				answer: 'manuStructFeminine',
				correct: false,
				points: 0
			}
		]
	},
	{
		id: 9,
		question: "ludovicMenard",
		image: `url(${imgPoiMaraichere})`,
		explanations: 'ludovicMenardExplanations',
		answers: [
			{
				id: 0,
				answer: 'ludovicMenardEngagement',
				correct: false,
				points: 0
			},
			{
				id: 1,
				answer: 'ludovicMenardPoingLeve',
				correct: false,
				points: 0
			},
			{
				id: 2,
				answer: 'ludovicMenardCombat',
				correct: true,
				points: 1
			},
			{
				id: 3,
				answer: 'ludovicMenardHommeAction',
				correct: false,
				points: 0
			}
		]
	}
]