import { toRad } from './toRad';
export function haversineDistance (coords1: [number, number], coords2: [number, number]) {
  const R = 6371e3;
  const lat1 = toRad(coords1[0]);
  const lat2 = toRad(coords2[0]);
  const deltaLat = toRad(coords2[0] - coords1[0]);
  const deltaLon = toRad(coords2[1] - coords1[1]);
  
  const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
        Math.cos(lat1) * Math.cos(lat2) *
        Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  
  return R * c;
  };