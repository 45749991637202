// App Actions
export const APP_FIRSTLAUNCH = "app_firstlaunch";
export const APP_LANGUAGE = "app_language";
/*export const APP_SENSORS = "app_sensors";*/

// Map Actions
export const MAP_COORDS = "map_coords";
export const MAP_HEADING = "map_heading";

// Game Actions
export const GAME_POIS = "game_pois";
export const GAME_TREASURES = "game_treasures";
export const GAME_SETDISCOVEREDPOI = "game_setdiscoveredpoi";
export const GAME_SETDISCOVEREDOBJ = "game_setdiscoveredobj";
export const GAME_SETSHOWIMAGE = "game_setshowimage";
export const GAME_SETQUIZ = "game_setquiz";
export const GAME_SETFINALRANK = "game_setfinalrank";

// DispatchType
export type DispatchType = (args: any) => any;

// IAction
export interface IAction {
	type: string;
	payload?: any;
}
