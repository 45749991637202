// React
import React, { useEffect, useState } from "react";
// Redux
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { setDiscoveredObj, setDiscoveredPoi } from "src/actions/game";
import { IRootState } from "src/reducers";
// Modules
import { useCookies } from "react-cookie";
// Components
import { GetTrad } from "src/utils/getTrad";
// Style
import "./ObjectFound.css";
// Assets
import starIconSmall from "../../style/assets/objFound/starIconSmall.png";
import starIconBig from "../../style/assets/objFound/starIconBig.png";
import starIconBlack from "../../style/assets/objFound/starIconBlack.png";
import starIconBackground from "../../style/assets/objFound/starIconBackground.png";
import itemImage from "../../style/assets/inventary/itemLvl1.png";

export const ObjectFound = (props: any) => {
	const dispatch: Dispatch<any> = useDispatch();

	const [cookies, setCookie] = useCookies(["cookiesAllowed", "pois", "treasures"]);

	const { language } = useSelector((state: IRootState) => state.app);
	const { treasures, pois } = useSelector((state: IRootState) => state.game);

	const [first, setFirst] = useState<boolean>(true);
	const [firstPoi, setFirstPoi] = useState<boolean>(true);
	const [objsFound, setObjsFound] = useState<number>(0);

	useEffect(() => {
		dispatch(setDiscoveredPoi(props.poi.properties.id));
		dispatch(setDiscoveredObj(props.poi.properties.id));
	}, []);

	useEffect(() => {
		if (first) {
			if (cookies.cookiesAllowed) {
				setCookie("treasures", JSON.stringify(treasures));
			}
			setFirst(false);
		}

		setObjsFound(treasures.filter((p: any) => p.discovered).length);
	}, [treasures]);

	useEffect(() => {
		if (firstPoi) {
			if (cookies.cookiesAllowed) {
				setCookie("pois", JSON.stringify(pois));
			}
			setFirstPoi(false);
		}
	}, [pois]);

	return (
		<div className='objFound'>
			<div className='objFound-content'>
				<div className='objFound-congrat-container'>
					<div className='objFound-congrat-text1'>
						<GetTrad value='congrats' lang={language} />
					</div>
					<div className='objFound-congrat-text2'>
						<GetTrad value='youWin' lang={language} />
					</div>
				</div>
				<div className='objFound-star-container'>
					<div
						className='objFound-star-background'
						style={{ backgroundImage: `url(${starIconBackground})` }}
					/>
					<div className='objFound-star-iconSmall-container'>
						<div
							className='objFound-star-iconSmall-black'
							style={{ backgroundImage: `url(${starIconBlack})` }}
						/>
						<div className='objFound-star-iconSmall' style={{ backgroundImage: `url(${starIconBig})` }} />
					</div>

					<div className='objFound-item' style={{ backgroundImage: props.poi.treasure.img }} />
				</div>
				<div className='objFound-name-container'>
					<div className='objFound-name-text1'>
						<GetTrad value={props.poi.treasure.name} lang={language} />
					</div>
					<div className='objFound-name-bar' />
					<div className='objFound-name-numberObj'>
						{objsFound + "/" + (objsFound <= 5 ? "5 " : "10 ")}
						{treasures.filter((p: any) => p.discovered === true).length > 1 ? 
							(<GetTrad value={"objsFoundPlurial"} lang={language} />) 
							: 
							(<GetTrad value={"objsFound"} lang={language} />)
						}
					</div>
				</div>
			</div>
			<div
				className='objFound-button'
				onClick={() => {
					if (objsFound === 5) {
						props.pageFcts.setPage("podcastunlock");
						return;
					} else if (objsFound === 10) {
						props.pageFcts.setPage("quizunlock");
						return;
					}
					props.pageFcts.setPage(null);
				}}
			>
				<div className='objFound-button-text'>
					<GetTrad value='followAdventure' lang={language} />
				</div>
			</div>
		</div>
	);
};
