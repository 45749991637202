// React
import React, { useEffect, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "src/reducers";
// Components
import { GetTrad } from "src/utils/getTrad";
// Modules
import { useCookies } from "react-cookie";
// Utils
import { quiz } from "../../datas/quiz";
// Style
import "./quizResult.css";
import { setFinalRank, setQuiz } from "src/actions/game";
// Import icon
import bandImg from "../../style/assets/global/BandHeader.png";
import quizImg from "../../style/assets/quiz/img1.jpg";
import rankStarUnactive from "../../style/assets/quiz/rankStarUnactive.png";
import rankStarActive from "../../style/assets/quiz/rankStarActive.png";

export const QuizResult = (props: any) => {
	const dispatch = useDispatch();

	const { language } = useSelector((state: IRootState) => state.app);
	const { finalRank } = useSelector((state: IRootState) => state.game);

	const [cookies, setCookie] = useCookies([
		"cookiesAllowed",
		"pois",
		"treasures",
		"quiz",
		"finalRank",
	]);
	const [cookiePopup, setCookiePopup] = useState(true);
	const [first, setFirst] = useState<boolean>(true);

	const retryQuiz = () => {
		if (cookies.cookiesAllowed) {
			setCookie("quiz", []);
		}
		dispatch(setQuiz([]))
	};

	useEffect (() => {
		if(props.score) {
			if (props.score < 4) {
				dispatch(setFinalRank("quizResultRank1"));
			} else if (props.score > 6) {
				dispatch(setFinalRank("quizResultRank3"));
			} else {
				dispatch(setFinalRank("quizResultRank2"));
			}
		}
	},[props.score])
	
	useEffect(() => {
		if (first) {
			if (cookies.cookiesAllowed) {
				setCookie("finalRank", JSON.stringify(finalRank));
			}
			setFirst(false);
		}
	}, [finalRank]);
	return (
		<React.Fragment>
			<div className='quizResult'>
				<div className='quizResult-main-container'>
					<div className='quizResult-main-text1'>
						<GetTrad value={"quizResultFinished"} lang={language} />
					</div>
					<div className='quizResult-main-points-container'>
						<div className='quizResult-main-points-number'>{props.score}/10 </div>
						<div className='quizResult-main-points-text'>
							<GetTrad value={"quizResultPoints"} lang={language} />
						</div>
					</div>
					<div className='quizResult-rank-container'>
						<div className='quizResult-rank-text'>
							<GetTrad value={"quizResultYourRank"} lang={language} />
						</div>
						<div className='quizResult-rank-seperator' />
						<div className='quizResult-rank-name'>
							{props.score < 4 ? (
								<GetTrad value={"quizResultRank1"} lang={language} />
							) : props.score > 6 ? (
								<GetTrad value={"quizResultRank3Full"} lang={language} />
							) : (
								<GetTrad value={"quizResultRank2"} lang={language} />
							)}
						</div>
						<div className='quizResult-rank-seperator' />
						<div className='quizResult-levelStar-container'>
							<div
								className='quizResult-levelStar-icon1'
								style={{ backgroundImage: `url(${rankStarActive})` }}
							/>
							<div
								className='quizResult-levelStar-icon2'
								style={{
									backgroundImage:
										props.score > 3 ? `url(${rankStarActive})` : `url(${rankStarUnactive})`,
								}}
							/>
							<div
								className='quizResult-levelStar-icon3'
								style={{
									backgroundImage:
										props.score > 6 ? `url(${rankStarActive})` : `url(${rankStarUnactive})`,
								}}
							/>
						</div>
					</div>

					<div className='quizResult-conclusion-text'>
						{" "}
						{/* To change */}
						{props.score <= 3
							? <GetTrad value={"quizResultConclusion1"} lang={language} />
							: props.score > 6
							?  <GetTrad value={"quizResultConclusion3"} lang={language} />
							:  <GetTrad value={"quizResultConclusion2"} lang={language} />}
					</div>
				</div>
				<div className='quizResult-buttons'>
					<div className='quizResult-button-backToMap' onClick={() => props.pageFcts.setPage(null)}>
						<GetTrad value={"quizResultBackToMap"} lang={language} />
					</div>
					<div
						className='quizResult-button-redoQuiz'
						onClick={() => {
							props.pageFcts.setPage("quizunlock");
							retryQuiz();
						}}
					>
						<GetTrad value={"quizResultRedoQuiz"} lang={language} />
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
