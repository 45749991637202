// React
import ReactDOM from "react-dom/client";
// Redux
import { Provider } from "react-redux";
import store from "./reducers/index";
// Modules
import { CookiesProvider } from "react-cookie";
// Style
import "./index.css";
// Components
import App from "./App";
import ReactGA from 'react-ga4';
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

ReactGA.initialize('G-3E1NX4KJZ9');

root.render(
	<CookiesProvider>
		<Provider store={store}>
			<App />
		</Provider>
	</CookiesProvider>
);

reportWebVitals();
