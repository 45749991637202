// React
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
// Utils
import { askGeoloc } from "src/utils/askGeoloc";
import { askSensors } from "src/utils/askSensors";


export function useAuthorizations() {
	// Cookies
	const [cookies, setCookie] = useCookies(["cookiesAllowed"]);
	// Geolocation
	const [geoLoc, setGeoLoc] = useState<boolean>(false);
	// Sensors
	const [sensors, setSensors] = useState<boolean>(false);

	const askAuthorization = () => {
		return new Promise<boolean>(async (resolve, reject) => {
			let geolocTemp = geoLoc;  
			try {
				if (!geoLoc) {
					let geoloc = await askGeoloc();

					if (geoloc) {
						setGeoLoc(true);
						geolocTemp = true;
					} else {
						setGeoLoc(false);
						geolocTemp = false;
					}
				}
			} catch (e: any) {
				setGeoLoc(false);
				geolocTemp=false;
			}
			if(geolocTemp) {
				resolve(true);
			} else {
				reject(false);
			}
		});
	};

	return {
		askAuthorization: askAuthorization,
		geoLoc: geoLoc,
		sensors: sensors,
	};
}
