// React
import React, { useEffect, useState } from "react";
// Redux
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";
// Components
import { GetTrad } from "src/utils/getTrad";
// Utils
import { toRad } from "../../../utils/toRad";
import { calcCrow } from "../../../utils/calcCrow";
// Style
import "./cardNav.css";
//Assets
import defaultPoiImage from "../../../style/assets/icons/cross.svg";
import compassIcon from "../../../style/assets/icons/compassIcon.svg";
import { setPath } from "src/components/Map/setPath";
import { haversineDistance } from "src/utils/haversineDistance";
export const CardNav = (props: any) => {
	const { language} = useSelector((state: IRootState) => state.app);
	const { coords } = useSelector((state: IRootState) => state.map);

	const [dist, setDist] = useState<number>();

	useEffect(() => {
		setPath(props.userCoords, props.poi.geometry.coordinates, props.map);

		return () => {
			setPath(coords, null, props.map);
		};
	}, []);


	useEffect(() => {
		if (props.geoLoc && props.userCoords && props.poi.geometry.coordinates) {
			
			const distance = haversineDistance(
				props.userCoords,
				props.poi.geometry.coordinates
			);
			setDist(distance);
		}

	}, [props.userCoords]);

	return (
		<React.Fragment>
			<div className='cardNav'>
				<div
					className='cardNav-content'
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<div className='cardNav-cross-container'>
						<div
							className='cardNav-quit'
							onClick={() => {
								props.setPoi(null);
								props.pageFcts.setCard(false);
								props.pageFcts.setCardNav(false);
							}}
						></div>
					</div>
					<div className='cardNav-contentWithoutButtons'>
						<div className='cardNav-title'>{props.poi.card.name || "L'ancienne fabrique d'allumettes"}</div>
						<div className='cardNav-omw-text-container'>
							<div className='cardNav-omw-barRight' />
							<div className='cardNav-omw-text'>
								<GetTrad value='onTheWay' lang={language} />
							</div>
							<div className='cardNav-omw-barLeft' />
						</div>
					</div>
					<div className='cardNav-button-container'>
						<div
							className='cardNav-button-compass-container'
							onClick={() => {
								props.pageFcts.setCard(false);
								props.pageFcts.setCardNav(false);
								props.pageFcts.setPage("go");
							}}
						>
							<div
								className='cardNav-button-compass-icon'
								style={{ backgroundImage: `url(${compassIcon})` }}
							/>
							<div className='cardNav-button-compass-text'>
								<GetTrad value='boussoleMode' lang={language} />
							</div>
						</div>
						{dist && dist <= 5000 ? (
							<div
								className='cardNav-button-validate-container'
								onClick={() => {
									props.pageFcts.setCard(false);
									props.pageFcts.setCardNav(false);
									props.pageFcts.setPage("posfound");
								}}
							>
								<div className='cardNav-button-validate-text'>
									<GetTrad value='validatePos' lang={language} />{" "}
								</div>
							</div>
						) : (
							<div className='cardNav-button-getClose-container'>
								<div className='cardNav-button-getClose-text'>
									<GetTrad value='getCloseToValidate' lang={language} />
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
