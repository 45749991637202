function isSafari() {
	if (navigator.userAgent.match("CriOS")) {
		return false;
	}
	let test = navigator.vendor.match(/[Aa]+pple/g);

	if (test && test.length > 0) {
		return true;
	}
	return false;
}

export const askSensors = () => {
	return new Promise<boolean>((resolve, reject) => {
		if (isSafari()) {
			if (
				typeof DeviceMotionEvent !== "undefined" &&
				typeof (DeviceMotionEvent as any).requestPermission === "function"
			) {
				(DeviceMotionEvent as any)
					.requestPermission()
					.then((response: any) => {
						if (response === "granted") {
							resolve(true);
						} else {
							reject(false);
						}
					})
					.catch((error: any) => {
						reject(false);
					});
			} else {
				reject(false);
			}

		} else {
			if (typeof (DeviceMotionEvent) !== "undefined" && typeof ((DeviceMotionEvent as any).requestPermission) === "function") {
				(DeviceMotionEvent as any).requestPermission().then((response: any) => {
					if (response === "granted") {
						resolve(true);
					} else {
						reject(false);
					}
				}).catch((error: any) => {
					console.error("Error requesting permission: " + error);
					reject(false);
				});
			} else {
				reject(false);
			}
		}
	});
};
