// React
import React, { useEffect, useState } from "react";
// Components
import { Sphere360 } from "./Sphere360/Sphere360";
// Style
import "./movie.css";
import { GetTrad } from "src/utils/getTrad";
// Assets
import seekLeftIcon from "../../style/assets/movie/seekLeftIcon.png";
import seekRightIcon from "../../style/assets/movie/seekRightIcon.png";
import playIcon from "../../style/assets/movie/playIcon.png";
import pauseIcon from "../../style/assets/movie/pauseIcon.png";
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";
import { isSafariBrowser } from "src/utils/isSafariBrowser";

const formatTime = (time: number) => {
	// let minutes = time / 60;
	// let seconds = time % 60;

	const minutes = Math.floor(time / 60);
	const seconds = time - minutes * 60;

	return (
		(minutes >= 0 ? minutes.toFixed(0) : "0") +
		":" +
		(seconds > 0 && seconds < 60 ? (seconds < 10 ? "0" : "") + seconds.toFixed(0) : "00")
	);
};

export const Movie = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);

	const [videoName, setVideoName] = useState("TRELAZE_3K");
	const [maxSize, setMaxSize] = useState(2880);
	const [playing, setPlaying] = useState<boolean>(true);
	const [intervalTime, setIntervalTime] = useState<NodeJS.Timeout>();

	// Sound
	const [sound, setSound] = useState(true);

	const toggleSound = () => {
		console.log("Toggle Sound Trigger");
		let videoElement = document.getElementById("video");

		if (videoElement) {
			(videoElement as HTMLVideoElement).muted = !(videoElement as HTMLVideoElement).muted;
			setSound(!sound);
		}
	};

	// Loader
	const [buffered, setBuffered] = useState(0);
	const [pausedSince, setPausedSince] = useState(50);
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		let inter = setInterval(() => {
			if (pausedSince > 0) {
				if (Date.now() - pausedSince > 1000) {
					setLoader(true);
					return;
				}
			}
			setLoader(false);
		}, 1000);

		return () => clearInterval(inter);
	});

	const newName = () => {
		let name = "TRELAZE";
		//if (maxSize < 2880) {
		name += "_2K";
		/*} else {
			name += '_3K';
		}*/
		console.log("VideoName =", name);
		setVideoName(name);
	};

	useEffect(() => {
		newName();
	}, []);

	useEffect(() => {
		let intervalTimeTmp: NodeJS.Timeout;

		let videoElem = document.getElementById("video");

		if (videoElem) {
			if (playing) {
				(videoElem as HTMLVideoElement).play();

				intervalTimeTmp = setInterval(() => {
					if (videoElem) {
						let timerElem = document.getElementById("videoInterfaceBottom-timerRight");

						if (timerElem) {
							let total = parseInt((videoElem as HTMLVideoElement).duration.toFixed(0));
							let partial = parseInt((videoElem as HTMLVideoElement).currentTime.toFixed(0));

							timerElem.innerHTML = formatTime(total - partial);
						}

						let timerLeftElem = document.getElementById("videoInterfaceBottom-timerLeft");

						if (timerLeftElem) {
							let partial = parseInt((videoElem as HTMLVideoElement).currentTime.toFixed(0));

							timerLeftElem.innerHTML = formatTime(partial);
						}
					}
				}, 1000);
			} else {
				(videoElem as HTMLVideoElement).pause();

				if (intervalTime) {
					clearInterval(intervalTime);
				}
			}
		}

		return () => clearInterval(intervalTimeTmp);
	}, [playing]);

	return (
		<div className='movie'>
			<div className='movie-image-container'>
				<div className='movie-image-background' style={{}}></div>
				<div className='movie-image' style={{}}></div>
				<div className='movie-band-stepContainer'>
					<div className='movie-band-step'>
						<GetTrad value='videoName' lang={language} />
					</div>
				</div>
				<div
					className='movie-quit-cross'
					onClick={() => {
						props.pageFcts.setPage("");
					}}
				></div>
			</div>
			<div className='viewport'>{<Sphere360 setMaxSize={setMaxSize} />}</div>

			<div className='videoInterface'>
				<div className='videoInterfaceBottom'>
					<div className='videoInterfaceBottom-buttons'>
						<div
							className='videoInterfaceBottom-button'
							style={{ backgroundImage: `url(${seekLeftIcon})` }}
							onClick={() => {
								let videoElement = document.getElementById("video");
								if (videoElement) {
									if ((videoElement as HTMLVideoElement).currentTime > 10) {
										(videoElement as HTMLVideoElement).currentTime -= 10;
									} else {
										(videoElement as HTMLVideoElement).currentTime = 0;
									}
								}
							}}
						></div>
						<div
							id='btnStart'
							className='videoInterfaceBottom-button videoInterfaceBottom-buttonPlay'
							style={{ backgroundImage: playing ? `url(${pauseIcon})` : `url(${playIcon})` }}
							onClick={() => {
								let videoElem = document.getElementById("video");

								if (videoElem) {
									if (
										(videoElem as HTMLVideoElement).paused ||
										(videoElem as HTMLVideoElement).duration === 0
									) {
										setPlaying(true);
									} else {
										setPlaying(false);
									}
								}
							}}
						></div>
						<div
							className='videoInterfaceBottom-button'
							style={{ backgroundImage: `url(${seekRightIcon})` }}
							onClick={() => {
								let videoElement = document.getElementById("video");
								if (videoElement) {
									if (
										(videoElement as HTMLVideoElement).currentTime <
										(videoElement as HTMLVideoElement).duration - 10
									) {
										(videoElement as HTMLVideoElement).currentTime += 10;
									} else {
										(videoElement as HTMLVideoElement).currentTime = (
											videoElement as HTMLVideoElement
										).duration;
									}
								}
							}}
						></div>
					</div>
					<div className='videoInterfaceBottom-progression'>
						<div id='videoInterfaceBottom-timerLeft' className='videoInterfaceBottom-timer'>
							0:00
						</div>
						<div
							id='progressbar'
							className='progressbar'
							onClick={(e) => {
								let progressbar = document.getElementById("progressbar");

								if (progressbar) {
									var offset = progressbar.getClientRects()[0];
									let dist = e.clientX - offset.left;
									let percentage = (dist * 100) / offset.width;

									let videoElement = document.getElementById("video");
									if (videoElement) {
										(videoElement as HTMLVideoElement).currentTime =
											(percentage / 100) * (videoElement as HTMLVideoElement).duration;
									}
								}
							}}
							onTouchEnd={(e) => {
								let progressbar = document.getElementById("progressbar");

								if (progressbar) {
									var offset = progressbar.getClientRects()[0];
									let dist = e.changedTouches[0].clientX - offset.left - 10;
									let percentage = (dist * 100) / offset.width;

									let videoElement = document.getElementById("video");
									if (videoElement) {
										(videoElement as HTMLVideoElement).currentTime =
											(percentage / 100) * (videoElement as HTMLVideoElement).duration;
									}
								}
							}}
						>
							<div
								className='buffered'
								style={{
									height: "4px",
									position: "absolute",
									background: "#fff",
									width: "calc(100% - 20px)",
									left: "10px",
								}}
							>
								<span
									id='buffered-amount'
									style={{
										display: "block",
										height: "100%",
										backgroundColor: "#aaa",
										width: "0",
										opacity: "0.6",
									}}
								></span>
							</div>
							<div
								className='progress'
								style={{
									zIndex: 10000,
									height: "4px",
									position: "absolute",
									width: "calc(100% - 20px)",
									left: "10px",
								}}
							>
								<span
									id='progress-amount'
									style={{
										display: "block",
										height: "100%",
										backgroundColor: "#7488E0",
										width: "0",
									}}
								></span>
							</div>
						</div>
						<div id='videoInterfaceBottom-timerRight' className='videoInterfaceBottom-timer'>
							0:00
						</div>
						{/* <div
							style={{ width: "50px", height: "50px", backgroundColor: "red" }}
							onClick={(e) => askSensors(e.target)}
						>
							a
						</div> */}
					</div>
				</div>
			</div>

			{/* {loader && <Loader buffered={buffered} />} */}
			<video
				id='video'
				autoPlay
				playsInline
				preload='metadata'
				width='0'
				height='0'
				src={"./assets/videos/" + videoName + ".mp4"}
				style={{
					position: "absolute",
					opacity: 0,
					margin: 0,
					padding: 0,
				}}
				onEnded={(e) => {
					props.pageFcts.setPage("");
				}}
				onCanPlay={(e) => {
					// e.currentTarget.play();
					let homeElement = document.getElementById("home");

					if (homeElement) {
						homeElement.style.pointerEvents = "none";
					}
				}}
				onWaiting={(e) => {
					setPausedSince(Date.now());

					let homeElement = document.getElementById("home");

					if (homeElement) {
						homeElement.style.pointerEvents = "all";
					}
				}}
				onPlaying={(e) => {
					setPausedSince(-1);

					let homeElement = document.getElementById("home");

					if (homeElement) {
						homeElement.style.pointerEvents = "none";
					}
				}}
				onProgress={(e) => {
					let video = e.currentTarget;

					if (video) {
						if (video.buffered.length > 0) {
							var bufferedEnd = video.buffered.end(video.buffered.length - 1);
							var duration = video.duration;
							if (duration > 0) {
								let bufferedAmount = document.getElementById("buffered-amount");
								if (bufferedAmount) {
									bufferedAmount.style.width = (bufferedEnd / duration) * 100 + "%";
									setBuffered(Math.trunc((bufferedEnd / duration) * 100));
								}
							}
						}
					}
				}}
				onTimeUpdate={(e) => {
					let video = e.currentTarget;

					if (video) {
						var duration = video.duration;
						if (duration > 0) {
							let progressAmount = document.getElementById("progress-amount");
							if (progressAmount) {
								progressAmount.style.width = (video.currentTime / duration) * 100 + "%";
							}
						}
					}
				}}
			/>
		</div>
	);
};
