// React
import { createReducer } from "@reduxjs/toolkit";
// Types
import { MAP_COORDS, MAP_HEADING, IAction } from "../actions/types";

export interface IMapState {
	coords: [number, number];
	heading: number;
}

const initialState: IMapState = {
	coords: [0, 0],
	heading: 0,
};

export const reducer = createReducer(initialState, (builder: any) => {
	builder.addCase(MAP_COORDS, (state: any, action: IAction) => {
		state.coords = action.payload;
	});
	builder.addCase(MAP_HEADING, (state: any, action: IAction) => {
		let newAngle = action.payload > 180 ? action.payload - 360 : action.payload;
		state.heading = action.payload === null ? 0 : newAngle;
	});
});
