// React
import React from "react";
// Redux
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";
// Components
// Style
import "./infos.css";
// Utils
import { GetTrad } from "src/utils/getTrad";
// Datas
import { ranks } from "../../datas/ranks";

export const Infos = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);
	const { pois } = useSelector((state: IRootState) => state.game);

	return (
		<React.Fragment>
			<div className='infos'>
				<div className='infos-quit'>
					<div
						className='infos-quit-cross'
						onClick={() => {
							props.pageFcts.setPage(null);
						}}
					></div>
				</div>
				<div className='infos-content'>
					<div className='infos-titleGroup'>
						<h2 className='infos-pretitle'>
							<GetTrad value='Title1' lang={language} />
						</h2>
						<h1 className='infos-title'>
							<GetTrad value='Title2' lang={language} />
							<br />
							<GetTrad value='Title3' lang={language} />
						</h1>
					</div>

					<div className='infos-text'>
						<GetTrad value='infoText1' lang={language} />
						<br />
						<GetTrad value='infoText2' lang={language} />
						<br />
						<div className='infos-rank'>
							<GetTrad
								value={
									ranks.find((r: any) => {
										return (
											r.min <= pois.filter((p: any) => p.discovered).length &&
											r.max > pois.filter((p: any) => p.discovered).length
										);
									})?.id || ""
								}
								lang={language}
							/>
						</div>

						<GetTrad
							value={
								ranks.find((r: any) => {
									return (
										r.min <= pois.filter((p: any) => p.discovered).length &&
										r.max > pois.filter((p: any) => p.discovered).length
									);
								})?.idDesc || ""
							}
							lang={language}
						/>
					</div>
					<div className='infos-anim'>
						<div className='infos-anim-dot infos-anim-dot1'></div>
						<div className='infos-anim-dot infos-anim-dot2'></div>
						<div className='infos-anim-dot infos-anim-dot3'></div>
						<div className='infos-anim-dot infos-anim-dot4'></div>
						<div className='infos-anim-dot infos-anim-dot5'></div>
						<div className='infos-anim-cursor'></div>
					</div>
					<p className='infos-text'>
						<GetTrad value='infoText3' lang={language} />
					</p>
					<p className='infos-time'>
						<GetTrad value='infoTextTime' lang={language} />
					</p>
					<p
						className='infos-credits'
						onClick={() => {
							props.pageFcts.setPage("credits");
						}}
					>
						<GetTrad value='credits' lang={language} />
					</p>
				</div>
				<div className='infos-background'>
					<h1 className='infos-background-1'></h1>
					<h1 className='infos-background-2'></h1>
					<h1 className='infos-background-3'></h1>
				</div>
			</div>
		</React.Fragment>
	);
};
