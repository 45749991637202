// React
import React, { useEffect, useState } from "react";
// Components

// Utils
import { calcCrow } from "../../../utils/calcCrow";
import { toRad } from "../../../utils/toRad";
import { haversineDistance } from "src/utils/haversineDistance";
// Style
import "./card.css";
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";
import { GetTrad } from "src/utils/getTrad";
// Assets
import trameSeperationImg from "../../../style/assets/global/TramePois.jpg";
import defaultPoiImage from "../../../style/assets/pois/POI_Preview.png";
import alreadyVisitedImage from "../../../style/assets/map/PoiSeen.png";

export const Card = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);
	const { coords } = useSelector((state: IRootState) => state.map);
	const { pois } = useSelector((state: IRootState) => state.game);

	const [dist, setDist] = useState<number>();

	useEffect(() => {
		if (props.geoLoc && props.userCoords && props.poi.geometry.coordinates) {
			
			const distance = haversineDistance(
				props.userCoords,
				props.poi.geometry.coordinates
			);
			setDist(distance);
		}
	}, [props.userCoords]);

	return (
		<React.Fragment>
			<div className='card'>
				<div
					className='card-content'
					onClick={(e) => {
						e.stopPropagation();
					}}
				>
					<div className='card-image-container'>
						<div
							className='card-image'
							style={{
								backgroundSize: props.poi.card.backgroundSize,
								backgroundPosition: props.poi.card.backgroundPosition,
								backgroundImage: props.poi.card.img,
							}}
						></div>
						<div className='card-image-trameSeparation'></div>
					</div>
					{pois.find((p: { id: string }) => p.id === props.poi.properties.id)?.discovered ? (
						<div
							className='card-image-alreadyVisited'
							style={{ backgroundImage: `url(${alreadyVisitedImage})` }}
						/>
					) : null}
					<div className='card-text-container'>
						<div className='card-text'>{props.poi.card.name || "L'ancienne fabrique d'allumettes"}</div>
						<div className='card-distance-text-container'>
							<div className='card-distance-barRight' />
							<div className='card-distance-text'>
							{dist && dist > 1000
								? (dist / 1000).toFixed(1) + "km"
								: dist
								? dist.toFixed(0) + "m"
								
						
								: (
									<React.Fragment>
										<GetTrad value='step' lang={language} /> {" " + props.poi.properties.step}
									</React.Fragment>
								)}
							</div>
							<div className='card-distance-barLeft' />
						</div>
					</div>

					<div className='card-buttons'>
						<div
							className='card-button card-button-white'
							onClick={() => {
								props.pageFcts.setCard(false);
								props.pageFcts.setCardNav(false);
								props.pageFcts.setPage("poi");
							}}
							style={{
								width: props.geoLoc ? "calc(47% - 4px)" : "calc(100%)",
								marginRight: props.geoLoc ? "3%" : "0",
								userSelect: "none",
								cursor: "pointer",
							}}
						>
							Voir
						</div>
						{(props.geoLoc) && (
							<div
								className='card-button card-button-black'
								onClick={() => {
									props.pageFcts.setCardNav(true);
									props.pageFcts.setCard(false);
								}}
							>
								Y aller
							</div>
						)
						}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
