// React
import React, { useState } from "react";
// Redux
import { Dispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../reducers";
// Utils
import { GetTrad } from "../../utils/getTrad";
// Style
import "./splashscreen.css";
import { useCookies } from "react-cookie";

// Assets
import mainImg from "../../style/assets/splashscreen_mainImg.jpg";
import arrowRight from "../../style/assets/icons/arrowRight.png";

export const Splashscreen = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);
	const [showPopup,setShowPopup] = useState<boolean>(false);

	return (
		<React.Fragment>
			<div className='splashscreen'>
				<div className='splashscreen-content'  style = {{filter: showPopup ? 'blur(5px)' : 'none'}}>
					<div className='splashscreen-text'>
						<h2 className='splashscreen-pretitle'>
							<GetTrad value='Title1' lang={language} />
						</h2>
						<h1 className='splashscreen-title'>
							<GetTrad value='Title2' lang={language} />
							<br />
							<GetTrad value='Title3' lang={language} />
						</h1>
						<p className='splashscreen-subtitle'>
							<GetTrad value='splashText1' lang={language} />
							<br />
							<GetTrad value='splashText2' lang={language} />
							<br />
							<GetTrad value='splashText3' lang={language} />
						</p>
					</div>

					<div className='splashscreen-img' style={{ backgroundImage: `url(${mainImg})` }}>
						<div className='splashscreen-background'>
							<h1 className='splashscreen-background-tre'>
								<GetTrad value='splashTitle1' lang={language} />
							</h1>
							<h1 className='splashscreen-background-la'>
								<GetTrad value='splashTitle2' lang={language} />
							</h1>
							<h1 className='splashscreen-background-ze'>
								<GetTrad value='splashTitle3' lang={language} />
							</h1>
						</div>
					</div>
				</div>
				{showPopup && (
					<div className='popup'>
						<div className='popup-content'>
							<div className='popup-text1'><GetTrad value='popupGeolocText' lang={language} /></div>
							<div className='popup-button' onClick={ () => {
								setShowPopup(false);
								props.pageFcts.setPage("")
								}}><GetTrad value='popupGeolocContinue' lang={language} />
							</div>
							<div className='popup-text2'><GetTrad value='popupGeolocWarning' lang={language} /></div>
						</div>
					</div>
				)} 
				

				<div
					className='splashscreen-button'
					onClick={async () => {
						try {
							await props.askAuthorization();
							props.pageFcts.setPage("");
						} catch (e) {
							setShowPopup(true);
						}
					}}
				>
					<div className='splashscreen-button-text'>
						<GetTrad value='splashStartExp' lang={language} />
					</div>
					<div className='splashscreen-button-arrow' style={{ backgroundImage: `url(${arrowRight})` }}></div>
				</div>
			</div>
		</React.Fragment>
	);
};
