export const trads = [
	{
		/* --- French --- */
		id: "fr",
		name: "Français",
		flag: "flagFr",
		// Reusable text
		// Title
		Title1: "Devenez",
		Title2: "Chasseur",
		Title3: "d'histoires",
		// Buttons
		boussoleMode: "Mode boussole",
		validatePos: "Valider ma position",
		// Ranks
		leader: "Secrétaire",
		cherubin: "Chérubin",
		perreyeux: "Perreyeux",
		secretary: "Secrétaire de la Chambre Syndicale",
		cherubinDesc: "APPRENTI-ARDOISIER",
		perreyeuxDesc: "ARDOISIER CONFIRMÉ",
		secretaryDesc: "REPRÉSENTE TOUS LES ARDOISIERS",

		// Informations page
		infoText1: "Bienvenue dans l'aventure !",
		infoText2: "Vous êtes pour le moment",
		infoTextRank: "Chérubin",
		infoTextRankDescription: "c'est-à-dire apprenti ardoisier.",
		infoText3:
			"Au fur et à mesure de votre quête, collectez des objets pour monter en grade ! 10 étapes pour découvrir les facettes visibles et invisibles de Trélazé. Bonne chance !",
		infoTextTime: "Durée totale du parcours : 2h +",
		credits: "Crédits",

		// Splash screen
		// Text
		splashText1: "Un parcours d'étapes",
		splashText2: "pour remonter le temps",
		splashText3: "à Trélazé",
		// Title
		splashTitle1: "Tré",
		splashTitle2: "la",
		splashTitle3: "zé",
		// Button
		splashStartExp: "Lancez l'expérience",
		content: "Contenus",
		objects: "Objets",

		// Popup - Missing geoloc
		popupGeolocText: "Êtes vous sûr de vouloir continuer sans la géolocalisation ?",
		popupGeolocContinue: "Continuer quand même",
		popupGeolocWarning: "Attention, désactiver la géocalisation peut affecter votre expérience de manière significative, nous vous recommendons de l'activer depuis les paramètres de votre téléphone.",

		// Map
		loadingProgress: "Chargement en cours...",
		discoverPoint: "Cliquez sur un point d'intérêt pour le découvrir !",
		discoverInventory: "Cliquez ici pour voir votre inventaire",

		// CardNav 
		onTheWay:"Trajet en cours",
		getCloseToValidate: "Rapprochez-vous pour valider l’étape",

		// Cookies
		cookiesTitle: "Ce site internet utilise des cookies",
		cookiesContent:
			"Nous utilisons des cookies pour nous assurer de vous offrir la meilleure expérience sur notre site Web. Si vous continuez à utiliser ce site, nous supposerons que vous en êtes satisfait.",
		cookiesAccept: "Accepter",
		cookiesDenied: "Refuser",
		cookiesReadPrivacyPolicy: "Lire la politique de confidentialité",

		// Discover
		congrats: "Bravo !",
		youFound: "Vous avez trouvé : ",
		objsFound: "objet trouvé",
		objsFoundPlurial:"objets trouvés",
		// ObjectFound
		youWin: "Vous gagnez :",
		// QuizUnlock
		youUnlocked: "vous avez débloqué : ",
		theQuiz: "Le Quiz",
		trelazeExpert:"Êtes-vous un véritable expert de Trélazé ?",
		//QuizResult
		quizResultFinished:"Quiz terminé",
		quizResultPoints:" points",
		quizResultYourRank:"Votre grade",
		quizResultRank1: "Chérubin", // Also used for finalRank display
		quizResultRank2: "Perreyeux", // Also used for finalRank display
		quizResultRank3: "Secrétaire",// Also used for finalRank display
		quizResultRank3Full: "Secrétaire de la Chambre Syndicale", // Also used for finalRank display
		quizResultBackToMap: "Retour à la carte",
		quizResultRedoQuiz: "Refaire le quiz",
		quizResultConclusion1: "Cher Chérubin, il faut revoir votre copie si vous souhaitez monter en grade ! Il vous reste encore des choses à apprendre pour passer Perreyeux.",
		quizResultConclusion2: "Bravo ! Vous êtes bien ardoisier confirmé ! Les mines de Trélazé ont affaire à un fin connaisseur.",
		quizResultConclusion3: "Félicitations ! Vous voilà suffisamment expert de Trélazé pour représenter vos camarades à la Chambre syndicale des ardoisiers, quel honneur !",
		// PodcastUnlock
		podcastCongrats: "Félicitations,",
		podcastYouDiscovered: "vous avez découvert",
		podcast5steps: "5 étapes",
		podcastYouAreNow:"Vous voilà désormais",
		podcastPerreyeux:"PERREYEUX",
		podcastText1:"Tout ardoisier a le droit à son surnom, alors vous aussi ! En voici quelques exemples savoureux : ",
		podcastContinueAdventure:"Poursuivre l'aventure",

		// Inventory
		progress: "Ma progression",
		film360: "Le film 360°",
		movieName: '"Voyage au coeur de Trélazé"',
		secretPodcast: "Le podcast secret",
		podcastName: '"Pseudonymes ardoisiers"',
		finalTest: "L'épreuve finale",
		find5toDiscover: "Trouvez 5 objets pour déverouiller",
		find10toDiscover: "Trouvez 10 objets pour déverouiller",
		play: "Jouer",

		level: "Niveau",
		collectedObject: "Objet collecté",
		collectedObjectPlurial: "Objets collectés",

		inventoryCardStep1: "La première fabrique d'allumettes",
		inventoryCardStep2: "La Butte de l'Enfer",
		inventoryCardStep3: "Les Favereaux",
		inventoryCardStep4: "Les vieux-fonds (La Brémandière)",
		inventoryCardStep5: "Le Moulin du Poirier",
		inventoryCardStep6: "La Butte du Buisson",
		inventoryCardStep7: "La Pyramide",
		inventoryCardStep8: "La Place des Tellières",
		inventoryCardStep9: "La seconde manufacture d'allumettes",
		inventoryCardStep10: "La Maraîchère",

		// Quiz
		question: "Question",
		correctAnswer: "Vous avez bien répondu !",
		uncorrectAnswer: "Vous avez répondu",
		nextQuestion: "Question suivante",
		lastQuestion: "Résultats",

		primMaterial: "Quel est le nom de la matière première extraite dans les carrières ardoisières de Trélazé ?",
		primMaterialCalcaire: "Le calcaire",
		primMaterialCoal: "Le charbon",
		primMaterialSchiste: "Le schiste",
		primMaterialNickel: "Le nickel",
		primMaterialAnswer: "La matière première extraite se nomme",
		primMaterialExplanations:
			"En effet, l’ardoise appartient à la famille des schiste argileux. Après extraction, on la divise en feuillets minces que l’on utilise de bien des façons : en couverture des maisons, au sol, en tablette… ou pour le toit d’une fabrique d'allumettes par exemple !",

		structureToGoDown: "Quelle est la structure la plus utilisée pour descendre au fond des carrières ?",
		structureToGoDownCheval: "Le cheval",
		structureToGoDownChevalement: "Le chevalement",
		structureToGoDownCheminement: "Le cheminement",
		structureToGoDownChameau: "Le chameau",
		structureToGoDownAnswer: "La structure la plus utilisée pour descendre est",
		structureToGoDownExplanations:
			"Le chevalement, c’est LA signature du paysage de Trélazé. Dans de nombreux endroits de la ville, vous pouvez apercevoir ces grandes structures métalliques.",

		employeeMine: "Comment appelle-t-on un ouvrier des carrières d’ardoises ?",
		employeeMinePerreyeux: "Un perreyeux",
		employeeMineMineur: "Un mineur",
		employeeMineHouilleur: "Un houilleur",
		employeeMineCarrier: "Un carrier",
		employeeMineAnswer: "Un ouvrier des carrières d'ardoises s'appelle",
		employeeMineExplanations:
			"À Trélazé, les ouvriers qui travaillent l’ardoise sont tous des perreyeux. Allez, encore quelques bonnes réponses et vous obtiendrez vous aussi ce statut !",

		oldHole: "Qu’est-ce qu’un vieux-fond ?",
		oldHoleRetraite: "Un perreyeux à la retraite",
		oldHoleSouterrain: "Un souterrain de la mine",
		oldHoleGrotte: "Une grotte très ancienne",
		oldHoleEau: "Une ancienne carrière recouverte d’eau",
		oldHoleAnswer: "Un vieux-fond est",
		oldHoleExplanations:
			"Et oui, le schiste est extrait dans des carrières dites “à ciel ouvert” formant d’immenses trous. Lorsque la carrière est abandonnée, ces anciens fonds se remplissent d’eau, formant ainsi les différents lacs que l’on aperçoit à Trélazé.",

		dangerExploitation: "Quel est le plus grand danger dans les exploitations ardoisières ?",
		dangerExploitationEau: "L’eau",
		dangerExploitationFeu: "Le feu",
		dangerExploitationDisputes: "Les disputes entre perreyeux",
		dangerExploitationAir: "Le manque d’air",
		dangerExploitationAnswer: "Le plus grand danger est",
		dangerExploitationExplanations:
			"En tout temps, les ouvriers ont cherché des solutions pour éviter à tout prix que la carrière se gorge d’eau… sinon c’est l’accident, l’inondation ou la fermeture de l’exploitation assurée !",

		deeperSchiste: "Jusqu’à quelle profondeur extrait-on le schiste à Trélazé ?",
		deeperSchiste225: "225 m",
		deeperSchiste325: "325 m",
		deeperSchiste425: "425 m",
		deeperSchiste525: "525 m",
		deeperSchisteAnswer: "On extrait le schiste à Trélazé jusqu'à",
		deeperSchisteExplanations:
			"Grâce aux avancées technologiques, les descenderies contemporaines de Trélazé s’enfonçaient très profondément sous la terre.",

		pyramideTrelaze: "Que symbolise la pyramide de Trélazé ?",
		pyramideTrelazeNapoleon: "Le passage de Napoléon III à Trélazé",
		pyramideTrelazeLoire: "La fin des travaux de la levée de la Loire",
		pyramideTrelazeCommission: "La fondation de la Commission des ardoisières d’Anger",
		pyramideTrelazeEgypte: "Les vestiges de l’Egypte antique",
		pyramideTrelazeAnswer: "La pyramide de Trélazé symbolise",
		pyramideTrelazeExplanations:
			"La pyramide est érigée en 1744 pour célébrer la grande levée de la Loire allant d’Orléans jusqu’à Nantes. Cela n’empêchera pas d’autres inondations par la suite…",

		employeeHome: "Quel est le nom des habitations des ouvriers ?",
		employeeHomeLotissement: "Un lotissement",
		employeeHomeGratteCiel: "Un gratte-ciel",
		employeeHomeEnsemble: "Un grand ensemble",
		employeeHomeCiteJardin: "Une cité-jardin",
		employeeHomeAnswer: "Le nom des habitations des ouvriers est",
		employeeHomeExplanations:
			"Le concept de cité-jardin naît à la fin du XIXe siècle en plein boom de la Révolution industrielle. Des quartiers entiers dédiés aux familles ouvrières sont construits, faits de maisons individuelles agréables et salubres, avec des lieux communs comme les lavoirs.",

		manuStruct: 'Quel est l’autre surnom de la "Manu", seconde manufacture d’allumettes de Trélazé ? ',
		manuStructParadis: "Le paradis",
		manuStructMineOr: "La mine d’or",
		manuStructModerne: "La moderne",
		manuStructFeminine: "La féminine",
		manuStructAnswer: "L'autre surnom de la \"Manu\" est'",
		manuStructExplanations:
			"La Manu était un exemple de modernité et d’évolution sociale, avec des salaires très alléchants pour l’époque.. d’où ce surnom de mine d’or.",

		ludovicMenard: "Quel est l’épitaphe de Ludovic Ménard ?",
		ludovicMenardEngagement: "« Son engagement, toujours et à jamais dans les coeurs de tous les perreyeux. »",
		ludovicMenardPoingLeve: "« Un homme qui visait la lune, et toujours le point levé. »",
		ludovicMenardCombat:
			"« Sa vie, au service des travailleurs, fut un combat permanent pour la justice et la paix »",
		ludovicMenardHommeAction: "« Jamais Trélazé ne connu pareil homme d’action. »",
		ludovicMenardAnswer: "L'épitaphe de Ludovic Ménard est'",
		ludovicMenardExplanations:
			"Ludovic Ménard est un icône trélazéenne qui a œuvré toute sa vie pour les droits des ardoisiers trélazéens. Son épitaphe rend donc hommage à son engagement continu.",

		backMap: "Retour à la carte",
		retryQuiz: "Refaire le quiz",
		followAdventure: "Poursuivre l'aventure",

		// --- Pois ---
		videoName: "Voyage au coeur de Trélazé",

		//Miscs
		step: "Étape ",
		buttonListen: "Écouter",
		buttonShare: "Partager",

		// Step 1 - La première manufacture d’allumettes
		//Title
		step1Title: "Des femmes et des allumettes",

		//Hints
		step1Hint1:
			"La première fabrique d’allumettes est aujourd’hui un lieu où l’on peut consulter des ouvrages ou aller voir des spectacles… Voyez-vous de quoi il s’agit ?",

		step1HintClose1:
			"Allez un autre indice parce que vous chauffez ! De ce point, on peut observer des chevalements… à ne pas confondre avec des chevaux ! Vous y êtes ?",
		//Subtitles
		step1Subtitle1: "Une petite fabrique familiale",
		step1Subtitle2: "La recette d'une fabrique d’allumettes traditionnelle",
		step1Subtitle3: "Les allumettières ou les femmes d’ardoisiers au travail",
		step1Subtitle4: "Les femmes au cœur de la lutte ouvrière",

		//Texts
		step1Text1:
			"À Trélazé, le craquage d’allumettes est une discipline prise très au sérieux !\nPour cause, l’industrie allumettière est venue s’implanter en ville au XIXe siècle, ce qui a permis aux Trélazéennes de travailler, enfin !",
		step1Text2:
			"La première fabrique d’allumettes de la ville ouvre à Trélazé en mars 1864 grâce à deux frères, Jacques et Charles Lebatteux. Comment s’organise cette manufacture typique de l’activité allumettière ? On vous donne la recette !",
		step1Text3:
			"Tout d’abord, il vous faut un bâtiment suffisamment grand pour accueillir tous les ouvriers et ouvrières. L’ingrédient indispensable pour sa construction : le schiste ardoisier bien-sûr ! Puis, ajoutez-y une grosse cuillère de fonctionnalité. Enfin, n’oubliez pas de bien séparer et de spécialiser chaque espace de travail.\nVotre manufacture est prête à ouvrir… non ! Il manque encore l’élément majeur sans quoi la fabrique ne peut fonctionner : les petites mains.",
		step1Text5:
			"Comme toute préparation, les ingrédients nécessaires à la recette ont un prix ! Les frères Lebatteux voient dans la Commission des Ardoisières d’Angers un partenaire tout trouvé, mais rien n’est donné sans contrepartie ! Les Lebatteux doivent embaucher en priorité les femmes des ardoisiers de Trélazé car le financement est calculé au prorata des épouses embauchées.",
		step1Text6:
			"Grâce à cet accord, les femmes peuvent enfin travailler, ce qui n’était pas le cas dans les carrières d’ardoise, et améliorer les conditions de vie de leurs familles.",
		step1Text7:
			"Aussi, cela donne l’occasion aux femmes de participer activement à la lutte syndicale ! Et oui, c’est une femme, Clémentine Maheux, qui est élue présidente de la chambre syndicale et c’est une autre femme, Louise Ménard, épouse du militant Ludovic Ménard, qui représente Trélazé au deuxième congrès de la Fédération nationale des ouvriers et ouvrières des manufactures d’allumettes de France en 1894.",
		step1Text8: "La voix des femmes se fait entendre !",
		step1Text9: "Ecoutez donc la vie de ces femmes-ouvrières en cliquant juste ici.",
		step1Text10:
			"Rappel concept de cet audio : un témoignage fictif propose une conversation entre une allumettière et une fendeuse d’ardoise où elles évoquent leur travail en tant que femmes dans ce monde ouvrier difficile et à majorité masculin.",

		//Anecdotes
		step1Anecdote1Title: "Les Trélazéens, amoureux des allumettes depuis toujours ?",
		step1Anecdote1Text:
			"Saviez-vous que, dans la région, de nombreuses personnes fabriquaient leurs allumettes chez elles, dans leur coin ? Bien avant l’arrivée de la manufacture, à Trélazé, on aime craquer les allumettes ! C’est Jean Labbé, habitant aux Favereaux, qui en fabrique le premier. C’est grâce à cette production artisanale que s’installe la production industrielle d’allumettes à Trélazé.",

		// Step 2 - La Butte de l’enfer
		//Title
		step2Title: "La Butte de l'Enfer",

		//Hints
		step2Hint1:
			"Pour accéder à l’enfer, c’est une ascension vers l’une des plus hautes buttes du parc des ardoisières qu’il faut faire !",
		step2Hint1Close:
			"Allez un autre indice parce que vous chauffez ! De ce point, on peut observer des chevalements… à ne pas confondre avec des chevaux ! Vous y êtes ?",

		//Subtitles
		step2Subtitle1: "À la recherche des chevalements",
		step2Subtitle2: "À cheval sur les chevalements !",
		step2Subtitle3: "Apparences d’un chevalement",

		//Texts
		step2Text1:
			"Aujourd’hui, Trélazé est la dernière ville de France à compter autant de chevalements sur son territoire… et quoi de mieux que de gravir la Butte de l’Enfer pour les admirer ! La vue est imprenable sur le parc des ardoisières et sur les chevalements… mais attendez, vous ne savez peut-être pas ce qu'est un chevalement ? On vous explique.",
		step2Text2:
			"Dans les mines ardoisières, les chevalements sont des structures qui servent à descendre et remonter les mineurs du fond de la mine, mais pas que ! Il y a aussi un cheval dans la cage qui le plonge dans le cauchemar du schiste. Le matériel et les stocks de blocs de schiste ardoisier sont aussi remontés par ce biais-là.",
		step2Text3:
			"Depuis 1838, le chevalement a eu bien des visages ! D’abord en bois, il est ensuite construit en fer, ce qui permet de supporter beaucoup plus de charges. Aujourd’hui, vous ne pouvez apercevoir que des chevalements métalliques. À vous de les repérer !",

		//Anecdotes
		step2Anecdote1Title: "Le Champ Robert a de quoi rendre fier !",
		step2Anecdote1Text:
			"Ce chevalement résume à lui seul l’évolution des puits des carrières d’ardoise trélazéennes. Et oui, vous le voyez en métal, mais en 1930 il est en bois avec un puits creusé d’une profondeur de 259 mètres, rien que ça !  Il est ensuite remplacé par un chevalement métallique. Il communique avec son voisin, le puits 25 Fresnais par des galeries. Son exploitation a cessé en 1950.",

		step2Anecdote2Title: "Des montagnes de déchets ardoisiers",
		step2Anecdote2Text:
			"Saviez-vous qu’à l’origine Trélazé était une plaine ? Et oui ! C’est l’exploitation ardoisière qui en a fait un territoire mythique de buttes et de vieux fonds, façonné par six siècles de travail de l’homme.",
		step2Audio: "Au pied du chevalement, quel vacarme !",

		// Step 3 - L'ancienne fabrique d'allumettes -

		//Title
		step3Title: "Dans le village des perreyeux",

		//Hints
		step3Hint1: "Pour trouver les Favereaux, il faut chercher des ruines. Cela vous dit quelque chose?",
		step3HintClose1:
			"Si vous êtes au cœur du parc des ardoisières, non loin de la butte de l’enfer… c’est que vous brûlez !",

		//Subtitles
		step3Subtitle1: "Un hameau typique",
		step3Subtitle2: "Un hameau abandonné",
		step3Subtitle3: "Audio associé : Une habitante nous raconte son ancienne vie aux Favereaux !",
		//Texts
		step3Text1:
			"Aujourd’hui, le hameau des Favereaux n’est plus que ruines. On peine à s’imaginer qu’il y a encore un siècle le village était bien vivant, habité par de nombreuses familles de perreyeux, c’est-à-dire des ouvriers des carrières d’ardoises…",
		step3Text2:
			"À l’époque, le village se compose d'une petite dizaine de bâtiments, dans lesquels quatre à six personnes vivent par foyer. Au centre du hameau se trouve un four à pain commun, des latrines et deux puits. Entre 1795 et 1921, pas plus d’une centaine d’habitants y vivent.",
		step3Text3:
			"Le hameau est ensuite partiellement abandonné en 1942, lorsque les cités ouvrières se développent en nombre dans toute la région. Le dernier locataire quitte le site en 1970. Depuis, la végétation a repris ses droits.",
		step3Text4:
			"Pour s'immerger dans la vie aux Favereaux, écoutez le témoignage d’un de ses derniers habitants en cliquant ici.",
		//Anecdotes
		step3Anecdote1Title: "Un hameau-témoin cristallisé",
		step3Anecdote1Text:
			"En 2011, un chantier d'insertion a pris en charge la restauration, la reconstruction et la remise en état des murs des maisons. C’est ce qu’on appelle un hameau cristallisé, c’est-à-dire dont les ruines ont été conservées telles quelles. Véritable témoin des conditions de vie très difficiles et spartiates des ouvriers ardoisiers, les ruines des Favereaux laissent imaginer les habitations précaires, notamment l’étroitesse des maisons.",

		step3Anecdote2Title: "Une découverte insoupçonnée !",
		step3Anecdote2Text:
			"Les personnes du chantier d’insertion ont découvert, enfoui dans la terre, une bouteille remplie de pois blancs et noirs. Ces derniers ont servi non pas en cuisine, mais pour savoir si les habitants allaient faire grève ou non. Si le pois est blanc, c’est un oui pour la grève, s’il est noir, c’est un non. Encore plus surprenant, cette tradition vient des congrégations religieuses qui l’utilisaient pour savoir si les ecclésiastiques pouvaient prendre la parole ou non. C'est de là que vient l’expression “avoir droit au chapitre”… bien loin des luttes ouvrières donc !",

		// Step 4 - Les vieux-fonds (La Brémandière) -

		//Title
		step4Title: "Des carrières à ciel ouvert, devenues des vieux-fonds",

		//Hints
		step4Hint1:
			"Pour plonger dans l’histoire des vieux-fonds, rendez-vous au bord du plan d’eau le plus proche du parking !",
		step4HintClose1:
			"Vous êtes prêts du but ! Vous devriez apercevoir, sur votre gauche, un plan d’eau bien sûr, mais aussi un panneau explicatif. Vous y êtes ?",

		//Subtitles
		step4Subtitle1: "La Brémandière",
		step4Subtitle2: "À fond pour la carrière à ciel ouvert !",
		step4Subtitle3: "La carrière VS l’eau : un combat de tous les instants",
		step4Subtitle4: "Audio associé : À l’eau !",

		//Texts
		step4Text1:
			"Pourquoi appelons-nous les plans d’eau de Trélazé des “vieux-fonds” ? Parce que s’y cachent d’anciens fonds de carrières, aujourd’hui remplis d’eau. La Brémandière est un bel exemple de vieux-fond, puisqu’elle est aussi une des plus vieilles carrières de la ville !",
		step4Text2:
			"Les historiens ont trouvé des traces d’une exploitation ardoisière à Trélazé, dès 1406 mais la Brémandière date du début XVIIIème siècle ! Notamment active au XIXe siècle, elle a pour particularité d’être ce que l’on appelle une carrière à ciel ouvert. On vous en dit plus.",
		step4Text3:
			"Pour extraire le schiste ardoisier, plusieurs options sont possibles, mais dans tous les cas… il faut creuser ! Ici, la Brémandière est qualifiée de carrière à ciel ouvert car elle est exploitée à l’air libre, sans tunnel souterrain. C’est tout bonnement un grand trou. Le plus grand ennemi de la carrière : l’eau !",
		step4Text4:
			"Il ne faut absolument pas que la carrière se gorge d’eau, sinon, c’est l’inondation et cela peut mettre en danger tous les ouvriers du fond ! D’abord, l’extraction de l’eau s’est faite grâce à des manèges activés par la force animale, puis par des moulins à vent (appelés moulins d’exhaure), enfin à l’aide de pompes à feu (machines à vapeur) à partir de 1840, bien plus efficaces ! Malheureusement, l’eau pompée était impropre à la consommation et pouvait donc difficilement être réutilisée, hormis pour arroser les cultures.",

		//Anecdotes
		step4Anecdote1Title: "Une plongée dans un vieux-fond, c’est possible !",
		step4Anecdote1Text:
			"Aussi étonnant que cela puisse paraître, certains vieux-fonds s’admirent sur terre… mais aussi sous l’eau ! Les pompiers et la sécurité civile s’entraînent à la plongée sous-marine dans le vieux-fond de l’Aubinière; alors à vos masques et tubas et plongez littéralement dans l’histoire !",
		step4audio: "À l'eau !",

		// Step 5 - Le Moulin du Poirier -

		//Title
		step5Title: "Le Moulin du Poirier, un vestige rare plein de mystères !",
		//Hints
		step5Hint:
			"Pour trouver le moulin c’est très simple, il suffit d’emprunter la rue qui porte son nom. En route, mauvaise troupe !",
		step5HintClose1: "Vous apercevez un petit édifice circulaire ? Vous y êtes !",

		//Subtitles
		step5Subtitle1: "On ne peut pas être à la mine et au moulin !",
		step5Subtitle2: "Son but ? Pomper l’eau !",
		step5Subtitle3: "Sa localisation ? Un marécage !",
		step5Subtitle4: "Mine d’ardoise et ferme agricole, même combat !",
		step5Subtitle5: "Audio associé : Et le moulin reprend vie !",

		//Texts
		step5Text1:
			"Trélazé n’est pas vraiment réputée pour être une terre d’agriculture, son truc, ce sont les ardoisières. Et pourtant, on trouve sur son territoire un moulin d’exhaure parfaitement conservé, le seul de la région. Menons l’enquête pour en comprendre son origine !",
		step5Text2:
			"Comme tous les moulins d’exhaure, ce moulin à la toiture d’ardoise, a pour fonction de pomper et d’extraire l’eau. Au centre du petit édifice, une pompe est actionnée par les rondes d’un cheval et a un débit jusqu’à 900 litres par heure.",
		step5Text3: "Cliquez ici pour entendre le hennissement du cheval.",
		step5Text4:
			"Voilà pour les caractéristiques techniques. Mais cela ne nous dit pas encore à quoi il servait. Regardons du côté de sa localisation.",
		step5Text5:
			"Il est installé dans une zone marécageuse. Il y a donc bien de l’eau, certes, mais à quoi pouvait servir toute cette eau pompée ? À l’exploitation ardoisière ? À l’agriculture ? Aux Trélazéens ? Allez, on vous donne la réponse !",
		step5Text6:
			"Et oui ! Cette pompe à manège appartient d‘abord à la carrière de l’Hermitage. Puis, de 1885 à 1911, elle sert au Vissoir, où se trouve l’une des fermes les plus prospères de Trélazé. Mais sa fonction reste encore floue : peut-être pour l’arrosage des jardins environnants, peut-être au drainage de ce quartier marécageux. Des documents laissent même à penser qu’elle proviendrait de l’abbaye de Fontevraud.",
		step5Text7: "Quoi qu’il en soit, le mystère demeure épais… ",

		//Anecdotes
		step5audio: "Et le moulin reprend vie !",

		// Step 6 - La Butte du Buisson -

		//Title
		step6Title: "Voir le ciel au fond de la mine, c’est possible même à l’époque contemporaine ! ",

		//Hints
		step6Hint1: "Pour vous rendre à cette étape du parcours, trouvez un panorama exceptionnel sur 3 vieux-fonds",
		step6HintClose1:
			"On vous donne un indice supplémentaire puisque vous êtes tout prêt du but : de ce point de vue, on peut admirer trois chevalements en métal... à vous de jouer !",

		//Subtitles
		step6Subtitle1: "Voir le ciel au fond de la mine, c’est possible même à l’époque contemporaine ! ",
		step6Subtitle2: "Une carrière à ciel ouvert contemporaine",
		step6Subtitle3: "Dans les exploitations souterraines",
		step6Subtitle4: "À vous de trouver !",
		step6Subtitle5: "Écoutez Alain Roger vous expliquer les techniques ardoisières en cliquant ci-dessous.",

		//Texts
		step6Text1:
			"Saviez-vous que les carrières à ciel ouvert ne sont pas que de l’histoire ancienne ? Et oui, à Trélazé, on cherche à extraire le schiste ardoisier coûte que coûte jusque très tardivement et ce avec toutes les techniques possibles. Du haut de la Butte du Buisson, c’est donc bien le monde ardoisier de la fin du XXe siècle que l’on contemple, et ses 8 chevalements. On vous raconte.",
		step6Text2:
			"Vous pouvez apercevoir trois vieux-fonds qui sont d’anciennes exploitations : le Buisson, la Porée, la carrière Villeneuve Napoléon. Cette dernière, creusée à ciel ouvert en 1992, a fait l’objet d’une tentative d’exploitation rapidement abandonnée.",
		step6Text3:
			"Ce panorama nous offre une vue sur deux autres vieux-fonds qui ne sont pas d’anciennes carrières à ciel ouvert mais des descenderies. Et oui, pour éviter les risques d’effondrement et extraire le schiste à plus de 400 m de profondeur, les exploitants creusent des souterrains. Des chevalements en fer, bien plus performants, sont aussi utilisés. Ainsi, c’est toute l’exploitation ardoisière qui entre dans la modernité !",
		step6Text4:
			"Vous savez déjà ce qu’est un chevalement, mais saurez-vous distinguer le chevalement Eiffel du chevalement Monthibert ? Un indice, le premier date du début du XXème siècle et pourrait avoir quelques points communs avec la technique de Eiffel, tandis que le second, datant de 1976, est reconnaissable par sa couleur rouge. Vous les avez trouvés ?",
		//Anecdotes

		// Step 7 - La Pyramide -

		//Title
		step7Title: "Lutter contre les inondations, un enjeu de tous les instants !",

		//Hints
		step7Hint1:
			"La suite de votre chasse aux histoires se poursuit dans Trélazé. Que faut-il trouver ? Une pyramide ! Attention, elle est largement plus petite que celles d’Egypte.",
		step7HintClose1:
			"Vous êtes à deux doigts de vous piquer sur la pointe de la pyramide, vous avez presque atteint votre but !",
		//Subtitles
		step7Subtitle1: "Des digues à la levée en passant par les murets",
		step7Subtitle2: "Juin 1856… la ville est sous l’eau !",
		step7Subtitle3: "Napoléon III appelé à la rescousse !",
		step7Subtitle4: "Audio associé : Sauve qui peut, l’eau monte !",

		//Texts
		step7Header1:
			"La pyramide de Trélazé marque la fin des travaux de la levée de la Loire. Retour sur la relation tumultueuse entre les Trélazéens et le fleuve.",
		step7Text1:
			"De tout temps, les populations ont cherché à se protéger de la Loire et protéger les carrières ardoisières en construisant digues, murets et levées. Ce fleuve est capricieux, la ville est victime de ses violentes crues régulièrement. En 1744, date à laquelle la pyramide est érigée, la grande levée de la Loire allant d’Orléans à Nantes est enfin terminée. Les Trélazéens se pensent à l'abri, et pourtant, ils ne sont pas au bout de leur surprise…",
		step7Text2:
			"Le 6 juin, 3h30 du matin à Trélazé, l’eau pénètre dans les carrières d’ardoise avec un bruit épouvantable. Elle monte de 1 m par heure. Le bourg est alors recouvert d’eau… Les carrières de La Porée, le Buisson, l’Hermitage, les Grands carreaux sont inondées et détruites. La population doit se réfugier sur les buttes formées par les déchets d’ardoise. 2000 personnes se retrouvent sans asile, sans pain.",
		step7Text3: "C’est la catastrophe, il faut appeler à l’aide ! Mais qui ?",
		step7Text4:
			"Le 9 juin, voilà qu’arrive l’empereur à Trélazé ! Il vient constater les dégâts terribles causés par la crue. Ce pourquoi, dix ans plus tard, on donna le nom de Napoléon une levée supplémentaire pour protéger les carrières. Et savez-vous pourquoi elle est si solide ? Car elle est construite par les ardoisiers eux-mêmes bien sûr !",
		//Anecdotes
		step7Anecdote1Title: "Napoléon III aurait pu ne pas venir !",
		step7Anecdote1Text:
			"L’Empereur hésite à venir à Trélazé, car l’année précédente, il fait réprimer durement l'insurrection des ouvriers que l’on surnomme “insurrection de la Marianne“, et il craint un accueil hostile… . Il a eu du flair puisque sa venue ne déclenche pas beaucoup d’enthousiasme parmi les ardoisiers. La presse locale change un peu l’histoire puisqu’elle déclare que les Trélazéens ont accueilli le monarque aux cris de « vive l’Empereur, qu’il soit béni pour le bien qu’il nous apporte ».",

		step7audio: "Sauve qui peut, l'eau monte !",

		// Step 8 - La place des Tellières -

		//Title
		step8Title: "Vivre mieux en pays ardoisier : le modèle de la cité-jardin",

		//Hints
		step8Hint1:
			"Pour trouver les Tellières, cherchez une jolie place avec un étrange édifice en son centre. À vous de jouer !",
		step8HintClose1: "Vous y êtes presque ! Validez votre position au pied du château d’eau.",

		//Subtitles
		step8Subtitle1: "Cité-jardin, kézako ?",
		step8Subtitle2: "De la ferme aux Tellières, il n’y a qu’un pas",
		step8Subtitle3: "Au cœur de la cité",
		step8Subtitle4:
			"Pour vivre heureux, vivons aux Tellières ! Écoutez le dialogue entre deux habitants de cette cité-jardin en cliquant ci-dessous.",

		//Texts
		step8Header1:
			"Trélazé est une ville industrielle depuis des siècles. De ce fait, il faut bien les loger tous ces ouvriers ! On construit d’abord des hameaux comme les Favereaux (étape 3 de la chasse aux histoires), puis la modernité débarque en ville. Le nouveau modèle des cités-jardins est là.",
		step8Text1:
			"Le concept de cité-jardin naît à la fin du XIXe siècle en plein boom de la Révolution industrielle. Les usines poussent comme des champignons, les ouvriers et ouvrières sont de plus en plus nombreux et vivent dans des conditions très précaires. C’est pourquoi des quartiers entiers dédiés aux familles ouvrières sont construits, faits de maisons individuelles agréables et salubres, avec des lieux communs comme les lavoirs. Entre ville et campagne, les Tellières est une cité-jardin modèle !",
		step8Text2:
			"La cité ouvrière des Tellières est construite sur une ancienne ferme, en bordure de la ville mais proche des exploitations ardoisières et la seconde manufacture d’allumettes surnommée la “Manu” (étape 9 de la chasse aux histoires). Inauguré en 1920, ce quartier de 150 logements est financé par la Commission des ardoisières d’Angers, mais c’est vraiment grâce aux luttes syndicales acharnées qu’il existe !",
		step8Text3:
			"Si on l’appelle cité, c’est parce qu’il y a des espaces communs à toutes les maisons. Ici, vous êtes au milieu de la place des Tellières. Autrefois, c’était bien animé ! Et oui, si vous avez vu le filtre avant-après, vous avez remarqué qu’un lavoir existait à l’époque ! Aujourd’hui disparu, on imagine bien les femmes se retrouvant pour laver le linge. Il y a également un château d’eau dont la toiture est recouverte d’ardoise, mais celui-ci, vous pouvez encore le voir ! Il y avait également des halles aujourd’hui disparues.",

		//Anecdotes

		// Step 9 - La seconde manufacture d’allumettes", -

		//Title
		step9Title: "Trélazé au temps de l’industrie, symbole de modernité",

		//Hints
		step9Hint1:
			"C’est un lotissement tout neuf aujourd’hui mais qu’il a gardé l’architecture industrielle de l’époque. À vous de jouer !",
		step9HintClose1: "Si votre cœur s’empare de l’esprit de la Manu, c’est que vous y êtes !",

		//Subtitles
		step9Subtitle1: "La Manu à l’avant-garde",
		step9Subtitle2: "Des critères d’entrée très select",
		step9Subtitle3: "La Manu, une révolution sanitaire",
		step9Subtitle4: "La Manu, un patrimoine industriel encore bien vivant",
		step9Subtitle5: "Audio associé : Pour vivre heureux, vivons aux Tellières !",

		//Texts
		step9Header1:
			"Manu, ce n’est pas le surnom d’un Trélazéen mais bien celui d’une grande usine d’allumettes ! Construite en 1920 rue Jean Jaurès, tout près de la cité des Tellières (étape 8 de la chasse aux histoires), elle est l’une des plus modernes de France. Et, comme pour la première fabrique d’allumettes (étape 1 de la chasse aux histoires), son histoire est étroitement liée à celle des femmes. On vous raconte.",
		step9Text1:
			"À son ouverture, la Manu de Trélazé tourne à plein régime ! Entre 11 et 12 milliards d’allumettes par an sortent de l’usine. Pour atteindre ces chiffres, il faut des équipements très performants. À l’extérieur, elle possède son propre chemin de fer qui amène des peupliers entiers, tandis qu’à l’intérieur tapis mécaniques et autres séchoirs à vapeurs actionnent la Manu. Mais n’oublions pas les ouvriers et ouvrières, ce sont eux les petites mains magiques de l’usine !",
		step9Text2:
			"Dans les années 1950, parmi les 500 employés, ⅔ des ouvriers sont des femmes. C’est une tradition dans le monde des allumettes ! Elles sont principalement dans les ateliers de confection et de remplissage des boîtes. Mais le plus étonnant dans cette usine, c’est probablement le CV qu’il faut avoir pour y entrer : être veuve de guerre, orphelin, ou avoir un membre de sa famille déjà salarié précédemment dans l’établissement. Et tous espéraient avoir un de ses critères car le salaire à la clé était très alléchant pour l’époque. La Manu avait donc encore un autre surnom : “la mine d’or”.",
		step9Text3:
			"Ce qui fait de l’usine une manufacture à l’avant-garde, c’est aussi toutes les installations dédiées aux employés. On y trouve une salle d’allaitement, un centre médical car les ouvriers étaient atteints de troubles à la mâchoire liés à l'inhalation de phosphore, mais aussi des douches ! Pour ces dernières, ce n’est pas un luxe vu le caractère dangereux et polluant de certains produits utilisés par les employés…",
		step9Text4:
			"Malgré cela, elle ferme ses portes en 1981. Il faut attendre 2018 pour assister à une reconversion du site, avec l’implantation d’un supermarché, et la transformation des anciens ateliers en logements. Les bâtiments sont aujourd’hui classés par la DRAC, comme patrimoine industriel du XXe siècle.\nEt la Manu n’a pas fini de se reconvertir ! La mise en chantier de nouveaux logements, une ouverture vers la route des Ponts de Cé, ainsi que la mise en valeur de l’ancien château d’eau sont au programme. Une construction artistique est en cours de réalisation : “la doublure”. Il s’agit d’une nouvelle cheminée conçue par le plasticien Raphaël Zarka.",

		//Anecdotes
		step9Anecdote1Title: "Comment reconnaître un allumettier ? ",
		step9Anecdote1Text:
			"Les allumettiers et allumettières manipulent toute la journée des produits chimiques, à commencer par le phosphore…. et ça se voit ! Et oui, on reconnait un allumettier à ses dents ou son urine… phosphorescente !",

		step9audio: "Au coeur de la Manu, les machines grondent !",

		// Step 10 - La Maraîchère -

		//Title
		step10Title: "Se réunir pour lutter !",

		//Hints
		step10Hint1:
			"Suivez les rires des enfants pour vous rendre là où les slogans des syndicalistes retentissaient !",
		step10HintClose1:
			"Si vous cherchez un endroit qui représente tous les Trélazéens , il faut se rendre à la Maraîchère ! Vous y êtes ?",
		//Subtitles
		step10Subtitle1: "Une salle, mille vies",
		step10Subtitle2: "À la maraîchère, on lutte pour le progrès social !",
		step10Subtitle3: "Ils sont venus débattre à la Maraîchère",
		step10Subtitle4: "Audio associé : Chantons la lutte !",
		step10Subtitle5: "Se réunir pour lutter !",

		step10Subtitle6: "Contenu bonus : Alain Pantais nous présente les pseudonymes ardoisiers !",

		//Texts
		step10Header1:
			"Ah la Maraîchère, si vous saviez comme elle compte pour les Trélazéens ! Si vous vous approchez de ce cœur battant de la ville, peut-être pourrez-vous encore entendre les cris des syndicalistes.",
		step10Text1:
			"Aujourd’hui école élémentaire publique, la salle de La Maraîchère, construite en 1867 par l’architecte Dainville, a eu mille et une fonctions : salle de classe (avec une bibliothèque), de sport, de spectacles, de bals, bureaux de votes, mais surtout lieu de rassemblements syndicaux pendant les grèves des ardoisiers. C’est de cela dont nous voulions vous parler !",
		step10Text2:
			"En plus d’être une école élémentaire, elle permet, dès son ouverture, de participer à l’alphabétisation des populations ouvrières de Trélazé ce qui, en soi, est déjà une avancée sociale sans précédent ! Dans la même salle, les ouvriers se retrouvent et luttent pour leurs droits. Des centaines d’hommes peuvent se réunir en même temps pour organiser la grève, pour vous dire son importance ! Et bien des personnages illustres sont venus y prononcer un discours.",
		step10Text3:
			"Le 10 août 1884, on invite Jean-Baptiste Clément, l’auteur du Temps des cerises et premier maire de la Commune libre de Paris, pour débattre sur « le droit syndical ». En 1896, c’est au tour du célèbre Sébastien Faure, propagandiste de l’idéal libertaire, reconnu pour sa pédagogie et ses qualités d’orateur, de venir évoquer “ la question sociale”. Et puis il est impossible d’oublier le passage de Louise Michel le 8 mars 1903, cette célèbre “passionaria” de la Commune de Paris qui vient animer une conférence sur les thèmes suivant : « pourquoi des églises, pourquoi des casernes » !",
		step10Text4:
			"Evidemment, le personnage le plus célèbre à l’échelle locale, c’est l’ancien leader syndicaliste ardoisier, Ludovic Ménard ! Très important pour les Trélazéens et excellent orateur, il est le porte-parole des ardoisiers et très écouté de ses camarades.",
		step10Text5: "Cliquez pour en savoir plus sur cette figure de Trélazé.",
		step10Text6:
			"Si vous regardez autour de vous, vous devriez apercevoir une grande stèle avec un personnage gravé. Vous la voyez ? C’est Ludovic Ménard !",
		step10Text7:
			"Charles de son vrai nom, il est le fils d’un journalier d’usine et vit dans une extrême précarité. Pour aider sa famille, il devient fendeur aux ardoisières de Trélazé dès ses 11 ans. Mais sa soif de s’instruire est insatiable, il va donc suivre des cours pour adultes ! Pas à la Maraîchère certes, mais tout de même. Ses lectures lui inspirent une grande soif d’égalité, de justice et de liberté, en même temps que le désir d’un monde moins précaire pour ses compagnons de travail .",
		step10Text8:
			"Vers 1890, il se consacre tout entier au syndicalisme révolutionnaire. Toute sa vie, Ménard sera le chef incontesté des « perreyeux ». De la fondation de la Chambre syndicale des ouvriers ardoisiers de Trélazé à la Fédération nationale regroupant tous les syndicats ardoisiers de France, son engagement pour le droit ouvrier est incontestable et incontesté.",
		step10Text9:
			"L’épitaphe sur sa stèle n’a donc rien d’étonnant : « Sa vie, au service des travailleurs, fut un combat permanent pour la justice et la paix ».",

		step10Text10:
			"Si vous voulez faire partie de la communauté des perreyeux de Trélazé, il vous faut un surnom ! ",
		step10Text11:
			"Mais pourquoi cette tradition ? Sans doute parce qu’il fut un temps où il fallait être le fils d’un fendeur d’ardoise pour pratiquer ce métier. En général, ce sont les anciens qui choisissaient votre petit nom, et ce dernier vous suivait toute votre vie, jusque dans les avis de décès.",
		step10Text12:
			"En début de carrière, vous êtes un “chérubin”, un apprenti en somme. Une fois adopté par les perreyeux, ils ne manqueront pas d’imagination pour vous baptiser !",
		step10Text13:
			"Voilà quelques exemples plein d’ironies : L’un était surnommé « la gaîté » parce qu’il ne riait jamais, l’autre « la rafale »… et oui il travaillait très lentement. Je pense aussi au  « directeur », un perreyeux plutôt fainéant, donnant des ordres à tout le monde… même à son chef.",
		step10Text14:
			"On pouvait jouer aussi sur le nom de famille de l’ardoisier : Derrien était surnommé rien du tout, Leroy c’était Louis XIV, tandis que Le Boulanger on l’appelait la miche.",
		step10Text15:
			"Je terminerai cette heureuse liste avec les surnoms de ma propre famille : Mon grand-père maternel était surnommé « Pirote » qui vient de l’expression « il souffle comme un jars et une pirote et ses petits », pirote étant le nom de l’oie femelle. Mon oncle Marcel était surnommé « Bugeaud » parce qu’il portait une casquette, comme celle que portait le général Bugeaud dans la célèbre chanson « as-tu vu la casquette… », ou encore mon oncle Henri avait été surnommé « le zoulou » car il était petit, au teint mat, très résistant… il est mort à 108 ans ! ",
		step10Text16: "Et vous, vous auriez aimé avoir quel surnom ?",

		//Anecdotes

		// Ranks
		levelCherubin: "Chérubin",
		levelApprentice: "Apprenti-ardoisier",

		// Items names
		itemStep1_matchbox: "Boite d'allumettes",
		itemStep2_shoes: "Les Sabots",
		itemStep3_hammer: "Le Maillet",
		itemStep4_helmet: "Le Casque",
		itemStep5_bucket: "Le Seau",
		itemStep6_slate: "l'Ardoise",
		itemStep7_newspaper: "Le Journal",
		itemStep8_biniou: "Le Biniou",
		itemStep9_coat: "La Blouse",
		itemStep10_flag: "Le Drapeau",

		// Go
		targetIsAt: "La cible est à",
		canYouSeeIt:"Vous la voyez ?",
	},
];
