// Redux
import { createAction } from "@reduxjs/toolkit";
// Types
import { GAME_POIS, GAME_TREASURES, GAME_SETDISCOVEREDPOI, GAME_SETDISCOVEREDOBJ, GAME_SETQUIZ, GAME_SETSHOWIMAGE, GAME_SETFINALRANK } from "./types";

export const setPoisProgress = createAction<any>(GAME_POIS);
export const setTreasuresProgress = createAction<any>(GAME_TREASURES);
export const setDiscoveredPoi = createAction<string>(GAME_SETDISCOVEREDPOI);
export const setDiscoveredObj = createAction<string>(GAME_SETDISCOVEREDOBJ);
export const setQuiz = createAction<any>(GAME_SETQUIZ);
export const setShowImage = createAction<string>(GAME_SETSHOWIMAGE);
export const setFinalRank = createAction<string>(GAME_SETFINALRANK);