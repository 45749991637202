// Global Map
import dotImg from "../style/assets/icons/dot.svg";
import dotImgFind from "../style/assets/icons/dotFind.svg";
import borneImg from "../style/assets/icons/borne.png";

// STEP 1 : Première Manufacture d'Allumettes
import imgPoiPremiereManuAllu from "../style/assets/pois/premiereManuAllu/Etape1_PremiereManuAllu.jpg";
import imgCardPremiereManuAllu from "../style/assets/pois/premiereManuAllu/Etape1_PremiereManuAllu.jpg";
import imgSliderPremiereManuAlluBefore from "../style/assets/pois/premiereManuAllu/Etape1_PremiereManuAllu.jpg";
import imgSliderPremiereManuAlluAfter from "../style/assets/pois/premiereManuAllu/Etape1_PremiereManufacture_After_Filtre.jpg";
import itemStep1_empty from "../style/assets/inventary/itemLvl1_Empty.png";
import itemStep1 from "../style/assets/inventary/itemLvl1.png";
import imgPremierAllu1 from "../style/assets/pois/premiereManuAllu/step1_poi_image.jpg";

// STEP 2 : Butte de l'Enfer
import imgPoiButteEnfer from "../style/assets/pois/butteEnfer/Etape2_ButteduBuisson_ChevalementPuits1ChampRobert.jpg";
import imgCardButteEnfer from "../style/assets/pois/butteEnfer/Etape2_ButteduBuisson_ChevalementPuits1ChampRobert.jpg";
import imgSliderButteEnferBefore from "../style/assets/pois/butteEnfer/Etape2_ButteduBuisson_ChevalementPuits1ChampRobert.jpg";
import imgSliderButteEnferAfter from "../style/assets/pois/butteEnfer/Etape2_ButteduBuisson_After_Filtre.jpg";
import itemStep2_empty from "../style/assets/inventary/itemLvl2_Empty.png";
import itemStep2 from "../style/assets/inventary/itemLvl2.png";
import imgButteEnfer1 from "../style/assets/pois/butteEnfer/step2_poi_image1.jpg";


// STEP 3 : Les Favereaux
import imgPoiFavereaux from "../style/assets/pois/favereaux/Etape3_Favereaux.jpg";
import imgCardFavereaux from "../style/assets/pois/favereaux/Etape3_Favereaux.jpg";
import imgSliderFavereauxBefore from "../style/assets/pois/favereaux/Etape3_Favereaux.jpg";
import imgSliderFavereauxAfter from "../style/assets/pois/favereaux/Etape3_Favereaux_After_Filtre.jpg";
import itemStep3_empty from "../style/assets/inventary/itemLvl3_Empty.png";
import itemStep3 from "../style/assets/inventary/itemLvl3.png";

// STEP 4 : Les Vieux-Fonds
import imgPoiVieuxfonds from "../style/assets/pois/vieuxfonds/Etape4_Bremandiere_Carrierecielouvert.jpg";
import imgCardVieuxfonds from "../style/assets/pois/vieuxfonds/Etape4_Bremandiere_Carrierecielouvert.jpg";
import imgSliderVieuxfondsBefore from "../style/assets/pois/vieuxfonds/Etape4_Bremandiere_Carrierecielouvert.jpg";
import imgSliderVieuxfondsAfter from "../style/assets/pois/vieuxfonds/Etape4_Petits_carreaux_Bremandiere_After_Filtre.jpg";
import itemStep4_empty from "../style/assets/inventary/itemLvl4_Empty.png";
import itemStep4 from "../style/assets/inventary/itemLvl4.png";
import imgVieuxFonds1 from "../style/assets/pois/vieuxfonds/step4_poi_image1.jpg";

// STEP 5 : Le Moulin
import imgPoiMoulin from "../style/assets/pois/moulin/Etape5_Moulin_After_Filtre.jpg";
import imgCardMoulin from "../style/assets/pois/moulin/Etape5_Moulin_After_Filtre.jpg";
import imgSliderMoulinBefore from "../style/assets/pois/moulin/Etape5_Moulin_Interior2.jpg";
import imgSliderMoulinAfter from "../style/assets/pois/moulin/Etape5_Moulin_After_Filtre.jpg";
import itemStep5_empty from "../style/assets/inventary/itemLvl5_Empty.png";
import itemStep5 from "../style/assets/inventary/itemLvl5.png";

// STEP 6 : La Butte du Buisson
import imgPoiButteBuisson from "../style/assets/pois/butteBuisson/Etape6_Buisson_fond_Monthibert.jpg";
import imgCardButteBuisson from "../style/assets/pois/butteBuisson/Etape6_Buisson_fond_Monthibert.jpg";
import imgSliderButteBuissonBefore from "../style/assets/pois/butteBuisson/Etape6_Buisson_fond_Monthibert.jpg";
import imgSliderButteBuissonAfter from "../style/assets/pois/butteBuisson/Etape6_Buisson_surface_After_Filtre.jpg";
import itemStep6_empty from "../style/assets/inventary/itemLvl6_Empty.png";
import itemStep6 from "../style/assets/inventary/itemLvl6.png";
import imgButteBuisson1 from "../style/assets/pois/butteBuisson/step6_poi_image1.jpg";

// STEP 7 : La Pyramide
import imgPoiPyramide from "../style/assets/pois/pyramide/Etape7_LaPyramide.jpg";
import imgCardPyramide from "../style/assets/pois/pyramide/Etape7_LaPyramide.jpg";
import imgSliderPyramideBefore from "../style/assets/pois/pyramide/Etape7_LaPyramide.jpg";
import imgSliderPyramideAfter from "../style/assets/pois/pyramide/Etape7_LaPyramide_After_Filtre.jpg";
import itemStep7_empty from "../style/assets/inventary/itemLvl7_Empty.png";
import itemStep7 from "../style/assets/inventary/itemLvl7.png";
import pyramidWaveBlue from "../style/assets/pois/pyramide/PyramidWaveBlue.svg";
import pyramidWaveLightBlue from "../style/assets/pois/pyramide/PyramidWaveLightBlue.svg";

// STEP 8 : La Place des Tellières
import imgPoiPlaceTellieres from "../style/assets/pois/placeTellieres/Etape8_LesTellieres.jpg";
import imgCardPlaceTellieres from "../style/assets/pois/placeTellieres/Etape8_LesTellieres.jpg";
import imgSliderPlaceTellieresBefore from "../style/assets/pois/placeTellieres/Etape8_TimescopeTellieres_Before_Filtre.jpg";
import imgSliderPlaceTellieresAfter from "../style/assets/pois/placeTellieres/Etape8_TimescopeTellieres_After_Filtre.jpg";
import itemStep8_empty from "../style/assets/inventary/itemLvl8_Empty.png";
import itemStep8 from "../style/assets/inventary/itemLvl8.png";
import imgPlaceTellieres1 from "../style/assets/pois/placeTellieres/step8_poi_image1.jpg";

// STEP 9 : Seconde Manufacture d’Allumettes
import imgPoiSecondeManuAllu from "../style/assets/pois/secondeManuAllu/Etape9_LaManu.jpg";
import imgCardSecondeManuAllu from "../style/assets/pois/secondeManuAllu/Etape9_LaManu.jpg";
import imgSliderSecondeManuAlluBefore from "../style/assets/pois/secondeManuAllu/Etape9_LaManu.jpg";
import imgSliderSecondeManuAlluAfter from "../style/assets/pois/secondeManuAllu/Etape9_LaManu_After_Filtre.jpg";
import itemStep9_empty from "../style/assets/inventary/itemLvl9_Empty.png";
import itemStep9 from "../style/assets/inventary/itemLvl9.png";
import imgSecondManuAllu1 from "../style/assets/pois/secondeManuAllu/step9_poi_image1.jpg";

// STEP 10 : La Maraîchère
import imgPoiMaraichere from "../style/assets/pois/maraichere/Etape10_LaMaraichere1.jpg";
import imgCardMaraichere from "../style/assets/pois/maraichere/Etape10_LaMaraichere1.jpg";
import imgSliderMaraichereBefore from "../style/assets/pois/maraichere/Etape10_LaMaraichere_Before_Filtre_1.jpg";
import imgSliderMaraichereAfter from "../style/assets/pois/maraichere/Etape10_Maraichere_After_Filtre.jpg";
import itemStep10_empty from "../style/assets/inventary/itemLvl10_Empty.png";
import itemStep10 from "../style/assets/inventary/itemLvl10.png";
import ludovicMenard from "../style/assets/pois/maraichere/ludovicMenard.jpg";
import imgMaraichere1 from "../style/assets/pois/maraichere/step10_poi_image1.jpg";

// DEV
import placeHolder from "../style/assets/dev/placeHolder.jpg";

import audioAmbianceButteEnfer from "../style/assets/pois/audio/Etape2CHEVALEMENT.mp3";
import audioAmbianceVieuxfonds from "../style/assets/pois/audio/Etape-4_VIEUX-FONDS_DEF.mp3"
import audioAmbianceMoulin from "../style/assets/pois/audio/Etape-5_MOULIN_DEF.mp3"
import audioAmbiancePyramide from "../style/assets/pois/audio/Etape-7_Pyramide_DEF.mp3"
import audioAmbianceSecondeManu from "../style/assets/pois/audio/Etape-9_MANUFACTURE_DEF.mp3"
import audioAmbianceMaraichere from "../style/assets/pois/audio/Etape-10_MARAÎCHÈRE_DEF.mp3"

const audioTestimonyPremiereManuAllu = "assets/pois/premiereManuAllu/testimonyPremiereManuAllu.mp3";
const audioTestimonyFavereaux = "assets/pois/favereaux/testimonyFavereaux.mp3";
//const audioAmbianceVieuxfonds = "assets/pois/vieuxfonds/ambianceVieuxfonds.mp3";
//const audioAmbianceMoulin = "assets/pois/moulin/ambianceMoulin.mp3";
const audioTestimonyButteBuisson = "assets/pois/butteBuisson/testimonyButteBuisson.mp3";
//const audioAmbiancePyramide = "assets/pois/pyramide/ambiancePyramide.mp3";
const audioTestimonyPlaceTellieres = "assets/pois/placeTellieres/testimonyPlaceTellieres.mp3";
//const audioAmbianceSecondeManu = "assets/pois/secondeManuAllu/ambianceSecondeManuAllu.mp3";
//const audioAmbianceMaraichere = "assets/pois/maraichere/ambianceMaraichere.mp3";

export const geojson = {
	type: "FeatureCollection",
	features: [
		{
			type: "Feature",
			properties: {
				id: "premiereManuAllu",
				step: 1,
			},
			card: {
				name: "La première fabrique d’allumettes",
				img: `url(${imgCardPremiereManuAllu})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			unlockScreen: {
				name: "La première fabrique d’allumettes",
				img: `url(${imgPoiPremiereManuAllu})`,
				backgroundSize: "contain",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			geometry: {
				type: "Point",
				coordinates: [-0.48930556, 47.44625],
				iconSize: [24, 24],
				icon: `url(${dotImg})`,
				iconFind: `url(${dotImgFind})`,
			},
			compass: [
				{
					type: "hint",
					content: "step1Hint1",
				},
				{
					type: "hintClose",
					content: "step1HintClose1",
				},
			],
			pagePoi: {
				title: `inventoryCardStep1`,
				button: {
					listen: "buttonListen",
					share: "buttonShare",
				},
				ambientSound: null,
				text: [
					{
						type: "subtitle",
						content: "step1Title",
					},
					{
						type: "text",
						content: "step1Text1",
					},
					{
						type: "imgSliderH",
						content: {
							imageBefore: `url(${imgSliderPremiereManuAlluBefore})`,
							imageAfter: `url(${imgSliderPremiereManuAlluAfter})`,
						},
					},
					{
						type: "subtitle",
						content: "step1Subtitle1",
					},
					{
						type: "text",
						content: "step1Text2",
					},
					{
						type: "subtitle",
						content: "step1Subtitle2",
					},
					{
						type: "text",
						content: "step1Text3",
					},
					{
						type: "image",
						content: `url(${imgPremierAllu1})`,
					},
					{
						type: "subtitle",
						content: "step1Subtitle3",
					},
					{
						type: "text",
						content: "step1Text5",
					},
					{
						type: "text",
						content: "step1Text6",
					},
					{
						type: "subtitle",
						content: "step1Subtitle4",
					},
					{
						type: "text",
						content: "step1Text7",
					},
					{
						type: "text",
						content: "step1Text8",
					},
					{
						type: "subtitle",
						content: "step1Text9",
					},
					{
						type: "audio",
						content: audioTestimonyPremiereManuAllu,
					},
					{
						type: "anecdote",
						content: {
							title: "step1Anecdote1Title",
							text: "step1Anecdote1Text",
						},
					},
				],
			},
			treasure: {
				name: "itemStep1_matchbox",
				imgEmpty: `url(${itemStep1_empty})`,
				img: `url(${itemStep1})`,
			},
		},
		{
			type: "Feature",
			properties: {
				id: "butteEnfer",
				step: 2,
			},
			card: {
				name: "La Butte de l’Enfer",
				img: `url(${imgCardButteEnfer})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			unlockScreen: {
				name: "La Butte de l’Enfer",
				img: `url(${imgPoiButteEnfer})`,
				backgroundSize: "contain",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			geometry: {
				type: "Point",
				coordinates: [-0.49294444, 47.4535],
				iconSize: [24, 24],
				icon: `url(${dotImg})`,
				iconFind: `url(${dotImgFind})`,
			},
			compass: [
				{
					type: "hint",
					content: "step2Hint1",
				},
				{
					type: "hintClose",
					content: "step2Hint1Close",
				},
			],
			pagePoi: {
				title: "step2Title",
				button: {
					share: "buttonShare",
				},
				ambientSound: {
					type: "audio",
					content: audioAmbianceButteEnfer,
					name: "step2Audio",
				},
				text: [
					{
						type: "subtitle",
						content: "step2Subtitle1",
					},
					{
						type: "text",
						content: "step2Text1",
					},
					{
						type: "imgSliderH",
						content: {
							imageBefore: `url(${imgSliderButteEnferBefore})`,
							imageAfter: `url(${imgSliderButteEnferAfter})`,
						},
					},
					{
						type: "subtitle",
						content: "step2Subtitle2",
					},
					{
						type: "text",
						content: "step2Text2",
					},
					{
						type: "image",
						content: `url(${imgButteEnfer1})`,
					},
					{
						type: "subtitle",
						content: "step2Subtitle3",
					},
					{
						type: "text",
						content: "step2Text3",
					},
					{
						type: "anecdote",
						content: {
							title: "step2Anecdote1Title",
							text: "step2Anecdote1Text",
						},
					},
					{
						type: "anecdote",
						content: {
							title: "step2Anecdote2Title",
							text: "step2Anecdote2Text",
						},
					},
				],
			},
			treasure: {
				name: "itemStep2_shoes",
				imgEmpty: `url(${itemStep2_empty})`,
				img: `url(${itemStep2})`,
			},
		},
		{
			type: "Feature",
			properties: {
				id: "favereaux",
				step: 3,
			},
			card: {
				name: "Les Favereaux",
				img: `url(${imgCardFavereaux})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			unlockScreen: {
				name: "Les Favereaux",
				img: `url(${imgPoiFavereaux})`,
				backgroundSize: "auto 150%",
				backgroundPosition: "center 70%",
				width: 809,
				height: 518,
			},
			geometry: {
				type: "Point",
				coordinates: [-0.49630556, 47.45555556],
				iconSize: [24, 24],
				icon: `url(${dotImg})`,
				iconFind: `url(${dotImgFind})`,
			},
			compass: [
				{
					type: "hint",
					content: "step3Hint1",
				},
				{
					type: "hintClose",
					content: "step3HintClose1",
				},
			],
			pagePoi: {
				title: "inventoryCardStep3",
				button: { listen: "buttonListen", share: "buttonShare" },
				ambientSound: null,
				text: [
					{
						type: "subtitle",
						content: "step3Title",
					},
					{
						type: "text",
						content: "step3Text1",
					},
					{
						type: "imgSliderH",
						content: {
							imageBefore: `url(${imgSliderFavereauxBefore})`,
							imageBeforeSize: `auto 160%`,
							imageBeforePos: `left 60%`,
							imageAfter: `url(${imgSliderFavereauxAfter})`,
						},
					},
					{
						type: "subtitle",
						content: "step3Subtitle1",
					},
					{
						type: "text",
						content: "step3Text2",
					},
					{
						type: "subtitle",
						content: "step3Subtitle2",
					},
					{
						type: "text",
						content: "step3Text3",
					},
					{
						type: "subtitle",
						content: "step3Text4",
					},
					{
						type: "audio",
						content: audioTestimonyFavereaux,
					},
					{
						type: "anecdote",
						content: {
							title: "step3Anecdote1Title",
							text: "step3Anecdote1Text",
						},
					},
					{
						type: "anecdote",
						content: {
							title: "step3Anecdote2Title",
							text: "step3Anecdote2Text",
						},
					},
				],
			},
			treasure: {
				name: "itemStep3_hammer",
				imgEmpty: `url(${itemStep3_empty})`,
				img: `url(${itemStep3})`,
			},
		},
		{
			type: "Feature",
			properties: {
				id: "vieuxfonds",
				step: 4,
			},
			card: {
				name: "Les vieux-fonds (La Brémandière)",
				img: `url(${imgCardVieuxfonds})`,
				backgroundSize: "cover",
				backgroundPosition: "auto 25%",
				width: 809,
				height: 518,
			},
			unlockScreen: {
				name: "Les vieux-fonds (La Brémandière)",
				img: `url(${imgPoiVieuxfonds})`,
				backgroundSize: "contain",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			geometry: {
				type: "Point",
				coordinates: [-0.48916667, 47.45425],
				iconSize: [24, 24],
				icon: `url(${dotImg})`,
				iconFind: `url(${dotImgFind})`,
			},
			compass: [
				{
					type: "hint",
					content: "step4Hint1",
				},
				{
					type: "hintClose",
					content: "step4HintClose1",
				},
			],
			pagePoi: {
				title: "inventoryCardStep4",
				button: { share: "buttonShare" },
				ambientSound: {
					type: "audio",
					content: audioAmbianceVieuxfonds,
					name: "step4audio",
				},
				text: [
					{
						type: "subtitle",
						content: "step4Title",
					},
					{
						type: "text",
						content: "step4Text1",
					},
					{
						type: "imgSliderH",
						content: {
							imageBefore: `url(${imgSliderVieuxfondsBefore})`,
							imageAfter: `url(${imgSliderVieuxfondsAfter})`,
						},
					},
					{
						type: "subtitle",
						content: "step4Subtitle1",
					},
					{
						type: "text",
						content: "step4Text2",
					},
					{
						type: "subtitle",
						content: "step4Subtitle2",
					},
					{
						type: "text",
						content: "step4Text3",
					},
					{
						type: "image",
						content: `url(${imgVieuxFonds1})`,
					},
					{
						type: "subtitle",
						content: "step4Subtitle3",
					},
					{
						type: "text",
						content: "step4Text4",
					},
					{
						type: "anecdote",
						content: {
							title: "step4Anecdote1Title",
							text: "step4Anecdote1Text",
						},
					},
				],
			},
			treasure: {
				name: "itemStep4_helmet",
				imgEmpty: `url(${itemStep4_empty})`,
				img: `url(${itemStep4})`,
			},
		},
		{
			type: "Feature",
			properties: {
				id: "moulin",
				step: 5,
			},
			card: {
				name: "Le Moulin du Poirier",
				img: `url(${imgCardMoulin})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			unlockScreen: {
				name: "Le Moulin du Poirier",
				img: `url(${imgPoiMoulin})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			geometry: {
				type: "Point",
				coordinates: [-0.48483333, 47.44611111],
				iconSize: [24, 24],
				icon: `url(${dotImg})`,
				iconFind: `url(${dotImgFind})`,
			},
			compass: [
				{
					type: "hint",
					content: "step5Hint",
				},
				{
					type: "hintClose",
					content: "step5HintClose1",
				},
			],
			pagePoi: {
				title: "step5Title",
				button: { share: "buttonShare" },
				ambientSound: {
					type: "audio",
					content: audioAmbianceMoulin,
					name: "step5audio",
				},
				text: [
					{
						type: "subtitle",
						content: "step5Title",
					},
					{
						type: "text",
						content: "step5Subtitle1",
					},
					{
						type: "text",
						content: "step5Text1",
					},
					{
						type: "imgSliderH",
						content: {
							imageBefore: `url(${imgSliderMoulinBefore})`,
							imageAfter: `url(${imgSliderMoulinAfter})`,
						},
					},
					{
						type: "subtitle",
						content: "step5Subtitle2",
					},
					{
						type: "text",
						content: "step5Text2",
					},
					{
						type: "text",
						content: "step5Text4",
					},
					{
						type: "subtitle",
						content: "step5Subtitle3",
					},
					{
						type: "text",
						content: "step5Text5",
					},
					{
						type: "subtitle",
						content: "step5Subtitle4",
					},
					{
						type: "text",
						content: "step5Text6",
					},
					{
						type: "text",
						content: "step5Text7",
					},
				],
			},
			treasure: {
				name: "itemStep5_bucket",
				imgEmpty: `url(${itemStep5_empty})`,
				img: `url(${itemStep5})`,
			},
		},
		{
			type: "Feature",
			properties: {
				id: "butteBuisson",
				step: 6,
			},
			card: {
				name: "La Butte du Buisson",
				img: `url(${imgCardButteBuisson})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			unlockScreen: {
				name: "La Butte du Buisson",
				img: `url(${imgPoiButteBuisson})`,
				backgroundSize: "contain",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			geometry: {
				type: "Point",
				coordinates: [-0.479278, 47.441441],
				iconSize: [24, 24],
				icon: `url(${dotImg})`,
				iconFind: `url(${dotImgFind})`,
			},
			compass: [
				{
					type: "hint",
					content: "step6Hint1",
				},
				{
					type: "hintClose",
					content: "step6HintClose1",
				},
			],
			pagePoi: {
				title: "inventoryCardStep6",
				button: { listen: "buttonListen", share: "buttonShare" },
				ambientSound: null,
				text: [
					{
						type: "subtitle",
						content: "step6Title",
					},
					{
						type: "text",
						content: "step6Text1",
					},
					{
						type: "imgSliderV",
						content: {
							imageBefore: `url(${imgSliderButteBuissonBefore})`,
							imageAfter: `url(${imgSliderButteBuissonAfter})`,
						},
					},
					{
						type: "subtitle",
						content: "step6Subtitle2",
					},
					{
						type: "text",
						content: "step6Text2",
					},
					{
						type: "image",
						content: `url(${imgButteBuisson1})`,
					},
					{
						type: "subtitle",
						content: "step6Subtitle3",
					},
					{
						type: "text",
						content: "step6Text3",
					},
					{
						type: "subtitle",
						content: "step6Subtitle5",
					},
					{
						type: "audio",
						content: audioTestimonyButteBuisson,
					},
					{
						type: "anecdote",
						content: {
							title: "step6Subtitle4",
							text: "step6Text4",
						},
					},
				],
			},
			treasure: {
				name: "itemStep6_slate",
				imgEmpty: `url(${itemStep6_empty})`,
				img: `url(${itemStep6})`,
			},
		},
		{
			type: "Feature",
			properties: {
				id: "pyramide",
				step: 7,
			},
			card: {
				name: "La Pyramide",
				img: `url(${imgCardPyramide})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: 800,
				height: 518,
			},
			unlockScreen: {
				name: "La Pyramide",
				img: `url(${imgPoiPyramide})`,
				backgroundSize: "contain",
				backgroundPosition: "center",
				width: 800,
				height: 518,
			},
			geometry: {
				type: "Point",
				coordinates: [-0.48911111, 47.44055556],
				iconSize: [24, 24],
				icon: `url(${dotImg})`,
				iconFind: `url(${dotImgFind})`,
			},
			compass: [
				{
					type: "hint",
					content: "step7Hint1",
				},
				{
					type: "hintClose",
					content: "step7HintClose1",
				},
			],
			pagePoi: {
				title: "inventoryCardStep7",
				button: { share: "buttonShare" },
				ambientSound: {
					type: "audio",
					content: audioAmbiancePyramide,
					name: "step7audio",
				},
				text: [
					{
						type: "subtitle",
						content: "step7Title",
					},
					{
						type: "text",
						content: "step7Header1",
					},
					{
						type: "imgSliderWave",
						content: {
							imageBackground: `url(${imgPoiPyramide})`,
							imageTop: `url(${pyramidWaveBlue})`,
							imageDown: `url(${pyramidWaveLightBlue})`,
						},
					},
					{
						type: "subtitle",
						content: "step7Subtitle1",
					},
					{
						type: "text",
						content: "step7Text1",
					},
					{
						type: "subtitle",
						content: "step7Subtitle2",
					},
					{
						type: "text",
						content: "step7Text2",
					},
					{
						type: "text",
						content: "step7Text3",
					},
					{
						type: "subtitle",
						content: "step7Subtitle3",
					},
					{
						type: "text",
						content: "step7Text4",
					},
					{
						type: "anecdote",
						content: {
							title: "step7Anecdote1Title",
							text: "step7Anecdote1Text",
						},
					},
				],
			},
			treasure: {
				name: "itemStep7_newspaper",
				imgEmpty: `url(${itemStep7_empty})`,
				img: `url(${itemStep7})`,
			},
		},
		{
			type: "Feature",
			properties: {
				id: "placeTellieres",
				step: 8,
			},
			card: {
				name: "La place des Tellières",
				img: `url(${imgCardPlaceTellieres})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			unlockScreen: {
				name: "La place des Tellières",
				img: `url(${imgPoiPlaceTellieres})`,
				backgroundSize: "contain",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			geometry: {
				type: "Point",
				coordinates: [-0.49975, 47.44247222],
				iconSize: [24, 24],
				icon: `url(${dotImg})`,
				iconFind: `url(${dotImgFind})`,
			},
			compass: [
				{
					type: "hint",
					content: "step8Hint1",
				},
				{
					type: "hintClose",
					content: "step8HintClose1",
				},
			],
			pagePoi: {
				title: "inventoryCardStep8",
				button: { listen: "buttonListen", share: "buttonShare" },
				ambientSound: null,
				text: [
					{
						type: "subtitle",
						content: "step8Title",
					},
					{
						type: "text",
						content: "step8Header1",
					},
					{
						type: "imgSliderH",
						content: {
							imageBefore: `url(${imgSliderPlaceTellieresBefore})`,
							imageAfter: `url(${imgSliderPlaceTellieresAfter})`,
						},
					},
					{
						type: "subtitle",
						content: "step8Subtitle1",
					},
					{
						type: "text",
						content: "step8Text1",
					},
					{
						type: "subtitle",
						content: "step8Subtitle2",
					},
					{
						type: "text",
						content: "step8Text2",
					},
					{
						type: "image",
						content: `url(${imgPlaceTellieres1})`,
					},
					{
						type: "subtitle",
						content: "step8Subtitle3",
					},
					{
						type: "text",
						content: "step8Text3",
					},
					{
						type: "subtitle",
						content: "step8Subtitle4",
					},
					{
						type: "audio",
						content: audioTestimonyPlaceTellieres,
					},

				],
			},
			treasure: {
				name: "itemStep8_biniou",
				imgEmpty: `url(${itemStep8_empty})`,
				img: `url(${itemStep8})`,
			},
		},
		{
			type: "Feature",
			properties: {
				id: "secondeManuAllu",
				step: 9,
			},
			card: {
				name: "La seconde manufacture d’allumettes",
				img: `url(${imgCardSecondeManuAllu})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			unlockScreen: {
				name: "La seconde manufacture d’allumettes",
				img: `url(${imgPoiSecondeManuAllu})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			geometry: {
				type: "Point",
				coordinates: [-0.50261111, 47.44380556],
				iconSize: [24, 24],
				icon: `url(${dotImg})`,
				iconFind: `url(${dotImgFind})`,
			},
			compass: [
				{
					type: "hint",
					content: "step9Hint1",
				},
				{
					type: "hintClose",
					content: "step9HintClose1",
				},
			],
			pagePoi: {
				title: "inventoryCardStep9",
				button: { share: "buttonShare" },
				ambientSound: {
					type: "audio",
					content: audioAmbianceSecondeManu,
					name: "step9audio",
				},
				text: [
					{
						type: "subtitle",
						content: "step9Title",
					},
					{
						type: "text",
						content: "step9Header1",
					},
					{
						type: "imgSliderH",
						content: {
							imageBefore: `url(${imgSliderSecondeManuAlluBefore})`,
							imageAfter: `url(${imgSliderSecondeManuAlluAfter})`,
						},
					},
					{
						type: "subtitle",
						content: "step9Subtitle1",
					},
					{
						type: "text",
						content: "step9Text1",
					},
					{
						type: "subtitle",
						content: "step9Subtitle2",
					},
					{
						type: "text",
						content: "step9Text2",
					},
					{
						type: "image",
						content: `url(${imgSecondManuAllu1})`,
					},					
					{
						type: "subtitle",
						content: "step9Subtitle3",
					},
					{
						type: "text",
						content: "step9Text3",
					},
					{
						type: "subtitle",
						content: "step9Subtitle4",
					},
					{
						type: "text",
						content: "step9Text4",
					},
					{
						type: "anecdote",
						content: {
							title: "step9Anecdote1Title",
							text: "step9Anecdote1Text",
						},
					},
				],
			},
			treasure: {
				name: "itemStep9_coat",
				imgEmpty: `url(${itemStep9_empty})`,
				img: `url(${itemStep9})`,
			},
		},
		{
			type: "Feature",
			properties: {
				id: "maraichere",
				step: 10,
			},
			card: {
				name: "La Maraîchère",
				img: `url(${imgCardMaraichere})`,
				backgroundSize: "cover",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			unlockScreen: {
				name: "La Maraîchère",
				img: `url(${imgPoiMaraichere})`,
				backgroundSize: "contain",
				backgroundPosition: "center",
				width: 809,
				height: 518,
			},
			geometry: {
				type: "Point",
				coordinates: [-0.49188889, 47.44577778],
				iconSize: [24, 24],
				icon: `url(${dotImg})`,
				iconFind: `url(${dotImgFind})`,
			},
			compass: [
				{
					type: "hint",
					content: "step10Hint1",
				},
				{
					type: "hintClose",
					content: "step10HintClose1",
				},
			],
			pagePoi: {
				title: "inventoryCardStep10",
				button: { share: "buttonShare" },
				ambientSound: {
					type: "audio",
					content: audioAmbianceMaraichere,
					name: "step10Subtitle4",
				},
				text: [
					{
						type: "subtitle",
						content: "step10Title",
					},
					{
						type: "text",
						content: "step10Header1",
					},
					{
						type: "imgSliderH",
						content: {
							imageBefore: `url(${imgSliderMaraichereBefore})`,
							imageAfter: `url(${imgSliderMaraichereAfter})`,
						},
					},
					{
						type: "subtitle",
						content: "step10Subtitle1",
					},
					{
						type: "text",
						content: "step10Text1",
					},
					{
						type: "subtitle",
						content: "step10Subtitle2",
					},
					{
						type: "text",
						content: "step10Text2",
					},
					{
						type: "image",
						content: `url(${imgMaraichere1})`,
					},
					{
						type: "subtitle",
						content: "step10Subtitle3",
					},
					{
						type: "text",
						content: "step10Text3",
					},
					{
						type: "text",
						content: "step10Text4",
					},
					/* Ludovic Ménard */
					{
						type: "more",
						img: {
							img: `url(${ludovicMenard})`,
							backgroundPosition: "center",
							backgroundSize: "contain",
						},
						content: [
							{
								type: "subtitle",
								content: "step10Subtitle5",
							},
							{
								type: "text",
								content: "step10Text6",
							},
							{
								type: "text",
								content: "step10Text7",
							},
							{
								type: "text",
								content: "step10Text8",
							},
							{
								type: "text",
								content: "step10Text9",
							},
						],
					},
				],
			},
			treasure: {
				name: "itemStep10_flag",
				imgEmpty: `url(${itemStep10_empty})`,
				img: `url(${itemStep10})`,
			},
		},
		{
			type: "Feature",
			properties: {
				id: "borne",
				step: 0,
			},
			card: null,
			unlockScreen: {
				name: "Borne",
			},
			geometry: {
				type: "Point",
				coordinates: [-0.48908333, 47.44652778],
				iconSize: [32, 80],
				icon: `url(${borneImg})`,
			},
		},
	],
};
