// React
import React, { useEffect, useRef, useState } from "react";
// Redux
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";

// Components
import { GetTrad } from "src/utils/getTrad";
// Style
import "./go.css";
import { bearingBetween } from "src/utils/bearingBetween";
import { haversineDistance } from "src/utils/haversineDistance";
import { askSensors } from "src/utils/askSensors";

export const Go = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);

	const [colorCircle, setColorCircle] = useState("#DC4426");
	const _colorCircle = useRef(colorCircle);

	// const { heading } = useSelector((state: IRootState) => state.map);

	// const _heading = useRef(heading);

	const [distance, setDistance] = useState<number>();
	const [heading, setHeading] = useState<number>(0);
	const [sensors, setSensors] = useState<boolean>(false);

	const handleAskSensors = async () => {
		try {
			const granted = localStorage.getItem('sensorsAccessGranted');
			if (granted) {
				setSensors(true);
				return;
			}
			if (!sensors) {
				const result = await askSensors();
				if (result) {
					setSensors(true);
					localStorage.setItem('sensorsAccessGranted', 'true');
				} else {
					setSensors(false);
					localStorage.removeItem('sensorsAccessGranted');
				}
			}
		}
		catch (error) {
			setSensors(false);
		}
	}

	const handleDeviceOrientation = (event: DeviceOrientationEvent) => {
		if (event.alpha !== null) {
			setHeading(event.alpha);
		}
		else {
		}
	}

	useEffect(() => {
		handleAskSensors();
		window.addEventListener("deviceorientation", handleDeviceOrientation);
		return () => {
			window.removeEventListener("deviceorientation", handleDeviceOrientation);
		}
	}, [])

	useEffect(() => {
		_colorCircle.current = colorCircle;
	}, [colorCircle]);

	const fillCircle = (angle: number) => {
		var canvas = document.getElementById("canvas2");
		var context = (canvas as HTMLCanvasElement)?.getContext("2d");

		if (context) {
			let centerx = 150;
			let centery = 150;
			let radius = 75;

			context.clearRect(0, 0, 300, 300);
			context.beginPath();
			context.fillStyle = _colorCircle.current;
			context.moveTo(centerx, centery);
			var arcsector = (angle * Math.PI) / 180;

			let offset = Math.PI / 2;

			context.arc(centerx, centery, radius, -offset, arcsector - offset, angle < 0);
			context.lineTo(centerx, centery);
			context.fill();
			context.closePath();
		}
	};

	useEffect(() => {
		let compassElem = document.getElementById("go-compass-img");

		if (compassElem && heading) {
			compassElem.style.transform = "translate(-50%, -50%) rotate(" + -heading + "deg)";
		}
	}, [heading]);

	const getDistance = (coordsDest: [number, number] | null) => {
		if (!coordsDest) {
			return;
		}
		if (props.userCoords) {
			try {
				let dist = haversineDistance(props.userCoords, props.poi?.geometry?.coordinates);
				setDistance(dist);

				let result = bearingBetween(props.userCoords, props.poi?.geometry?.coordinates);

				let targetElem = document.getElementById("go-compass-pointContainer");
				if (targetElem && heading) {
					targetElem.style.transform = "rotate(" + result + "deg)";
					let newResult = result - heading;

					if (newResult <= -180) {
						newResult += 360;
					} else if (newResult > 180) {
						newResult -= 360;
					}
					fillCircle(newResult);
				}
			} catch (e) {
				console.error("Error calculating distance or bearing.", e);
			}
		} else {
			console.error("Retrieving user location failed.");
		}
	};

	const _userCoords = useRef(props.userCoords);
	useEffect(() => {
		_userCoords.current = props.userCoords;
		getDistance(props.poi.geometry.coordinates);
	}, [props.userCoords, getDistance]);

	useEffect(() => {
		if (distance && distance > 100 && colorCircle !== "#DC4426") {
			setColorCircle("#DC4426");
		} else if (distance && distance <= 100 && colorCircle !== "#5B69D0") {
			setColorCircle("#5B69D0");
		}
	}, [distance]);

	return (
		<React.Fragment>
			<div className='go'>
				<div className='go-quit'>
					<div
						className='go-quit-cross'
						onClick={() => {
							props.pageFcts.setPage("");
							window.removeEventListener("deviceorientation", handleDeviceOrientation);
						}}
					></div>
				</div>
				<div className='go-content'>
					<div className={"go-hint"}>
						{heading !== undefined && heading !== null && distance !== undefined && distance !== null ? (
							<React.Fragment>
								<div className={"go-hintTitle"}>INDICE</div>
								<div className={"go-hintContent"}>
									{distance && distance > 200 ? (
										<GetTrad
											value={props.poi.compass.find((hint: any) => hint.type === "hint")?.content}
											lang={language}
										/>
									) : distance ? (
										<GetTrad
											value={
												props.poi.compass.find((hint: any) => hint.type === "hintClose")
													?.content
											}
											lang={language}
										/>
									) : (
										"..."
									)}
								</div>
							</React.Fragment>
						) : (
							<div className={"go-loadingTitle"} style={{ textTransform: "uppercase" }}>
								<GetTrad value={"loadingProgress"} lang={language} />
							</div>
						)}
					</div>
					<div className={"go-compass"}>
						{
							<canvas
								id='canvas2'
								width='300'
								height='300'
								style={{ float: "left", position: "relative", top: "0" }}
							></canvas>
						}
						<div id='go-compass-img' className='go-compass-img'>
							<div id='go-compass-pointContainer' className={"go-compass-pointContainer"}>
								{heading !== undefined &&
									heading !== null &&
									distance !== undefined &&
									distance !== null && <div className='go-compass-point'></div>}
							</div>
						</div>

						<div
							id='go-compass-imgNeedle'
							className={"go-compass-imgNeedle"}
							style={{
								animation:
									heading !== undefined &&
										heading !== null &&
										distance !== undefined &&
										distance !== null
										? ""
										: "rotateInf 1s linear infinite forwards",
							}}
						></div>
					</div>
					<div
						className={"go-textBottom"}
						style={{
							opacity:
								heading !== undefined && heading !== null && distance !== undefined && distance !== null
									? "1"
									: "0",
						}}
					>
						<div className={"go-textBottom-target"}><GetTrad value='targetIsAt' lang={language} /></div>

						<div className={"go-textBottom-distance"}>
							{distance && distance > 1000
								? (distance / 1000).toFixed(1) + "km"
								: distance
									? distance.toFixed(0) + "m"
									: ""}
						</div>
						<div className={"go-textBottom-see"}><GetTrad value='canYouSeeIt' lang={language} /></div>
					</div>
				</div>
				<div
					className='go-button cardNav-button-blue'
					onClick={() => {
						props.pageFcts.setCard(false);
						props.pageFcts.setCardNav(false);
						props.pageFcts.setPage("posfound");
						window.removeEventListener("deviceorientation", handleDeviceOrientation);
					}}
				>
					<GetTrad value='validatePos' lang={language} />
				</div>
			</div>
		</React.Fragment>
	);
};
