import { toRad } from './toRad';
export function bearingBetween (coords1: [number, number], coords2: [number, number]) {
    const lat1 = toRad(coords1[0]);
    const lon1 = toRad(coords1[1]);
    const lat2 = toRad(coords2[0]);
    const lon2 = toRad(coords2[1]);
  
    const y = Math.sin(lon2 - lon1) * Math.cos(lat2);
    const x = Math.cos(lat1) * Math.sin(lat2) -
              Math.sin(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1);
  
    let bearing = Math.atan2(y, x) * (180 / Math.PI);
  
    bearing = (bearing + 360) % 360;
  
    return bearing;
  };