// React
import React, { useEffect, useRef, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { setQuiz } from "src/actions/game";
import { IRootState } from "src/reducers";
// Components
import { QuizResult } from "./QuizResult";
import { GetTrad } from "src/utils/getTrad";
// Modules
import { useCookies } from "react-cookie";
// Utils
import { quiz } from "../../datas/quiz";
// Style
import "./quiz.css";
// Assets
import bandImg from "../../style/assets/global/BandHeader.png";
import quizImg from "../../style/assets/pois/maraichere/Etape10_LaMaraichere1.jpg";

export const Quiz = (props: any) => {
	const dispatch = useDispatch();

	const [cookies, setCookie] = useCookies(["cookiesAllowed", "pois", "quiz"]);

	const { language } = useSelector((state: IRootState) => state.app);
	const { quizAns } = useSelector((state: IRootState) => state.game);

	const _quizAns = useRef(quizAns);

	const [score, setScore] = useState<number>(0);
	const [explanations, setExplanations] = useState<boolean>(false);
	const [results, setResults] = useState<boolean>(false);

	const [answer, setAnswer] = useState<{ answer: any; correct: boolean }>({ answer: {}, correct: false });

	const [firstRender, setFirstRender] = useState(true);

	useEffect(() => {
		if (firstRender) {
			if (quizAns.length >= quiz.length) {
				setResults(true);
			}
			setFirstRender(false);
		}

		_quizAns.current = quizAns;

		if (quizAns.length <= 0) {
			setAnswer({ answer: {}, correct: false });
			setExplanations(false);
			setResults(false);
		}

		let scoreTmp = 0;
		for (let ans of quizAns) {
			scoreTmp += ans.points;
		}
		setScore(scoreTmp);
	}, [quizAns]);

	const goodAnswer = (a: any) => {
		setAnswer({ answer: a, correct: true });
		let answerElem = document.getElementById("answer" + a.id);

		if (answerElem) {
			answerElem.style.backgroundColor = "#5B69D0";
			answerElem.style.color = "#FFFFFF";

			setTimeout(() => {
				setExplanations(true);

				let quizAnsTmp = [..._quizAns.current];
				quizAnsTmp.push({
					question: quiz.find((q: any) => q.id === _quizAns.current.length),
					points: a.points,
				});

				dispatch(setQuiz(quizAnsTmp));
				if (cookies.cookiesAllowed) {
					setCookie("quiz", JSON.stringify(quizAnsTmp));
				}

				setTimeout(() => {
					if (answerElem) {
						answerElem.style.backgroundColor = "";
						answerElem.style.color = "";
					}
				}, 500);
			}, 1000);
		}
	};

	const badAnswer = (a: any) => {
		setAnswer({ answer: a, correct: false });
		let answerElem = document.getElementById("answer" + a.id);

		if (answerElem) {
			answerElem.style.backgroundColor = "#EF6044";
			answerElem.style.color = "#1C1917";

			setTimeout(() => {
				setExplanations(true);

				let quizAnsTmp = [..._quizAns.current];
				quizAnsTmp.push({
					question: quiz.find((q: any) => q.id === quiz.length - 1),
					points: a.points,
				});
				dispatch(setQuiz(quizAnsTmp));
				if (cookies.cookiesAllowed) {
					setCookie("quiz", JSON.stringify(quizAnsTmp));
				}

				setTimeout(() => {
					if (answerElem) {
						answerElem.style.backgroundColor = "";
						answerElem.style.color = "";
					}
				}, 500);
			}, 1000);
		}
	};

	const nextQuestion = () => {
		console.log("Next question!", _quizAns.current.length);

		setAnswer({ answer: {}, correct: false });
		setExplanations(false);
		const test = document.querySelectorAll('.quiz-answer');
		test.forEach((elem) => {
			(elem as HTMLElement).style.pointerEvents = 'all';
		});
	};

	return (
		<React.Fragment>
			<div className='quiz'>
				<div
					className='quiz-band'
					style={{
						backgroundImage: `url(${bandImg})`,
					}}
				>
					<div id='quiz-band-number' className='quiz-band-number'>
						{score}
						<span className='quiz-band-pts'>{" point" + (score > 1 ? "s" : "")}</span>
					</div>
					<div className='quiz-quit'>
						<div
							className='quiz-quit-cross'
							onClick={() => {
								props.pageFcts.setPage(null);
							}}
						></div>
					</div>
				</div>
				<div className='quiz-content'>
					<div className='quiz-top'>
						<div className='quiz-img' style={{ backgroundImage: explanations ? quiz.find((q: any) => q.id === quizAns.length - 1)?.image : quizAns.length < 10 ? quiz.find((q: any) => q.id === quizAns.length)?.image : quizImg }}>
							{explanations && (
								<div className={'quiz-score quiz-score-' + (answer.correct ? "correct" : "uncorrect")}>
									{answer.correct ? '+1' : '-1'}
									<span style={{fontSize: 'max(1px, min(5vh, 3vw, 24px))'}}>pt</span>
								</div>
							)}
						</div>

					</div>
					<div
						className='quiz-bot'
						style={{ justifyContent: explanations ? "space-around" : "space-around" }}
					>
						<div className='quiz-question-number'>
							<GetTrad value='question' lang={language} />
							{" " + (explanations ? quizAns.length : quizAns.length + 1)}
						</div>
						{!explanations && (
							<div className='quiz-question-question'>
								<GetTrad
									value={quiz.find((q: any) => q.id === quizAns.length)?.question || ""}
									lang={language}
								/>
							</div>
						)}
						{explanations && (
							<div className='quiz-question-question'>
								<GetTrad
									value={
										quiz.find((q: any) => q.id === quizAns.length - 1)?.question + "Answer" || ""
									}
									lang={language}
								/>
								<div className='quiz-question-answer'>
									<GetTrad
										value={
											quiz
												.find((q: any) => q.id === quizAns.length - 1)
												?.answers.find((a: any) => a.correct === true)?.answer || ""
										}
										lang={language}
									/>
								</div>
								<div className={"quiz-question-answer-" + (answer.correct ? "correct" : "uncorrect")}>
									<GetTrad
										value={answer.correct ? "correctAnswer" : "uncorrectAnswer"}
										lang={language}
									/>
									<br />
									{!answer.correct && (
										<div className={"quiz-question-answer-final"}>
											<GetTrad value={answer.answer.answer} lang={language} />
											{" !"}
										</div>
									)}
								</div>
							</div>
						)}

						{!explanations && (
							<div className='quiz-answers'>
								{quiz
									.find((q: any) => q.id === quizAns.length)
									?.answers.map((a: any) => {
										return (
											<div
												id={"answer" + a.id}
												key={
													quiz.find((q: any) => q.id === quizAns.length - 1)?.question +
													"_" +
													a.id
												}
												className='quiz-answer'

												onClick={ () => {
													const test = document.querySelectorAll('.quiz-answer');
													test.forEach((elem) => {
														(elem as HTMLElement).style.pointerEvents = 'none';
													});
													if (a.correct) {
														goodAnswer(a);
													} else {
														badAnswer(a);
													}
												}}
											>
												<GetTrad value={a.answer || ""} lang={language} />
											</div>
										);
									})}
							</div>
						)}
						{explanations && (
							<React.Fragment>
								<div className='quiz-explanations'>
									<GetTrad
										value={quiz.find((q: any) => q.id === quizAns.length - 1)?.explanations || ""}
										lang={language}
									/>
								</div>
								<div
									className='quiz-button2'
									onClick={() => {
										if (quizAns.length < quiz.length) {
											nextQuestion();
										} else {
											setResults(true);
										}
									}}
								>
									<GetTrad
										value={quizAns.length < quiz.length ? "nextQuestion" : "lastQuestion"}
										lang={language}
									/>
								</div>
							</React.Fragment>
						)}
					</div>
				</div>
				{results && <QuizResult pageFcts={props.pageFcts} score={score} />}
			</div>
		</React.Fragment>
	);
};
