// React
import React, { useEffect, useState } from "react";
// Style
import "./podcast.css";
import { GetTrad } from "src/utils/getTrad";
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";
// Assets
import audioPlayIcon from "../../../style/assets/podcast/audioPlayIcon.svg";
import audioPauseIcon from "../../../style/assets/podcast/audioPauseIcon.svg";

import audioMedia from "../../../style/assets/pois/ambianceVieuxfonds.mp3";

const formatTime = (time: number) => {
	// let minutes = time / 60;
	// let seconds = time % 60;

	const minutes = Math.floor(time / 60);
	const seconds = time - minutes * 60;

	return (
		(minutes >= 0 ? minutes.toFixed(0) : "0") +
		":" +
		(seconds > 0 && seconds < 60 ? (seconds < 10 ? "0" : "") + seconds.toFixed(0) : "00")
	);
};

export const Podcast = (props: { audio: any; mode: string; data?: any }) => {
	const { language } = useSelector((state: IRootState) => state.app);

	const [playing, setPlaying] = useState<boolean>(false);
	const [intervalTime, setIntervalTime] = useState<NodeJS.Timeout>();

	useEffect(() => {
		let to = setTimeout(() => {
			let audioElem = document.getElementById("audio");

			if (audioElem) {
				let timerElem = document.getElementById("podcast-audioPlayer-timer");

				if (timerElem) {
					let total = parseInt((audioElem as HTMLAudioElement).duration.toFixed(0));

					timerElem.innerHTML = formatTime(total);
				}

				let progressElem = document.getElementById("podcast-audioPlayer-progress");

				if (progressElem) {
					let total = parseInt((audioElem as HTMLAudioElement).duration.toFixed(0));

					progressElem.style.width = "100%";
				}
			}
		}, 1000);

		return () => clearTimeout(to);
	}, []);

	useEffect(() => {
		let intervalTimeTmp: NodeJS.Timeout;
		let audioElem = document.getElementById("audio");

		if (audioElem) {
			if (playing) {
				(audioElem as HTMLAudioElement).play();

				intervalTimeTmp = setInterval(() => {
					if (audioElem) {
						let timerElem = document.getElementById("podcast-audioPlayer-timer");

						if (timerElem) {
							let total = parseInt((audioElem as HTMLAudioElement).duration.toFixed(0));
							let partial = parseInt((audioElem as HTMLAudioElement).currentTime.toFixed(0));

							timerElem.innerHTML = formatTime(total - partial);
						}

						let progressElem = document.getElementById("podcast-audioPlayer-progress");

						if (progressElem) {
							let total = parseInt((audioElem as HTMLAudioElement).duration.toFixed(0));
							let partial = parseInt((audioElem as HTMLAudioElement).currentTime.toFixed(0));

							progressElem.style.width = Math.floor(100 - (partial * 100) / total) + "%";
						}
					}
				}, 1000);

				setIntervalTime(intervalTimeTmp);
			} else {
				(audioElem as HTMLAudioElement).pause();

				if (intervalTime) {
					clearInterval(intervalTime);
				}
			}
		}

		return () => clearInterval(intervalTimeTmp);
	}, [playing]);

	if (props.mode === "float") {
		return (
			<React.Fragment>
				<div id='podcast-audioPlayer-float' className='podcast-audioPlayer-float'>
					{props.audio && <audio id='audio' src={props.audio.content} loop={true}></audio>}
					<div
						className='podcast-audioPlayer-float-play'
						onClick={() => {
							let audioElem = document.getElementById("audio");

							if (audioElem) {
								if (
									(audioElem as HTMLAudioElement).paused ||
									(audioElem as HTMLAudioElement).duration === 0
								) {
									setPlaying(true);
								} else {
									setPlaying(false);
								}
							}
						}}
					>
						<div
							className='podcast-audioPlayer-float-playIcon'
							style={{
								backgroundImage: `url(${playing ? audioPauseIcon : audioPlayIcon})`,
							}}
						></div>
					</div>
					<div className='podcast-audioPlayer-float-name'>
						<GetTrad value={props.audio.name} lang={language} />
					</div>
				</div>
			</React.Fragment>
		);
	}

	return (
		<div id='podcast-audioPlayer' className='podcast-audioPlayer'>
			{props.audio && (
				<audio
					id='audio'
					src={props.audio}
					loop={false}
					onEnded={() => {
						let audioElem = document.getElementById("audio");

						if (audioElem) {
							(audioElem as HTMLAudioElement).currentTime = 0;
							setPlaying(false);

							let timerElem = document.getElementById("podcast-audioPlayer-timer");

							if (timerElem) {
								let total = parseInt((audioElem as HTMLAudioElement).duration.toFixed(0));

								timerElem.innerHTML = formatTime(total);
							}

							let progressElem = document.getElementById("podcast-audioPlayer-progress");

							if (progressElem) {
								progressElem.style.transition = "unset";
								setTimeout(() => {
									if (progressElem) {
										progressElem.style.width = "100%";
									}
									setTimeout(() => {
										if (progressElem) {
											progressElem.style.transition = "1s linear";
										}
									}, 100);
								}, 100);
							}
						}
					}}
				></audio>
			)}
			<div
				className='podcast-audioPlayer-play'
				onClick={() => {
					let audioElem = document.getElementById("audio");

					if (audioElem) {
						if ((audioElem as HTMLAudioElement).paused || (audioElem as HTMLAudioElement).duration === 0) {
							setPlaying(true);
						} else {
							setPlaying(false);
						}
					}
				}}
			>
				<div
					className='podcast-audioPlayer-playIcon'
					style={{
						backgroundImage: `url(${playing ? audioPauseIcon : audioPlayIcon})`,
					}}
				></div>
			</div>
			<div className='podcast-audioPlayer-progressContainer'>
				<div className='podcast-audioPlayer-progressBase'></div>
				<div id='podcast-audioPlayer-progress' className='podcast-audioPlayer-progress'></div>
			</div>
			<div id='podcast-audioPlayer-timer' className='podcast-audioPlayer-timer'></div>
		</div>
	);
};
