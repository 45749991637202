// React
import { useEffect, useState } from "react";
import { unstable_HistoryRouter as HistoryRouter, Routes, Route } from "react-router-dom";
// Redux
import { useDispatch } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { useCookies } from "react-cookie";
// Components
import { Map } from "./components/Map/Map";
// Style
import "./App.css";
import { setPoisProgress, setTreasuresProgress, setQuiz, setFinalRank } from "./actions/game";

// Assets
import UI_TabBar_top from "./style/devAssets/UI_TabBar_top.jpg";
import UI_TabBar_bottom from "./style/devAssets/UI_TabBar_bottom.jpg";

let history = require("history").createBrowserHistory();

function App() {
	const dispatch: Dispatch<any> = useDispatch();

	const [dev] = useState(false);

	/* Cookies */
	const [cookies] = useCookies(["cookiesAllowed", "pois", "treasures", "quiz", "finalRank"]);
	const [cookiePopup, setCookiePopup] = useState(true);

	const [first, setFirst] = useState(true);

	useEffect(() => {
		if (cookies.cookiesAllowed) {
			if (cookies.pois) {
				dispatch(setPoisProgress(cookies.pois));
			}
			if (cookies.treasures) {
				dispatch(setTreasuresProgress(cookies.treasures));
			}
			if (cookies.quiz && first) {
				dispatch(setQuiz(cookies.quiz));
				setFirst(false);
			}
			if (cookies.finalRank && first) {
				dispatch(setFinalRank(cookies.finalRank));
				setFirst(false);
			}
		}
	}, [dispatch, first, cookies]);

	return (
		<div className='App'>
			<div className='app-container'>
				{dev && <div className='dev-ui-top' style={{ backgroundImage: `url(${UI_TabBar_top})` }}></div>}
				<div className='app-content'>
					<HistoryRouter history={history}>
						<Routes>
							<Route
								path='/:poiSelected'
								element={
									<Map
										cookies={{
											cookies: cookies,
											cookiePopup: cookiePopup,
											setCookiePopup: setCookiePopup,
										}}
									/>
								}
							/>
							<Route
								path='/'
								element={
									<Map
										cookies={{
											cookies: cookies,
											cookiePopup: cookiePopup,
											setCookiePopup: setCookiePopup,
										}}
									/>
								}
							/>
						</Routes>
					</HistoryRouter>
				</div>
				{dev && <div className='dev-ui-bottom' style={{ backgroundImage: `url(${UI_TabBar_bottom})` }}></div>}
			</div>
		</div>
	);
}

export default App;
