// React
import React, { useEffect, useState } from "react";
// Redux
import { Dispatch } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { setDiscoveredPoi } from "src/actions/game";
import { IRootState } from "src/reducers";
// Modules
import { useCookies } from "react-cookie";
// Components
import { GetTrad } from "src/utils/getTrad";
// Style
import "./posObjectFound.css";
// Assets
import alreadyVisitedImage from "../../style/assets/map/PoiSeen.svg";

export const PosObjectFound = (props: any) => {
	const dispatch = useDispatch();

	const [cookies, setCookie] = useCookies(["cookiesAllowed", "pois", "treasures"]);

	const { language } = useSelector((state: IRootState) => state.app);
	const { pois } = useSelector((state: IRootState) => state.game);

	const [first, setFirst] = useState<boolean>(true);

	useEffect(() => {
		dispatch(setDiscoveredPoi(props.poi.properties.id));

		console.log("poi =", props.poi);
	}, []);

	useEffect(() => {
		if (first) {
			if (cookies.cookiesAllowed) {
				setCookie("pois", JSON.stringify(pois));
			}
			setFirst(false);
		}
	}, [pois]);

	console.log("location state poi", props.poi);

	return (
		<React.Fragment>
			<div className='PosObjFound'>
				<div className='PosObjFound-topPage'>
					<div className='posObjFound-text'>
						<div className='posObjFound-textBravo'>
							<GetTrad value='congrats' lang={language} />
						</div>
						<div className='posObjFound-textFound'>
							<GetTrad value='youFound' lang={language} />
						</div>
					</div>
					<div className='posObjFound-image'>
						<div className='posObjFound-imageTitle'>{props.poi.unlockScreen.name}</div>
						<div
							className='posObjFound-imageBackground'
							style={{
								aspectRatio: props.poi.unlockScreen.width / props.poi.unlockScreen.height,
								backgroundPosition: props.poi.unlockScreen.backgroundPosition,
								backgroundSize: props.poi.unlockScreen.backgroundSize,
								backgroundImage: props.poi.unlockScreen.img,
								transform: "rotate(4deg)",
							}}
						></div>
						<div
							className='posObjFound-imageView'
							style={{ backgroundImage: `url(${alreadyVisitedImage})` }}
						></div>
					</div>
				</div>

				<div
					className='PosObjFound-button'
					onClick={() => {
						props.pageFcts.setPage("poi");
					}}
				>
					<div className='posObjFound-button-text'>Découvrir</div>
				</div>
			</div>
		</React.Fragment>
	);
};
