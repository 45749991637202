// React
import React from "react";
// Style
import "./credits.css";
// Data
import { creditsData } from "../../datas/credits";
// Assets
import separatorImg from "../../style/assets/icons/creditSeparator.png";
import cityLogo from "../../style/assets/credits/Trelaze-even.png";
export const Credits = (props: any) => {
	return (
		<React.Fragment>
			<div className='credits'>
				<div className='credits-quit'>
					<div className='credits-quit-space' />
					<div className='credits-quit-text'>Crédits</div>
					<div
						className='credits-quit-cross'
						onClick={() => {
							props.pageFcts.setPage(null);
						}}
					/>
				</div>
				<div className='credits-header-title'>Trelazé, Chasseur d’histoire</div>

				<div className='credits-content'>
					{creditsData.map((sectionData, sectionIndex) =>
						Object.entries(sectionData).map(([sectionTitle, entries]) => {
							switch (sectionTitle) {
								case "Help":
									return (
										<div key={sectionTitle+'_'+sectionIndex} className='credits-documentation-container'>
											<div className='credits-separator-container'>
												<div className='credits-separator-bar' />
												<div
													className='credits-separator-image'
													style={{ backgroundImage: `url(${separatorImg})` }}
												></div>
											</div>
											<div className='credits-documentation-cityLogo' 
											style={{ backgroundImage: cityLogo }}>
											</div>
											{entries.map((entry, i: number) => {
												switch (entry.type) {
													case "title":
														return (
															<React.Fragment key={sectionTitle+'_'+sectionIndex + "_" + i}>
																<div className='credits-documentation-entry'>
																	{entry.content}
																</div>
			
															</React.Fragment>
														);
													default:
														return null;
												}
											})}
										</div>
									);
								case "Documentation":
									return (
										<div key={sectionTitle+'_'+sectionIndex} className='credits-documentation-container'>
											<div className='credits-separator-container'>
												<div className='credits-separator-bar' />
												<div
													className='credits-separator-image'
													style={{ backgroundImage: `url(${separatorImg})` }}
												></div>
											</div>
											<div className='credits-documentation-title'> {sectionTitle}</div>
											{entries.map((entry, i) => {
												switch (entry.type) {
													case "location":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-location'
															>
																{entry.content}
															</div>
														);
													case "from":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-from'
															>
																{entry.content}
															</div>
														);
													case "credit":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-credit'
															>
																{entry.content}
															</div>
														);
													default:
														return null;
												}
											})}
										</div>
									);
								case "Iconographies":
									return (
										<div key={sectionTitle+'_'+sectionIndex} className='credits-iconographie-container'>
											<div className='credits-separator-container'>
												<div className='credits-separator-bar' />
												<div
													className='credits-separator-image'
													style={{ backgroundImage: `url(${separatorImg})` }}
												></div>
											</div>
											<div className='credits-iconographie-title'>{sectionTitle}</div>
											{entries.map((entry, i) => {
												switch (entry.type) {
													case "location":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-location'
															>
																{entry.content}
															</div>
														);
													case "from":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-from'
															>
																{entry.content}
															</div>
														);
													case "credit":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-credit'
															>
																{entry.content}
															</div>
														);
													default:
														return null;
												}
											})}
										</div>
									);
								case "SoundDesign":
									return (
										<div key={sectionTitle+'_'+sectionIndex} className='credits-soundDesign-container'>
											<div className='credits-separator-container'>
												<div className='credits-separator-bar' />
												<div
													className='credits-separator-image'
													style={{ backgroundImage: `url(${separatorImg})` }}
												></div>
											</div>
											
											{entries.map((entry, i) => {
												switch (entry.type) {
													case "title":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-soundDesign-title'
															>
																{" "}
																{entry.content}
															</div>
														);
													case "location":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-location'
															>
																{entry.content}
															</div>
														);
													case "from":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-from'
															>
																{entry.content}
															</div>
														);
													case "credit":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-credit'
															>
																{entry.content}
															</div>
														);
													default:
														return null;
												}
											})}
										</div>
									);
								case "Musics":
									return (
										<div key={sectionTitle+'_'+sectionIndex} className='credits-musics-container'>
											<div className='credits-separator-container'>
												<div className='credits-separator-bar' />
												<div
													className='credits-separator-image'
													style={{ backgroundImage: `url(${separatorImg})` }}
												></div>
											</div>
											{entries.map((entry, i) => {
												switch (entry.type) {
													case "title":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-musics-title'
															>
																{" "}
																{entry.content}
															</div>
														);
													case "location":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-location'
															>
																{entry.content}
															</div>
														);
													case "from":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-from'
															>
																{entry.content}
															</div>
														);
													case "credit":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-credit'
															>
																{entry.content}
															</div>
														);
													default:
														return null;
												}
											})}
										</div>
									);
								case "Design":
									return (
										<div key={sectionTitle+'_'+sectionIndex} className='credits-design-container'>
											<div className='credits-separator-container'>
												<div className='credits-separator-bar' />
												<div
													className='credits-separator-image'
													style={{ backgroundImage: `url(${separatorImg})` }}
												></div>
											</div>
											{entries.map((entry, i) => {
												switch (entry.type) {
													case "title":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-design-title'
															>
																{" "}
																{entry.content}
															</div>
														);
													case "location":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-location'
															>
																{entry.content}
															</div>
														);
													case "from":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-from'
															>
																{entry.content}
															</div>
														);
													case "credit":
														return (
															<div
																key={sectionTitle+'_'+sectionIndex + "_" + entry.type + "_" + i}
																className='credits-credit'
															>
																{entry.content}
															</div>
														);
													default:
														return null;
												}
											})}
										</div>
									);
								default:
									return null;
							}
						})
					)}
				</div>
			</div>
		</React.Fragment>
	);
};
