import { trads } from "./trads";

import cityLogo from "../style/assets/credits/Trelaze-even.png";
export const creditsData = [
    {
    
    "Help": [
        {
            type:"title",
            content:"La ville de Trélazé remercie tout particulièrement M. Pantais, conseiller municipal et passionné d'histoire, M. Roger, président de l'association les Amis de l'ardoise - Musée de Trélazé, et M. Bilien, représentant de l'association les Amis du Patrimoine Trélazéen pour leur contribution à ce projet.			"
        },
    ],

    "Documentation": [
        {
            type: "credit",
            content: [
                "”Association “Les Amis de l’ardoise”"
            ],
        },
        {
            type: "credit",
            content: [
                "Musée de Trélazé"
            ],
        },
        {
            type: "credit",
            content: [
                "32 Chemin de la Maraîchère (Pôle Hervé Bazin)"
            ],
        },
        {
            type: "credit",
            content: [
                "49800 Trélazé"
            ],
        },
        {
            type: "credit",
            content: [
                "02 41 69 04 71"
            ],
        },
        {
            type: "credit",
            content: [
                "www.lemuseedel’ardoise.fr"
            ],
        },
    ],
    "Iconographies": [
            {
                type: "credit",
                content: ["Club photo Reg’Art de l’association culturelle du Petit pré à Trélazé"]
            },
            {
                type: "credit",
                content: ["clubphotoregart@laposte.net"]
            },
            {
                type: "credit",
                content: ["ccpetitpre@sfr.fr"]
            },
            {
                type: "credit",
                content: ["https://petit-pre.wixsite.com/culture"]
            },
            {
                type: "credit",
                content: [" "]
            },
            {
                type: "credit",
                content: ["Les étudiants de l'Asta (Académie Supérieure de Théâtre d'Anjou)"]
            },
            {
                type: "credit",
                content: [" "]
            },
            {
                type: "credit",
                content: ["Hugues FALAISE - Helidrone Ouest"]
            },
            {
                type: "credit",
                content: [" "]
            },
            {
                type: "credit",
                content: ["Photographe inconnu"]
            },
            {
                type: "credit",
                content: ["Archives départementales de Maine-et-Loire"]
            },
            {
                type: "credit",
                content: [" "]
            },
            {
                type: "credit",
                content: ["”Association “Les Amis de l’ardoise”"]
            },
            {
                type: "credit",
                content: ["Musée de Trélazé"]
            },
            {
                type: "credit",
                content: ["32 Chemin de la Maraîchère (Pôle Hervé Bazin)"]
            },
            {
                type: "credit",
                content: ["49800 Trélazé"]
            },
            {
                type: "credit",
                content: ["02 41 69 04 71"]
            },
            {
                type: "credit",
                content: ["www.lemuseedel’ardoise.fr"]
            },       
    ],
    "SoundDesign": [
        {
            type:"title",
            content:"Créations sonores"
        },
        {
            type: "credit",
            content: ["Alain ROGER, président de l'Association “Les Amis de l'ardoise” "]
        },
        {
            type: "credit",
            content: ["Musée de Trélazé"]
        },
        {
            type: "credit",
            content: ["Timescope"]
        },
    ],
    
    "Musics": [
        {
            type:"title",
            content:"Chansons"
        },
        {
            type: "credit",
            content: ["D'ailleurs c'est d'ici"]
        }
    ],
    
    "Design": [
        {
            type:"title",
            content:"Design & Réalisation"
        },
        {
            type: "credit",
            content: ["Timescope"]
        }
    ]
}]    