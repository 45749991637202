// React
import React from "react";
// Redux
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";
// Imports
import { GetTrad } from "src/utils/getTrad";
// CSS
import "./podcastUnlock.css";
//Assets
import rankStarUnactive from "../../style/assets/podcast/starUnactive.png";
import rankStarActive from "../../style/assets/podcast/starActive.png";
import audioPlayIcon from "../../../style/assets/podcast/audioPlayIcon.png";
import audioPauseIcon from "../../../style/assets/podcast/audioPauseIcon.png";
import { Podcast } from "../Partials/Podcast/Podcast";

export const PodcastUnlock = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);

	return (
		<div className='podcastUnlock'>
			<div className='podcastUnlock-content'>
				<div className='podcastUnlock-congrat-container'>
					<div className='podcastUnlock-congrat-text1'>
						<GetTrad value='podcastCongrats' lang={language} />
					</div>
					<div className='podcastUnlock-congrat-text2'>
						<GetTrad value='podcastYouDiscovered' lang={language} />
					</div>
				</div>
				<div className='podcastUnlock-steps-container'>
					<div className='podcastUnlock-steps-text'>
						<GetTrad value='podcast5steps' lang={language} />
					</div>
				</div>
				<div className='podcastUnlock-rank-container'>
					<div className='podcastUnlock-rank-headerText'>
						<GetTrad value='podcastYouAreNow' lang={language} />
					</div>
					<div className='podcastUnlock-rank-separator' />
					<div className='podcastUnlock-rank-text'>
						<GetTrad value='podcastPerreyeux' lang={language} />
					</div>
					<div className='podcastUnlock-rank-separator' />
					<div className='podcastUnlock-rank-stars-container'>
						<div
							className='podcastUnlock-rank-stars-icon1'
							style={{ backgroundImage: `url(${rankStarActive})` }}
						/>
						<div
							className='podcastUnlock-rank-stars-icon2'
							style={{ backgroundImage: `url(${rankStarActive})` }}
						/>
						<div
							className='podcastUnlock-rank-stars-icon2'
							style={{ backgroundImage: `url(${rankStarUnactive})` }}
						/>
					</div>
				</div>
				<div className='podcastUnlock-text-container'>
					<div className='podcastUnlock-text-1'>
						<GetTrad value='podcastText1' lang={language} />
					</div>
				</div>
				<Podcast mode='inline' audio={"assets/podcastBonus.mp3"} />
				<div
					className='podcastUnlock-continueButton'
					onClick={() => {
						props.pageFcts.setPage(null);
					}}
				>
					<GetTrad value='podcastContinueAdventure' lang={language} />
				</div>
			</div>
		</div>
	);
};
