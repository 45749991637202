import React, { useEffect, useState } from "react";
// Redux
import { useSelector } from "react-redux";
import { useCookies } from "react-cookie";
// CSS
import "./cookies.css";
// Utils
import { trads } from "../../../datas/trads";

export const Cookies = (props: any) => {
	const { language } = useSelector((state: any) => state.app);

	const [cookies, setCookie /*, removeCookie*/] = useCookies(["cookiesAllowed"]);

	const [display, setDisplay] = useState<boolean>(true);

	let goTo = () => {
		props.displayPrivatePolicyPage(true);
	};
	
	return (
		<React.Fragment>
			{display ? (
				<div className='cookies'>
					<div className='cookies-desc'>
						{trads.find((t) => t.id === language)?.cookiesContent}{" "}
						<a onClick={() => goTo()}>{trads.find((t) => t.id === language)?.cookiesReadPrivacyPolicy}</a>
					</div>
					<div className='cookies-buttons'>
						<div
							className='cookies-button'
							onClick={() => {
								console.log("setCookiePopup à false")
								props.setCookiePopup(false);
							}}
						>
							<div className='cookies-buttonText'>
								{trads.find((t) => t.id === language)?.cookiesDenied}
							</div>
						</div>
						<div
							className='cookies-button'
							onClick={() => {
								setCookie("cookiesAllowed", true);
								props.setCookiePopup(false);
							}}
						>
							<div className='cookies-buttonText'>
								{trads.find((t) => t.id === language)?.cookiesAccept}
							</div>
						</div>
					</div>
				</div>
			) : null}
		</React.Fragment>
	);
};
