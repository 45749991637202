// React
import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
// Redux
import { useSelector } from "react-redux";
import { IRootState } from "src/reducers";
// Components
import { Podcast } from "../Partials/Podcast/Podcast";
import { GetTrad } from "src/utils/getTrad";
// Style
import "./poi.css";

// Import icon
import bandImg from "../../style/assets/global/BandHeader.png";
import stepIcon from "../../style/assets/icons/stepIcon.svg";
import listenIcon from "../../style/assets/icons/playIcon.svg";
import shareIcon from "../../style/assets/icons/shareIcon.svg";
import questionMarkIcon from "../../style/assets/icons/questionMarkIcon.png";
import { off } from "process";

export const Poi = (props: any) => {
	const { language } = useSelector((state: IRootState) => state.app);
	const { treasures } = useSelector((state: IRootState) => state.game);

	const _anecdote = useRef(false);

	const [moving, setmoving] = useState<boolean>(false);
	const _moving = useRef(moving);
	const [posBegin, setPosBegin] = useState<any>();
	const _posBegin = useRef(posBegin);

	const [more, displayMore] = useState(false);

	const sharePoi = async (shareData: any) => {
		try {
			await navigator.share(shareData);
		} catch (err) {
			console.error("Error: " + err);
		}
	};

	useEffect(() => {
		_moving.current = moving;
	}, [moving]);

	useEffect(() => {
		_posBegin.current = posBegin;
	}, [posBegin]);

	const pickupH = (event: any) => {
		if (!_moving.current) {
			let zone = document.getElementsByClassName("poi-slider-before")[0];

			if (zone) {
				zone = zone as HTMLElement;

				setPosBegin(zone.getBoundingClientRect());
			}

			// target.style.height = target.clientHeight;
			// target.style.width = target.clientWidth;
			//target.style.position = "fixed";

			document.addEventListener("mousemove", moveH);
			document.addEventListener("touchmove", moveH);
			document.addEventListener("mouseup", dropH);
			document.addEventListener("touchend", dropH);

			setmoving(true);
		}
	};

	const moveH = (event: any) => {
		if (_moving.current) {
			let target = document.getElementById("poi-slider-cursor");

			if (target) {
				let offset;
				if (event.clientX) {
					offset = event.clientX - _posBegin.current.left;
					offset = (offset * 100) / _posBegin.current.width;
				} else {
					offset = event.changedTouches[0].clientX - _posBegin.current.left;
					offset = (offset * 100) / _posBegin.current.width;
				}
				if(offset < 0 || offset > 100) {
					dropH(event);
				}
				if (offset < 8) {
					offset = 8;
				} else if (offset > 95) {
					offset = 95;
				}

				let afterImgElem = document.getElementById("poi-slider-after");
				if (afterImgElem) {
					afterImgElem.style.width = offset + "%";
				}

				target.style.left = offset + "%";
			}
		}
	};

	const dropH = (event: any) => {
		if (_moving.current) {
			document.removeEventListener("mousemove", moveH);
			document.removeEventListener("touchmove", moveH);
			document.removeEventListener("mouseup", dropH);
			document.removeEventListener("touchend", dropH);

			setmoving(false);
		}
	};

	const pickupV = (event: any) => {
		if (!_moving.current) {
			let zone = document.getElementsByClassName("poi-slider-before")[0];
		
			if (zone) {
				zone = zone as HTMLElement;

				setPosBegin(zone.getBoundingClientRect());
			}

			// target.style.height = target.clientHeight;
			// target.style.width = target.clientWidth;
			//target.style.position = "fixed";

			document.addEventListener("mousemove", moveV);
			document.addEventListener("touchmove", moveV);
			document.addEventListener("mouseup", dropV);
			document.addEventListener("touchend", dropV);

			setmoving(true);
		}
	};

	const moveV = (event: any) => {
		if (_moving.current) {
			let target = document.getElementById("poi-slider-cursorV");

			if (target) {
				let offset;
				if (event.clientY) {
					offset = event.clientY - _posBegin.current.top;
					offset = (offset * 100) / _posBegin.current.height;
				} else {
					offset = event.changedTouches[0].clientY - _posBegin.current.top;
					offset = (offset * 100) / _posBegin.current.height;
				}
				if(offset < 0 || offset > 100) {
					dropH(event);
				}

				if (offset < 6) {
					offset = 6;
				} else if (offset > 94) {
					offset = 94;
				}

				let afterImgElem = document.getElementById("poi-slider-afterV");
				if (afterImgElem) {
					afterImgElem.style.height = offset + "%";
				}

				let afterWaveImgElem = document.getElementById("poi-sliderWave-afterV");
				if (afterWaveImgElem) {
					afterWaveImgElem.style.height = "calc(" + (100 - offset) + "%)";
				}

				target.style.top = offset + "%";
			}
		}
	};

	const dropV = (event: any) => {
		if (_moving.current) {
			document.removeEventListener("mousemove", moveV);
			document.removeEventListener("touchmove", moveV);
			document.removeEventListener("mouseup", dropV);
			document.removeEventListener("touchend", dropV);

			setmoving(false);
		}
	};

	return (
		<React.Fragment>
			<div
				className='poi'
				onScroll={(elem: any) => {
					let podcastElem = document.getElementById("podcast-audioPlayer-float");
					if (podcastElem) {
						let rect = elem.target.getBoundingClientRect();

						if (elem.target.scrollTop >= elem.target.scrollHeight - rect.height - 500) {
							(podcastElem as HTMLElement).style.opacity = "0";
							(podcastElem as HTMLElement).style.pointerEvents = "none";
						} else {
							(podcastElem as HTMLElement).style.opacity = "1";
							(podcastElem as HTMLElement).style.pointerEvents = "all";
						}
					}
				}}
			>
				<div className='poi-content'>
					<div
						className='poi-band'
						style={{
							backgroundImage: `url(${bandImg})`,
						}}
					>
						<div className='poi-band-stepContainer'>
							<div
								className='poi-band-icon'
								style={{
									backgroundImage: `url(${stepIcon})`,
								}}
							></div>
							<div className='poi-band-step'>
								<GetTrad value='step' lang={language} />
								{(props.poi.properties.step < 10 ? "0" : "") + props.poi.properties.step}
							</div>
						</div>
						<div
							className='poi-quit-cross'
							onClick={() => {
								props.pageFcts.setPage(null);
								props.pageFcts.setCard(false);
								props.pageFcts.setCardNav(false);
								props.setPoi(null);
							}}
						></div>
					</div>

					<div className='poi-title'>
						<GetTrad value={props.poi.pagePoi.title} lang={language} />
					</div>

					<div className='poi-button-container'>
						{props.poi.pagePoi.button.listen && (
							<div
								className='poi-button-listen'
								onClick={() => {
									let pageElem = document.getElementsByClassName("poi")[0];
									let podcastElem = document.getElementById("podcast-audioPlayer");

									if (podcastElem && pageElem) {
										let pos = podcastElem.getBoundingClientRect();
										pageElem.scroll({
											top: pos.top - 100,
											left: 0,
											behavior: "smooth",
										});
									}
								}}
							>
								<div
									className='poi-button-listen-icon'
									style={{ backgroundImage: `url(${listenIcon})` }}
								></div>
								<div className='poi-button-listen-text'>
									<GetTrad value={props.poi.pagePoi.button.listen} lang={language} />
								</div>
							</div>
						)}
						{props.poi.pagePoi.button.share && (
							<div
								className='poi-button-share'
								onClick={() => {
									sharePoi({
										title: "Trélazé - Devenez Chasseur d'Histoire : " + props.poi.card.name,
										url: '/' + props.poi.properties.id,
									});
								}}
							>
								<div
									className='poi-button-share-icon'
									style={{ backgroundImage: `url(${shareIcon})` }}
								></div>
								<div className='poi-button-share-text'>
									<GetTrad value={props.poi.pagePoi.button.share} lang={language} />
								</div>
							</div>
						)}
					</div>

					{props.poi.pagePoi.text.map((elem: any, index: number) => {
						switch (elem.type) {
							case "textHeader":
								return (
									<div key={"pagePoi-" + index} className='poi-textHeader'>
										<GetTrad value={elem.content} lang={language} />
									</div>
								);
							// case 'hint':
							// 	return <div className='poi-hint'><GetTrad value={elem.content} lang={language} /></div>
							// case 'hintClose':
							// 	return <div className='poi-hintClose'><GetTrad value={elem.content} lang={language} /></div>
							case "subtitle":
								return (
									<div key={"pagePoi-" + index} className='poi-subtitle'>
										<GetTrad value={elem.content} lang={language} />
									</div>
								);
							case "text":
								return (
									<div key={"pagePoi-" + index} className='poi-text'>
										<GetTrad value={elem.content} lang={language} />
									</div>
								);
							case "imgSliderH":
								return (
									<div key={"pagePoi-" + index} className='poi-imgSlider'>
										<div
											className='poi-slider-before'
											style={{
												backgroundImage: elem.content.imageAfter,
											}}
										>
											<div
												id='poi-slider-after'
												className='poi-slider-after'
												style={{
													backgroundImage: elem.content.imageBefore,
													backgroundPosition: elem.content.imageBeforePos || "left",
													backgroundSize: elem.content.imageBeforeSize || "auto 100%",
												}}
											></div>
											<div
												id='poi-slider-cursor'
												className='poi-slider-cursor'
												onTouchStart={(event) => pickupH(event)}
												onMouseDown={(event) => pickupH(event)}
											>
												<div className='poi-slider-button'></div>
											</div>
										</div>
										<br />
										<br />
									</div>
								);
							case "imgSliderV":
								return (
									<div key={"pagePoi-" + index} className='poi-imgSlider'>
										<div
											className='poi-slider-before'
											style={{
												backgroundImage: elem.content.imageBefore,
											}}
										>
											<div
												id='poi-slider-afterV'
												className='poi-slider-afterV'
												style={{
													backgroundImage: elem.content.imageAfter,
													backgroundPosition: "top",
													backgroundSize: "100% auto",
												}}
											></div>
											<div
												id='poi-slider-cursorV'
												className='poi-slider-cursorV'
												onTouchStart={(event) => pickupV(event)}
												onMouseDown={(event) => pickupV(event)}
											>
												<div className='poi-slider-buttonV'></div>
											</div>
										</div>
										<br />
										<br />
									</div>
								);
							case "imgSliderWave":
								return (
									<div key={"pagePoi-" + index} className='poi-imgSliderWave'>
										<div
											className='poi-slider-before'
											style={{
												backgroundImage: elem.content.imageBackground,
												mixBlendMode: "multiply",
											}}
										>
											<div id='poi-sliderWave-afterV' className='poi-sliderWave-afterV-container'>
												<div
													className='poi-sliderWave-afterV wave1'
													style={{
														backgroundImage: elem.content.imageTop,
														backgroundPosition: "center top",
														backgroundSize: "cover",
														backgroundRepeat: "repeat-x",
													}}
												></div>
												<div
													className='poi-sliderWave-afterV wave2'
													style={{
														backgroundImage: elem.content.imageDown,
														backgroundPosition: "center top",
														backgroundSize: "cover",
														backgroundRepeat: "repeat-x",
													}}
												></div>
											</div>
											<div
												id='poi-slider-cursorV'
												className='poi-slider-cursorV'
												style={{
													bottom: "6%",
													top: "unset",
												}}
												onTouchStart={(event) => pickupV(event)}
												onMouseDown={(event) => pickupV(event)}
											>
												<div className='poi-slider-buttonV'></div>
											</div>
										</div>
										<br />
										<br />
									</div>
								);
							case "image":
								return (
									<div key={"pagePoi-" + index} className='poi-img-container'>
										<div
											className='poi-img'
											style={{
												backgroundImage: elem.content,
											}}
										></div>
									</div>
								);

							case "audio":
								return <Podcast key={"pagePoi" + index} mode='inline' audio={elem.content} />;
							case "anecdote":
								if (_anecdote.current === false) {
									_anecdote.current = true;
									console.log("set anecdote == ", _anecdote.current);
									return (
										<React.Fragment key={"pagePoi-" + index}>
											<div className='poi-anecdote-header'>L'ANECDOTE EN +</div>

											<div className='poi-anecdote-container'>
												<div className='poi-anecdote-text-container'>
													<div className='poi-anecdote-title'>
														<GetTrad value={elem.content.title} lang={language} />
													</div>

													<div className='poi-anecdote-text'>
														<GetTrad value={elem.content.text} lang={language} />
													</div>
												</div>
											</div>
										</React.Fragment>
									);
								} else {
									return (
										<div key={"pagePoi-" + index} className='poi-anecdote-container'>
											<div className='poi-anecdote-text-container'>
												<div className='poi-anecdote-title'>
													<GetTrad value={elem.content.title} lang={language} />
												</div>

												<div className='poi-anecdote-text'>
													<GetTrad value={elem.content.text} lang={language} />
												</div>
											</div>
										</div>
									);
								}
							case "more":
								return (
									<React.Fragment key={"pagePoi-" + index}>
										<div
											className='poi-anecdote-container'
											style={{ margin: "min(2vh, 2vw) auto min(2vh, 2vw) auto" }}
											onClick={() => {
												displayMore(!more);
											}}
										>
											<div className='poi-anecdote-text-container'>
												<div
													className='poi-more-img'
													style={{
														backgroundImage: elem.img.img,
														backgroundPosition: elem.img.backgroundPosition,
														backgroundSize: elem.img.backgroundSize,
													}}
												></div>
												{more ? (
													<div className='poi-more-explanations'>
														{elem.content.map((content: any, index: number) => {
															switch (content.type) {
																case "textHeader":
																	return (
																		<div
																			key={"pagePoi-" + index}
																			className='poi-textHeader'
																		>
																			<GetTrad
																				value={content.content}
																				lang={language}
																			/>
																		</div>
																	);
																case "subtitle":
																	return (
																		<div
																			key={"pagePoi-" + index}
																			className='poi-subtitle'
																		>
																			<GetTrad
																				value={content.content}
																				lang={language}
																			/>
																		</div>
																	);
																case "text":
																	return (
																		<div
																			key={"pagePoi-" + index}
																			className='poi-text'
																		>
																			<GetTrad
																				value={content.content}
																				lang={language}
																			/>
																		</div>
																	);
															}
														})}
													</div>
												) : (
													<div
														className='poi-textHeader'
														style={{
															textAlign: "center",
															padding: "0",
															margin: "0 0 min(2vh, 2vw, 0)",
														}}
													>
														Cliquez pour en savoir plus
													</div>
												)}
											</div>
										</div>
									</React.Fragment>
								);
						}
					})}
					<div className='poi-discover-container'>
						<div className='poi-discover-image'>
							<div
								className='poi-discover-image-item'
								style={{
									backgroundImage: treasures.find(
										(p: { id: string }) => p.id === props.poi.properties.id
									)?.discovered
										? props.poi.treasure.img
										: props.poi.treasure.imgEmpty,
									opacity: treasures.find((p: { id: string }) => p.id === props.poi.properties.id)
										?.discovered
										? "1"
										: "0.1",
								}}
							></div>
							<div
								className='poi-discover-image-questionMark'
								style={{
									backgroundImage: `url(${questionMarkIcon})`,
									opacity: treasures.find((p: { id: string }) => p.id === props.poi.properties.id)
										?.discovered
										? 0
										: 1,
								}}
							></div>
						</div>

						<div
							className='poi-discover-button-container'
							onClick={() => {
								props.pageFcts.setPage("objfound");
							}}
						>
							<div className='poi-discover-button-text'>Découvrir le trésor</div>
						</div>
					</div>
					{props.poi.pagePoi.ambientSound && <Podcast mode='float' audio={props.poi.pagePoi.ambientSound} />}
				</div>
			</div>
		</React.Fragment>
	);
};
