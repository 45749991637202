import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// datas
import { trads } from "../datas/trads";

export const GetTrad = (props: { value: string; lang: string }) => {
	let actualTrads = trads?.find((t: any) => t.id === props.lang);

	if (!actualTrads || (!props.value || props.value === "")) {
		return <React.Fragment></React.Fragment>;
	}

	let textSplit = (actualTrads as any)[props.value].split("\n");

	return (
		<React.Fragment>
			{textSplit.map((elem: any, index: number) => {
				return (
					<React.Fragment key={"trad-" + props.value + index}>
						{elem}
						{index < textSplit.length - 1 && <br />}
					</React.Fragment>
				);
			})}
		</React.Fragment>
	);
};
