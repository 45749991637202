// React
import React, { useEffect, useRef, useState } from "react";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { setCoords, setHeading } from "../../actions/map";
import { IRootState } from "../../reducers";
// Modules
import mapboxgl from "mapbox-gl";
import axios from "axios";
// Components
import { Card } from "../Partials/Card/Card";
import { CardNav } from "../Partials/CardNav/CardNav";
// Utils
import { GetTrad } from "src/utils/getTrad";
// Style
import "./map.css";
import "mapbox-gl/dist/mapbox-gl.css";
// Datas
import { geojson } from "../../datas/pois";
import { trads } from "../../datas/trads";
//Ranks
import { ranks } from "src/datas/ranks";

// Assets
import infoIcon from "../../style/assets/icons/info.png";
import compassIcon from "../../style/assets/icons/compass.png";
import targetIcon from "../../style/assets/icons/target.png";
import finalRankStar from "../../style/assets/podcast/starActive.png";

// Assets - Inventory menu
import inventoryBG from "../../style/assets/map/circleInventoryBG.png";
import itemLvl1 from "../../style/assets/inventary/itemLvl1.png";
import itemLvl2 from "../../style/assets/inventary/itemLvl2.png";
import itemLvl3 from "../../style/assets/inventary/itemLvl3.png";
import itemLvl4 from "../../style/assets/inventary/itemLvl4.png";
import { Go } from "../Go/Go";
import { Splashscreen } from "../Splashscreen/Splashscreen";
import { setPath } from "./setPath";
import { useAuthorizations } from "src/hooks/useAuthorizations";
import { Movie } from "../Movie/Movie";
import { ObjectFound } from "../ObjectFound/ObjectFound";
import { PosObjectFound } from "../PosObjectFound/PosObjectFound";
import { Poi } from "../Poi/Poi";
import { PodcastUnlock } from "../Unlocks/PodcastUnlock";
import { QuizUnlock } from "../Unlocks/QuizUnlock";
import { Quiz } from "../Quiz/Quiz";
import { Inventory } from "../Inventory/Inventory";
import { Infos } from "../Infos/Infos";
import { Credits } from "../Credits/Credits";
import { PrivatePolicy } from "../PrivatePolicy/PrivatePolicy";
import { Cookies } from "../Partials/Cookies/Cookies";
import { useParams } from "react-router-dom";
import { setDiscoveredPoi } from "src/actions/game";

const accessStyleToken = process.env.REACT_APP_MAPBOX_API_STYLEKEY;
const accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

export const Map = (props: any) => {
	const { poiSelected } = useParams();
	const dispatch: Dispatch<any> = useDispatch();

	const { askAuthorization, geoLoc, sensors } = useAuthorizations();

	const { language } = useSelector((state: IRootState) => state.app);
	const { pois, treasures, finalRank } = useSelector((state: IRootState) => state.game);
	const { coords } = useSelector((state: IRootState) => state.map);

	// Map's components
	const [poi, setPoi] = useState<any>();
	const [card, setCard] = useState<any>(false);
	const [cardNav, setCardNav] = useState<any>(false);
	// Page components
	const [page, setPage] = useState("splashscreen");
	const [privatePolicyPage, displayPrivatePolicyPage] = useState<boolean>(false);
	const _previousPage = useRef(page);

	useEffect(() => {
		if (poiSelected) {
			let poi = geojson.features.find((p: any) => {
				return p.properties.id === poiSelected;
			});
			if (poi) {
				setPoi(poi);
				dispatch(setDiscoveredPoi(poi.properties.id));
				setPage("poi");
			}
		}
	}, []);

	useEffect(() => {
		_previousPage.current = page;
	}, [page]);
	/*const [moviePage, displayMoviePage] = useState<boolean>(false);
	const [splashscreenPage, displaySplashscreenPage] = useState<boolean>(true);
	const [goPage, displayGoPage] = useState<boolean>(false);*/

	// Map
	const [markerList, setMarkerList] = useState<Array<any>>([]);
	const [map, setMap] = useState<mapboxgl.Map>();
	const _map = useRef(map);
	useEffect(() => {
		_map.current = map;
	}, [map]);

	useEffect(() => {
		if (accessToken) {
			mapboxgl.accessToken = accessStyleToken + "";

			const mapGL = new mapboxgl.Map({
				container: "map",
				style: "mapbox://styles/felixmarquette/clgpjcd5k00hj01qugp7n21ob",
				center: [-0.48908333, 47.44652778],
				zoom: 14,
			});

			if (mapGL) {
				setMap(mapGL);
			}
		}
	}, []);

	// Manage icons/marker with coordonates
	useEffect(() => {
		if (map) {
			if (markerList && (markerList as Array<any>).length > 0) {
				for (var i = (markerList as Array<any>).length - 1; i >= 0; i--) {
					(markerList as Array<any>)[i].remove();
				}
			}

			let markerListTmp = [];
			for (const marker of geojson.features) {
				const el = document.createElement("div");
				const el_child = document.createElement("div");

				el.id = "marker-" + marker.properties.id;

				const width = marker.geometry.iconSize[0];
				const height = marker.geometry.iconSize[1];
				el.className = "marker";
				el_child.style.width = "100%";
				el_child.style.height = "100%";
				el_child.style.top = "0";
				el_child.style.left = "0";
				el_child.style.position = "absolute";

				if (pois.filter((p: any) => p.id === marker.properties.id)[0]?.discovered && marker.geometry.iconFind) {
					el_child.style.backgroundImage = marker.geometry.iconFind;
				} else {
					el_child.style.backgroundImage = marker.geometry.icon;
					if (marker.card) {
						el_child.style.animation = "markerBreathe linear 3s infinite";
					}
				}
				el.style.width = `${width}px`;
				el.style.height = `${height}px`;
				el.style.top = "12px";
				el_child.style.backgroundSize = "100%";
				el.appendChild(el_child);

				el.addEventListener("click", (e) => {
					e.stopPropagation();
					if (marker.card) {
						setPoi(marker);
						setCard(true);
						map?.flyTo({
							center: [marker.geometry.coordinates[0], marker.geometry.coordinates[1] - 0.003],
							zoom: 15,
						});
					} else {
						setPage("movie");
						//displayMoviePage(true);
					}
				});

				// Add markers to the map.
				if (!cardNav || poi.properties.id === marker.properties.id) {
					let tmp = new mapboxgl.Marker(el, { anchor: "bottom" })
						.setLngLat([marker.geometry.coordinates[0], marker.geometry.coordinates[1]])
						.addTo(map);

					markerListTmp.push(tmp);
				}
			}

			setMarkerList(markerListTmp);

			map.addControl(
				new mapboxgl.GeolocateControl({
					positionOptions: {
						enableHighAccuracy: false,
					},
					showAccuracyCircle: false,
					trackUserLocation: true,
					showUserHeading: true,
				}),
				"top-right"
			);
		}
	}, [map, cardNav, page]);

	// Gamification
	const discoveredObject = treasures.filter((p: any) => p.discovered === true).length;
	const currentRank = ranks.find((rank) => rank.min <= discoveredObject && rank.max > discoveredObject);

	const [userCoords, setUserCoords] = useState<number[]>();
	const [heading, setHeading] = useState(0);

	useEffect(() => {
		let watcher: number;
		if (geoLoc) {
			watcher = navigator.geolocation.watchPosition(
				(position) => {
					setUserCoords([position.coords.longitude, position.coords.latitude]);
					let tmp = position.coords.heading || 0;
					setHeading(tmp);
				},
				(error) => {
					console.error("Geolocation error:", error.message);
				},
				{ enableHighAccuracy: true, timeout: 20000, maximumAge: 0 }
			);
		}

		return () => {
			if (watcher) {
				navigator.geolocation.clearWatch(watcher);
			}
		};
	}, [geoLoc]);

	const [tuto, setTuto] = useState(false);
	const [lastAction, setLastAction] = useState(Date.now());

	useEffect(() => {
		let timeout: NodeJS.Timeout;

		if (tuto) {
			let firstMarkerElem = document.getElementById("marker-premiereManuAllu");

			if (firstMarkerElem) {
				firstMarkerElem.style.zIndex = "8000";

				let popupElemCreated = document.createElement("div");
				popupElemCreated.id = "map-popup";
				popupElemCreated.className = "map-popup";
				popupElemCreated.style.display = "flex";
				(popupElemCreated as HTMLElement).innerHTML = `
					<div id='map-popup-tailBottom' class='map-popup-tailBottom'></div>
					<div class='map-popup-content'>${trads.find((trad: any) => trad.id === language)?.discoverPoint}</div>
				`;

				(popupElemCreated as HTMLElement).style.minWidth = "250px";
				(popupElemCreated as HTMLElement).style.transform = "translate(calc(-50% + 12px), calc(-100% - 12px))";
				(popupElemCreated as HTMLElement).style.display = "flex";

				let child = firstMarkerElem.appendChild(popupElemCreated);

				timeout = setTimeout(() => {
					if (popupElemCreated) {
						firstMarkerElem?.removeChild(popupElemCreated);

						let inventoryElem = document.getElementById("map-ui-bottomLeft-inventory");

						if (inventoryElem) {
							(popupElemCreated as HTMLElement).style.bottom = "0px";
							(popupElemCreated as HTMLElement).style.position = "absolute";
							(popupElemCreated as HTMLElement).style.transform =
								"translate(calc(10% + 12px), calc(-180% - 12px))";
							(popupElemCreated as HTMLElement).style.maxWidth = "200px";

							(popupElemCreated as HTMLElement).innerHTML = `
					<div id='map-popup-tailBottom' class='map-popup-tailBottom'></div>
					<div class='map-popup-content'>${trads.find((trad: any) => trad.id === language)?.discoverInventory}</div>
				`;
							inventoryElem.appendChild(popupElemCreated);
						}
					}
				}, 3000);
			}
		}

		return () => {
			clearTimeout(timeout);
		};
	}, [tuto]);

	const subpages = ["splashscreen", "movie", "go"];

	const [to, setTo] = useState<any>(null);
	const _to = useRef(to);

	useEffect(() => {
		_to.current = to;
	}, [to]);

	useEffect(() => {
		if (_to.current) {
			clearTimeout(_to.current);
		}
		setTuto(false);
		let popupElem = document.getElementById("map-popup");

		if (popupElem) {
			popupElem.remove();
		}

		let timeout: NodeJS.Timeout;
		if (_previousPage.current === "") {
			if (_to.current) {
				clearTimeout(_to.current);
			}
			_to.current = setTimeout(() => {
				setTuto(true);
			}, 15000);
		}

		return () => {
			if (_to.current) {
				clearTimeout(_to.current);
			}
		};
	}, [lastAction]);

	useEffect(() => {
		console.log("previous page was ", _previousPage);

		if (_previousPage.current === "") {
			setTuto(true);
		}

		if (map) {
			setPath(userCoords, null, map);
		}
	}, [page]);

	const pageFcts = {
		setPage: setPage,
		setCard: setCard,
		setCardNav: setCardNav,
	};

	useEffect(() => {
		if (!cardNav && map) {
			setPath(coords, null, map);
		}
	}, [cardNav, map]);
	return (
		<React.Fragment>
			<div
				id='map'
				className='map'
				onClick={() => {
					console.log("last action");
					setLastAction(Date.now());
					if (poi) {
						setPoi(null);
					}
					if (card) {
						setCard(false);
					}
					if (cardNav) {
						setCardNav(false);
						setPath(coords, null, _map.current);
					}
				}}
			></div>
			<div className='map-ui'>
				<div className='map-ui-topLeft'>
					<div className='map-ui-topLeft-options'>
						<div
							className='map-ui-option'
							onClick={() => {
								setPage("infos");
							}}
							style={{ backgroundImage: `url(${infoIcon})` }}
						></div>
					</div>
				</div>
				<div className='map-ui-topRight'>
					<div className='map-ui-topRight-options'>
						<div
							className='map-ui-option'
							onClick={() => {
								map?.resetNorth({ duration: 1000 });
							}}
							style={{ backgroundImage: `url(${compassIcon})` }}
						></div>
						<div className='map-ui-separation'></div>
						<div
							className='map-ui-option'
							onClick={() => {
								let geoElems = document.getElementsByClassName("mapboxgl-ctrl-geolocate");

								if (geoElems && geoElems[0]) {
									(geoElems[0] as HTMLButtonElement).click();
								}
							}}
							style={{ backgroundImage: `url(${targetIcon})` }}
						></div>
					</div>
				</div>
				<div className='map-ui-bottomLeft'>
					<div
						className='map-ui-bottomLeft-background-image'
						style={{ backgroundImage: `url(${inventoryBG})` }}
					></div>

					<div
						id='map-ui-bottomLeft-inventory'
						className='map-ui-bottomLeft-inventory'
						onClick={() => {
							setPage("inv");
						}}
					>
						<div className='map-ui-bottomLeft-inventoryContent'>
							<div
								className='map-ui-bottomLeft-levelIcon'
								style={{ backgroundImage: `url(${currentRank?.img})` }}
							></div>
							<div className='map-ui-bottomLeft-text-container'>
								<div className='map-ui-bottomLeft-text-level'>
									<GetTrad value={"level"} lang={language} />{" "}
									{finalRank ? (
										<>
											<GetTrad value={finalRank.toString()} lang={language} />
											<div
												className='map-ui-bottomLeft-finalRank-star'
												style={{ backgroundImage: `url(${finalRankStar})` }}
											/>
										</>
									) : currentRank && currentRank.id === "secretary" ? (
										<GetTrad value={"leader"} lang={language} />
									) : (
										currentRank && <GetTrad value={currentRank.id} lang={language} />
									)}
								</div>
								<div className='map-ui-bottomLeft-text-collectedObj'>
									{discoveredObject + "/" + treasures.length}{" "}
									{treasures.filter((p: any) => p.discovered === true).length > 1 ? (
										<GetTrad value={"collectedObjectPlurial"} lang={language} />
									) : (
										<GetTrad value={"collectedObject"} lang={language} />
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				{card && <Card pageFcts={pageFcts} geoLoc={geoLoc} userCoords={userCoords} poi={poi} setPoi={setPoi} />}
				{cardNav && (
					<CardNav
						pageFcts={pageFcts}
						geoLoc={geoLoc}
						userCoords={userCoords}
						poi={poi}
						setPoi={setPoi}
						map={_map.current}
					/>
				)}
			</div>
			{/* Subpages */}
			{page === "splashscreen" && <Splashscreen pageFcts={pageFcts} askAuthorization={askAuthorization} />}
			{page === "go" && <Go pageFcts={pageFcts} userCoords={userCoords} heading={heading} poi={poi} />}
			{page === "inv" && <Inventory pageFcts={pageFcts} poi={poi} setPoi={setPoi} />}
			{page === "posfound" && <PosObjectFound pageFcts={pageFcts} poi={poi} />}
			{page === "objfound" && <ObjectFound pageFcts={pageFcts} poi={poi} />}
			{page === "podcastunlock" && <PodcastUnlock pageFcts={pageFcts} poi={poi} />}
			{page === "quizunlock" && <QuizUnlock pageFcts={pageFcts} poi={poi} />}
			{page === "quiz" && <Quiz pageFcts={pageFcts} poi={poi} />}
			{page === "poi" && <Poi pageFcts={pageFcts} poi={poi} setPoi={setPoi} />}
			{page === "movie" && <Movie pageFcts={pageFcts} />}
			{page === "infos" && <Infos pageFcts={pageFcts} />}
			{page === "credits" && <Credits pageFcts={pageFcts} />}
			{privatePolicyPage && (
				<PrivatePolicy pageFcts={pageFcts} displayPrivatePolicyPage={displayPrivatePolicyPage} />
			)}
			{!props.cookies.cookies.cookiesAllowed && props.cookies.cookiePopup && !privatePolicyPage && (
				<Cookies
					setCookiePopup={props.cookies.setCookiePopup}
					displayPrivatePolicyPage={displayPrivatePolicyPage}
				/>
			)}
		</React.Fragment>
	);
};
