// React
import { createReducer } from "@reduxjs/toolkit";
// Types
import {
	GAME_POIS,
	GAME_SETDISCOVEREDPOI,
	GAME_SETDISCOVEREDOBJ,
	GAME_SETQUIZ,
	GAME_SETSHOWIMAGE,
	IAction,
	GAME_TREASURES,
	GAME_SETFINALRANK,
} from "../actions/types";
import { useCookies } from "react-cookie";
import { StaticReadUsage } from "three";

export interface IGameState {
	currentPoi: string;
	pois: any;
	treasures: any;
	quizAns: any[];
	finalRank: string;
}

const initialState: IGameState = {
	currentPoi: "",
	pois: [
		{
			id: "premiereManuAllu",
			discovered: false,
			showImage: false,
		},
		{
			id: "butteEnfer",
			discovered: false,
			showImage: false,
		},
		{
			id: "favereaux",
			discovered: false,
			showImage: false,
		},
		{
			id: "vieuxfonds",
			discovered: false,
			showImage: false,
		},
		{
			id: "moulin",
			discovered: false,
			showImage: false,
		},
		{
			id: "butteBuisson",
			discovered: false,
			showImage: false,
		},
		{
			id: "pyramide",
			discovered: false,
			showImage: false,
		},
		{
			id: "placeTellieres",
			discovered: false,
			showImage: false,
		},
		{
			id: "secondeManuAllu",
			discovered: false,
			showImage: false,
		},
		{
			id: "maraichere",
			discovered: false,
			showImage: false,
		},
	],
	treasures: [
		{
			id: "premiereManuAllu",
			discovered: false,
			showImage: false,
		},
		{
			id: "butteEnfer",
			discovered: false,
			showImage: false,
		},
		{
			id: "favereaux",
			discovered: false,
			showImage: false,
		},
		{
			id: "vieuxfonds",
			discovered: false,
			showImage: false,
		},
		{
			id: "moulin",
			discovered: false,
			showImage: false,
		},
		{
			id: "butteBuisson",
			discovered: false,
			showImage: false,
		},
		{
			id: "pyramide",
			discovered: false,
			showImage: false,
		},
		{
			id: "placeTellieres",
			discovered: false,
			showImage: false,
		},
		{
			id: "secondeManuAllu",
			discovered: false,
			showImage: false,
		},
		{
			id: "maraichere",
			discovered: false,
			showImage: false,
		},
	],
	quizAns: [],
	finalRank: ""
};

export const reducer = createReducer(initialState, (builder: any) => {
	builder.addCase(GAME_POIS, (state: any, action: IAction) => {
		state.pois = action.payload;
	});
	builder.addCase(GAME_TREASURES, (state: any, action: IAction) => {
		state.treasures = action.payload;
	});
	builder.addCase(GAME_SETDISCOVEREDPOI, (state: any, action: IAction) => {
		if (!state.pois.find((p: any) => p.id === action.payload)) {
			console.log("Trying to discover an inexistant POI!");
		} else {
			state.pois.find((p: any) => p.id === action.payload).discovered = true;
		}
	});
	builder.addCase(GAME_SETDISCOVEREDOBJ, (state: any, action: IAction) => {
		if (!state.treasures.find((p: any) => p.id === action.payload)) {
			console.log("Trying to discover an inexistant Treasure!");
		} else {
			state.treasures.find((p: any) => p.id === action.payload).discovered = true;
		}
	});
	builder.addCase(GAME_SETQUIZ, (state: any, action: IAction) => {
		state.quizAns = action.payload;
	});
	builder.addCase(GAME_SETSHOWIMAGE, (state: any, action: IAction) => {
		if (!state.pois.find((p: any) => p.id === action.payload)) {
			console.log("Trying to discover an inexistant POI!");
		} else {
			state.pois.find((p: any) => p.id === action.payload).showImage = !state.pois.find(
				(p: any) => p.id === action.payload
			).showImage;
		}
	});
	builder.addCase(GAME_SETFINALRANK, (state: any, action: IAction) => {
		state.finalRank = action.payload;

	});
});
