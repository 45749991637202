import itemCherubin from "../style/assets/inventary/itemLvl3.png";
import itemPerreyeux from "../style/assets/inventary/itemLvl2.png";
import itemSecretary from "../style/assets/inventary/itemLvl10.png";

export const ranks = [
	{
		id: "cherubin",
		idDesc: "cherubinDesc",
		min: 0,
		max: 5,
		img: itemCherubin,
	},
	{
		id: "perreyeux",
		idDesc: "perreyeuxDesc",
		min: 5,
		max: 10,
		img: itemPerreyeux,
	},
	{
		id: "secretary",
		idDesc: "secretaryDesc",
		min: 10,
		max: 20,
		img: itemSecretary,
	},
];
