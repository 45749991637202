// Modules
import * as THREE from "three";
import { PerspectiveCamera, Vector3 } from "three";
// import { degToRad } from "three/src/math/MathUtils";

import {
	FikscopeEngine,
	Pawn,
	KeyBinding,
	Sphere,
	Sphere_cons_type,
	update_input_type,
	CameraComponent,
} from "../../../../fikscopeengine_modules/fikscopeengine-core";

import { LMSphere } from "../level/LMSphere";

/* import { AVrController } from "../../../fikscopeengine_modules/fikscopeengine-core/actors/AVrController"; */

export type Sphero_cons_type = {
	fe: FikscopeEngine;
	material: THREE.Material;
	pos: THREE.Vector3;
	quat: THREE.Quaternion;
	r?: number;
	mass?: number;
	friction?: number;
	physic?: boolean;
	speed?: number;
};

export class PSphere extends Pawn<Sphere> {
	private cameraComponent: CameraComponent;
	//public camera: THREE.PerspectiveCamera;
	public velocityX: number = 0;
	public velocityY: number = 0;
	public speed: number = 0.05;
	public frein: number = 0.998;
	public offsetz: number = -90;
	public previousTime = new THREE.Clock().getDelta();
	public clock = new THREE.Clock();
	public delta = new THREE.Clock().getDelta();

	public level?: LMSphere;

	public mouseClickStart: number = 0;
	public mousePositionOrigin = new THREE.Vector2();
	public mouseMoving: Boolean = false;
	public cameraOrigin = new THREE.Vector3();

	public offsetCamera = new THREE.Vector3(0, 0, 0);

	public authorization = false;
	private setCameraRotation: any;

	constructor({
		fe,
		material,
		pos,
		quat,
		r = 1,
		mass = 0,
		friction = 1,
		physic = false,
		speed = 10,
	}: Sphere_cons_type) {
		const actions = new Map([
			["up", new KeyBinding("KeyW")],
			["down", new KeyBinding("KeyS")],
			["jump", new KeyBinding("Space")],
			["left", new KeyBinding("KeyA")],
			["right", new KeyBinding("KeyD")],
		]);
		super({
			feObject: new Sphere({
				fe: fe,
				material: material,
				pos: pos,
				quat: quat,
				r: r,
				mass: mass,
				friction: friction,
				physic: physic,
				speed: speed,
			} as Sphere_cons_type),
			actions: actions,
		});

		// Camera setup
		const { clientWidth, clientHeight } = fe.renderer.domElement;

		let details = navigator.userAgent;
		let regexp = /android|iphone|kindle|ipad/i;
		let isMobileDevice = regexp.test(details);

		const camera = new THREE.PerspectiveCamera(isMobileDevice ? 90 : 70, clientWidth / clientHeight, 1, 20000);

		camera.up.set(0, 0, 1);
		camera.rotateOnWorldAxis(new Vector3(1, 0, 0), 0); // 0 * (Math.PI / 180)
		camera.position.set(0, 0, 0);

		//Offset
		camera.rotateOnWorldAxis(new Vector3(0, 1, 0), this.offsetz * (Math.PI / 180)); // 0 * (Math.PI / 180)

		this.cameraComponent = new CameraComponent({
			camera,
			renderer: fe.renderer,
		});

		this.cameraComponent.setFunctionUpdate((camera: PerspectiveCamera) => {
			//this.cameraComponent.followPawn(this);
		});

		fe.cameraOperator.setCameraComponent(this.cameraComponent);

		this.controller.handleMouseButton = (e, code, pressed) => this.handleMouseButton(e, code, pressed);
		this.controller.handleMouseMove = (e, dx, dy) => this.handleMouseMove(e, dx, dy);
		this.controller.handleTouchMove = (e, dx, dy) => this.handleTouchMove(e, dx, dy);
		this.controller.handleKeyboardEvent = (e, c, p) => this.handleKeyboardEvent(e, c, p);

		this.controller.handleTouch = (e, code, pressed) => this.handleTouch(e, code, pressed);

		this.bindFe(fe);

		//Handle pointlock
		if (fe.inputManager) {
			fe.inputManager.setPointerLock(false);
		}

		function isSafari() {
			if (navigator.userAgent.match("CriOS")) {
				return false;
			}

			let test = navigator.vendor.match(/[Aa]+pple/g);

			if (test) {
				if (test.length > 0) {
					return true;
				}
			}

			return false;
		}

		let btnStart = document.getElementById("btnStart");
		let debug = document.getElementById("debug");

		let setObjectQuaternion = (function () {
			var zee = new THREE.Vector3(0, 0, 1);
			var euler = new THREE.Euler();
			var q0 = new THREE.Quaternion();
			var q1 = new THREE.Quaternion(-Math.sqrt(0.5), 0, 0, Math.sqrt(0.5)); // - PI/2 around the x-axis

			return function (quaternion: THREE.Quaternion, alpha: number, beta: number, gamma: number, orient: number) {
				euler.set(beta, alpha, -gamma, "YXZ"); // 'ZXY' for the device, but 'YXZ' for us
				quaternion.setFromEuler(euler); // orient the device
				quaternion.multiply(q1); // camera looks out the back of the device, not the top
				quaternion.multiply(q0.setFromAxisAngle(zee, orient)); // adjust for screen orientation
			};
		})();

		const setCameraRotation = (event: any, _this: any) => {
			if (event && event.alpha !== null && event.beta !== null && event.gamma !== null) {
				if (debug) {
					debug.innerHTML = "" + event.alpha + "<br />" + event.beta + "<br />" + event.gamma + "";
				}

				var alphaOffset = 0;
				var screenOrientation = 0;

				var alpha = event.alpha ? THREE.MathUtils.degToRad(event.alpha) + alphaOffset : 0; // Z
				var beta = event.beta ? THREE.MathUtils.degToRad(event.beta) : 0; // X'
				var gamma = event.gamma ? THREE.MathUtils.degToRad(event.gamma) : 0; // Y''

				var orient = screenOrientation ? THREE.MathUtils.degToRad(screenOrientation) : 0; // O

				if (orient) {
					beta -= Math.PI / 2;
				}

				//alpha += Math.PI / 2;

				setObjectQuaternion(camera.quaternion, alpha, beta, gamma, orient);

				/*camera.setRotationFromEuler(new THREE.Euler(
					event.beta* (Math.PI/180),
					event.gamma* (Math.PI/180),
					event.alpha* (Math.PI/180),
					"XZY"
				));*/

				//camera.setRotationFromEuler(new THREE.Euler(0,0,0));

				//offset
				let angle = (this.offsetCamera.z * 180) / Math.PI / 12 - 90;
				camera.rotateOnWorldAxis(new Vector3(0, 1, 0), angle * (Math.PI / 180));
			} else {
				var alphaOffset = 0;
				var screenOrientation = 0;

				var alpha = 0;
				var beta = Math.PI / 2;
				var gamma = 0;

				//alpha += Math.PI / 2;

				setObjectQuaternion(camera.quaternion, alpha, beta, gamma, 0);

				let angle = (this.offsetCamera.z * 180) / Math.PI / 12 - 90;
				camera.rotateOnWorldAxis(new Vector3(0, 1, 0), angle * (Math.PI / 180));
			}
		};
		this.setCameraRotation = setCameraRotation;

		let _this = this;
		if (isSafari()) {
			if (
				typeof DeviceMotionEvent !== "undefined" &&
				typeof (DeviceMotionEvent as any).requestPermission === "function"
			) {
				// (optional) Do something before API request prompt.
				(DeviceMotionEvent as any)
					.requestPermission()
					.then((response: any) => {
						// (optional) Do something after API prompt dismissed.
						if (response == "granted") {
							window.addEventListener("devicemotion", (e) => {
								// do something for 'e' here.
							});
						}
					})
					.catch(console.error);
			} else {
				alert("DeviceMotionEvent is not defined");
			}

			(DeviceMotionEvent as any).requestPermission().then((response: any) => {
				if (response == "granted") {
					_this.authorization = true;
					window.addEventListener("deviceorientation", (event) => {
						setCameraRotation(event, this);
					});
				}
			});
		} else {
			//console.log("not safari");

			if ((DeviceMotionEvent as any).hasOwnProperty("requestPermission")) {
				(DeviceMotionEvent as any).requestPermission().then((response: any) => {
					if (response == "granted") {
						// Add a listener to get smartphone orientation
						// in the alpha-beta-gamma axes (units in degrees)

						window.addEventListener("devicemotion", (event) => {
							/*if (event && event.acceleration) {
										if (debug) {
											//debug.innerHTML = ""+event.acceleration.x+"<br />"+event.acceleration.y+"<br />"+event.acceleration.z+""
										}
									}*/
						});

						_this.authorization = true;
						window.addEventListener("deviceorientation", (event) => {
							//console.log(event.absolute)
							//console.log(event.alpha + ' : ' + event.beta + ' : ' + event.gamma);

							setCameraRotation(event, this);
						});
					}
				});
			} else {
				_this.authorization = true;
				// alert("DeviceMotionEvent don't have requestPermission");
				window.addEventListener("deviceorientation", (event) => {
					setCameraRotation(event, this);
				});
			}
		}

		this.update = (args: update_input_type) => {
			if (this.feObject) {
				// this.delta = this.clock.getDelta(); // Normaly deltatime is already set in args, no ?
			}
		};
	}

	handleTouchMove(event: any, deltaX: number, deltaY: number) {
		if (this.feObject) {
			//this.cameraComponent.rotate(deltaX, deltaY);
			/*console.log("okmove")
			console.log(deltaX)*/

			//console.log(this.mousePositionOrigin.x - event.clientX);

			let moveX = event.changedTouches[0].clientX - this.mousePositionOrigin.x;
			let moveY = event.changedTouches[0].clientY - this.mousePositionOrigin.y;

			//console.log(moveX)

			/*this.cameraComponent.camera.rotation.set(this.cameraOrigin.x, this.cameraOrigin.y, this.cameraOrigin.z);
			this.cameraComponent.camera.rotateX(moveY / 400);
			this.cameraComponent.camera.rotateOnWorldAxis(new Vector3(0, 1, 0), moveX / 400);*/

			this.offsetCamera.x += moveY / 400;
			this.offsetCamera.z += moveX / 400;

			if (!this.authorization && this.setCameraRotation) {
				this.setCameraRotation(undefined, this);
			}
		} else {
			console.warn("No object for pawn");
		}
	}

	handleMouseMove(event: any, deltaX: number, deltaY: number) {
		if (this.feObject) {
			//this.cameraComponent.rotate(deltaX, deltaY);
			/*console.log("okmove")
			console.log(deltaX)*/

			//console.log(this.mousePositionOrigin.x - event.clientX);

			let moveX = event.clientX - this.mousePositionOrigin.x;
			let moveY = event.clientY - this.mousePositionOrigin.y;

			//console.log(moveX)

			this.cameraComponent.camera.rotation.set(this.cameraOrigin.x, this.cameraOrigin.y, this.cameraOrigin.z);
			this.cameraComponent.camera.rotateX(moveY / 400);
			this.cameraComponent.camera.rotateOnWorldAxis(new Vector3(0, 1, 0), moveX / 400);
		} else {
			console.warn("No object for pawn");
		}
	}

	handleMouseWheel(event: any, value: number) {
		//Nothing for now
	}

	checkBox(x: number, y: number) {
		let button1 = document.getElementById("button1");
		let button2 = document.getElementById("button2");
		let button3 = document.getElementById("button3");

		let offsetLeft = 0;
		let homeElem = document.getElementById("home");
		if (homeElem) {
			offsetLeft = homeElem.offsetLeft - homeElem.offsetWidth / 2;
		}

		if (button1) {
			if (y > button1.offsetTop - 15 && y < button1.offsetTop + button1.clientHeight + 15) {
				if (x > button1.offsetLeft - 15 && x < button1.offsetLeft + button1.clientWidth) {
					console.log("Click speaker");
					button1.click();
					return;
				}
			}
		}
		if (button2) {
			if (y > button2.offsetTop - 15 && y < button2.offsetTop + button2.clientHeight + 15) {
				if (x > button2.offsetLeft - 15 && x < button2.offsetLeft + button2.clientWidth) {
					console.log("Click sous titres");
					button2.click();
					return;
				}
			}
		}
		if (button3) {
			if (y > button3.offsetTop - 15 && y < button3.offsetTop + button3.clientHeight + 15) {
				if (x > button3.offsetLeft - 15 && x < button3.offsetLeft + button3.clientWidth) {
					console.log("Click crdits");
					button3.click();
					return;
				}
			}
		}
	}

	handleTouch(event: any, code: string, pressed: boolean) {
		if (event.type === "touchstart") {
			this.mouseMoving = true;
			this.mouseClickStart = Date.now();
			this.mousePositionOrigin.set(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
			this.cameraOrigin.set(
				this.cameraComponent.camera.rotation.x,
				this.cameraComponent.camera.rotation.y,
				this.cameraComponent.camera.rotation.z
			);
		} else if (event.type === "touchend") {
			let length = Date.now() - this.mouseClickStart;
			this.mouseMoving = false;

			if (length < 150) {
				this.checkBox(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
			}

			if (!this.authorization && this.setCameraRotation) {
				this.setCameraRotation(undefined, this);
			}
			//console.log("mouseUp")
		}

		// if (event.type === 'touchend') {
		// 	this.checkBox(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
		// }
	}

	handleMouseButton(event: any, code: string, pressed: boolean) {
		if (event.type === "mousedown") {
			this.mouseMoving = true;
			this.mouseClickStart = Date.now();
			this.mousePositionOrigin.set(event.clientX, event.clientY);
			this.cameraOrigin.set(
				this.cameraComponent.camera.rotation.x,
				this.cameraComponent.camera.rotation.y,
				this.cameraComponent.camera.rotation.z
			);
		} else if (event.type === "mouseup") {
			let length = Date.now() - this.mouseClickStart;
			this.mouseMoving = false;

			if (length < 150) {
				this.checkBox(event.clientX, event.clientY);
			}
			//console.log("mouseUp")
		}
	}

	handleKeyboardEvent(event: any, code: string, pressed: boolean) {
		//could be more effecient to only get from code…
		//this.actions.get(code) … up down left right is useless
		if (this.controller) {
			for (let binding of this.controller.actions.values()) {
				if (binding.eventCodes === code) {
					if (binding.isPressed !== pressed) {
						binding.isPressed = pressed;
					}
				}
			}
		} else {
			console.warn("[Sphero]: Controller undef ?");
		}
	}
}
